<div class="scope-header">
    <textarea
        #scopeHeaderInput
        bpTextareaAutoresize
        (blur)="applyChanges($event)"
        (keydown.enter)="applyChanges($event)"
        [formControl]="scopesHeaderControl"
        class="sc-input"
        placeholder="Enter new scope header"
        rows="1"
        type="text">
    </textarea>

    <button (click)="showDeleteConfirmation()"
            class="btn btn-circle btn-small btn-secondary"
            ngbTooltip="Delete scope header"
            type="button">
        <i class="material-icons">delete_forever</i>
    </button>
</div>
