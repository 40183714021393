<div class="block-header m-b-10">
    <div class="row">
        <div class="col-sm-12 text-center">
            <strong>
                Hello, admin!
            </strong>
        </div>
    </div>
</div>

