<div class="main-fixed-container" #content>
    <div class="flex-between-container align-items-center controls m-t-10 d-none d-md-flex">
        <div class="flex-1 back-button-container">
            <button (click)="onPreviousButtonClick()"
                    *ngIf="isPreviousButtonVisible()"
                    [disabled]="inProcess()"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back
            </button>

            <button [routerLink]="['../../schedule', store.data.project.id]"
                    *ngIf="store.data?.project == null && returnPage === 'schedule'"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back
            </button>

            <button [routerLink]="['../../dashboard', store.data.project.id]"
                    *ngIf="store.data?.project == null && returnPage === 'cost_plan' && currentStep === 1"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back
            </button>
        </div>
        <div class="flex-1 next-button-container text-right">
            <button (click)="onNextButtonClick()"
                    *ngIf="isNextButtonVisible()"
                    [disabled]="isNextButtonDisabled()"
                    class="btn btn-primary btn-border-radius waves-effect save next"
                    ngbTooltip="Next"
                    type="button">
                Next <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            </button>

            <button (click)="onFinishButtonClick()"
                    *ngIf="isFinishButtonVisible()"
                    [disabled]="isFinishedButtonDisabled()"
                    class="btn btn-primary btn-border-radius waves-effect finish next"
                    ngbTooltip="Finish"
                    type="button">
                Next <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            </button>
        </div>
    </div>

    <div [ngSwitch]="currentStep">
        <bp-template-wizard-step1 *ngSwitchCase="1"
                                  [data]="store.data">
        </bp-template-wizard-step1>

        <bp-template-wizard-step2 *ngSwitchCase="2"
                                  [data]="store.data">
        </bp-template-wizard-step2>

        <bp-template-wizard-step3 *ngSwitchDefault
                                  [td]="store.data.templateData[currentStep - 3]">
        </bp-template-wizard-step3>
    </div>

    <div class="fixed-footer-mobile visible-in-normal-view-on-desktop">
        <div class="flex-between-container align-items-center controls bottom">
            <div class="flex-1 back-button-container">
                <button (click)="onPreviousButtonClick()"
                        *ngIf="isPreviousButtonVisible()"
                        [disabled]="inProcess()"
                        class="btn btn-default btn-border-radius waves-effect previous"
                        ngbTooltip="Back"
                        type="button">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back
                </button>

                <button [routerLink]="['../../schedule', store.data.project.id]"
                        *ngIf="store.data?.project == null && returnPage === 'schedule'"
                        class="btn btn-default btn-border-radius waves-effect previous"
                        ngbTooltip="Back"
                        type="button">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back
                </button>

                <button [routerLink]="['../../dashboard', store.data.project.id]"
                        *ngIf="store.data?.project == null && returnPage === 'cost_plan' && currentStep === 1"
                        class="btn btn-default btn-border-radius waves-effect previous"
                        ngbTooltip="Back"
                        type="button">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back
                </button>
            </div>
            <div class="flex-1 next-button-container text-right">
                <button (click)="onNextButtonClick()"
                        *ngIf="isNextButtonVisible()"
                        [disabled]="isNextButtonDisabled()"
                        class="btn btn-primary btn-border-radius waves-effect save next"
                        ngbTooltip="Next"
                        type="button">
                    Next <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                </button>

                <button (click)="onFinishButtonClick()"
                        *ngIf="isFinishButtonVisible()"
                        [disabled]="isFinishedButtonDisabled()"
                        class="btn btn-primary btn-border-radius waves-effect finish next"
                        ngbTooltip="Finish"
                        type="button">
                    Next <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
