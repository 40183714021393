import { Component, Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ReviewByQsModalComponent
} from 'app/flows/scheduler/components/review-by-qs-modal/review-by-qs-modal.component';

@Injectable({ providedIn: "root" })
export class ReviewByQsModalService implements OnDestroy {
    private ngbModalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    open(): NgbModalRef {
        this.ngbModalRef = this.modalService.open(ReviewByQsModalComponent as Component, {
            windowClass: "bp-modal review-by-qs",
            backdrop: "static"
        });

        return this.ngbModalRef;
    }

    ngOnDestroy(): void {
        this.ngbModalRef = null;
    }
}
