<div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-component-area-heading">
                    Create or edit a Component Area
                </h1>
                <div>
                    <div [hidden]="!componentArea.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="componentArea.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_name">
                            Name
                        </label>
                        <input [(ngModel)]="componentArea.componentArea"
                               class="form-control"
                               id="field_name"
                               name="name"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                            <small [hidden]="!editForm.controls.name?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_unit">
                            Unit
                        </label>
                        <select [(ngModel)]="componentArea.unit"
                                class="form-control"
                                id="field_unit"
                                name="unit"
                                required>
                            <option [ngValue]="null"></option>
                            <option *ngFor="let unitOption of units; trackBy: trackUnitById"
                                    [ngValue]="unitOption.id === componentArea.unit?.id ? componentArea.unit : unitOption">
                                {{unitOption.unit}}
                            </option>
                        </select>
                        <div [hidden]="!(editForm.controls.unit?.dirty && editForm.controls.unit?.invalid)">
                            <small [hidden]="!editForm.controls.unit?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>

