<div class="card">
        <div class="header">
            <div class="row">
                <div class="col-sm-6">
                    <h1 style="line-height: 40px">
                        Areas
                    </h1>
                </div>
                <div class="col-sm-6">
                    <button [routerLink]="['/area/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-area pull-right">
                        Create new Area
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="areas"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>
                        ID
                    </th>
                    <th>
                        AREA
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let area of areas; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/area', area.id, 'view' ]">
                            {{ area.id }}
                        </a>
                    </td>
                    <td>
                        {{ area.area }}
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div class="bp-btn-group"
                             role="group">
                            <button [routerLink]="['/area', area.id, 'view' ]"
                                    class="btn btn-info waves-effect"
                                    type="submit">
                                <i class="material-icons">pageview</i>
                            </button>
                            <button [routerLink]="['/area', area.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button (click)="onDeleteArea(area)"
                                    class="btn btn-danger waves-effect"
                                    type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>


