<div class="resource-cost flex flex-row justify-content-around gap-40"
     *ngIf="dashboardStore.resourceCost$ | async as resourceCost">
    <div style="max-width: 450px; width: 100%;">
        <div class="flex flex-column gap-20">
            <div class="resource-cost__row flex flex-row gap-20 justify-content-between">
                <div class="flex align-items-center gap-10">
                    <div class="resource-cost__row__squire squire_1">
                    </div>
                    <div class="resource-cost__row__label">
                        Building Materials
                    </div>
                </div>
                <div class="resource-cost__row__cost">
                    {{ resourceCost.buildingMaterial || 0 | currency:'GBP' }}
                </div>
            </div>

            <div class="resource-cost__row flex flex-row gap-20 justify-content-between">
                <div class="flex align-items-center gap-10">
                    <div class="resource-cost__row__squire squire_2">
                    </div>
                    <div class="resource-cost__row__label">
                        Labour
                    </div>
                </div>
                <div class="resource-cost__row__cost">
                    {{ resourceCost.labour || 0 | currency:'GBP' }}
                </div>
            </div>

            <div class="resource-cost__row flex flex-row gap-20 justify-content-between">
                <div class="flex align-items-center gap-10">
                    <div class="resource-cost__row__squire squire_3">
                    </div>
                    <div class="resource-cost__row__label">
                        Finishing Materials
                    </div>
                </div>
                <div class="resource-cost__row__cost">
                    {{ resourceCost.finishingMaterial || 0 | currency:'GBP' }}
                </div>
            </div>

            <div class="resource-cost__row flex flex-row gap-20 justify-content-between"
                 *ngIf="resourceCost.profit != null">
                <div class="flex align-items-center gap-10">
                    <div class="resource-cost__row__squire squire_4">
                    </div>
                    <div class="resource-cost__row__label">
                        OH & P
                    </div>
                </div>
                <div class="resource-cost__row__cost">
                    {{ resourceCost.profit || 0 | currency:'GBP' }}
                </div>
            </div>

            <div class="resource-cost__row-total flex flex-row gap-20 justify-content-between">
                <div class="flex align-items-center gap-10">
                    <div class="resource-cost__row-total__squire">
                    </div>
                    <div class="resource-cost__row-total__label">
                        Total
                    </div>
                </div>
                <div>
                    <div class="resource-cost__row-total__cost">
                        {{ total | currency:'GBP' }}
                    </div>
                    <div class="resource-cost__row-total__ex-vat">
                        Ex. VAT
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 30%">
        <div class="resource-cost__chart"
             *ngIf="doughnutChartData?.datasets?.length; else noChartData">
            <canvas baseChart
                    id="canvas-id"
                    [data]="doughnutChartData"
                    [type]="'doughnut'"
                    [options]="doughnutChartOptions">
            </canvas>
        </div>

        <ng-template #noChartData>
            <div class="text-center">
                No Data for chart Available
            </div>
        </ng-template>

    </div>

</div>
