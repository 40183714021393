import { ITemplateRequest } from 'app/shared/model/template-request.model';
import { TemplateRequestApi } from 'app/shared/dataservices/template-request.api';
import { ITemplateRequestData, TemplateWizardStore } from 'app/flows/scheduler/template-wizard/template-wizard.store';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IScheduleTemplate } from 'app/shared/model/schedule-template.model';
import { BpAlertService } from 'app/shared/services/bp-alert.service';

const SELECTED_TEMPLATES_LIMIT = 28;

@Injectable({ providedIn: 'root' })
export class TemplateWizardService {
    constructor(
        private alertService: BpAlertService,
        private templateRequestApi: TemplateRequestApi,
        private store: TemplateWizardStore
    ) {
    }

    init(): void {
    }

    sendTemplateRequest(projectId: number, templateRequestData: ITemplateRequestData): Promise<boolean> {
        return new Promise(resolve => {
            const promises = this.generatePromises(projectId, [templateRequestData]);
            if (promises.length) {
                Promise.all(promises).then(() => {
                    resolve(true);
                })
            } else {
                resolve(false);
            }
        });
    }

    sendTemplateRequestsBatchIfNeeded(projectId: number): Promise<boolean> {
        return new Promise(resolve => {
            const promises = this.generatePromises(projectId, this.store.templateRequestDataArray);
            if (promises.length) {
                Promise.all(promises).then(() => {
                    resolve(true);
                })
            } else {
                resolve(false);
            }
        });
    }

    decTemplateCount(template: IScheduleTemplate): void {
        if (template.count > 0) {
            template.count--;
        }
    }

    incTemplateCount(template: IScheduleTemplate): void {
        if (this.store.data.getTemplatesWithTags().length >= SELECTED_TEMPLATES_LIMIT) {
            this.alertService.warning(`You have selected more than the ${SELECTED_TEMPLATES_LIMIT} template limit. Please remove templates until you are below this number. You can always add more after the project is created. Many thanks!`);
            return;
        }

        template.count++;
    }

    private generatePromises(projectId: number, templateRequestDataArray: ITemplateRequestData[]): Function[] {
        const promises = [];
        if (templateRequestDataArray?.length) {
            templateRequestDataArray.forEach((templateRequestData) => {
                const templateRequest: ITemplateRequest = {
                    name: templateRequestData.name,
                    description: templateRequestData.description,
                    projectId
                };
                promises.push(lastValueFrom(
                    this.templateRequestApi.create(templateRequest,
                    templateRequestData.attachments.map(a => a.file),
                    templateRequestData.attachments.map(a => a.additionalProperties)[0]))
                );
            });
        }

        return promises;
    }
}
