<div>
    <div class="main-fixed-container">
        <div class="flex justify-content-end">
            <button (click)="onRequestProfessionalClick()"
                    class="d-none d-md-inline btn btn-secondary btn-border-radius request-professional"
                    ngbTooltip="Request Professional"
                    type="button">
                Request Professional
            </button>

            <button (click)="onRequestProfessionalClick()"
                    class="d-md-none btn btn-secondary btn-circle edit-schedule-areas"
                    ngbTooltip="Request Professional">
                <i class="material-icons md-24">
                    account_circle
                </i>
            </button>
        </div>

        <form [formGroup]="form"
              *ngIf="form"
              class="new-invitation-form">
            <button #submitFormButton
                    id="submitFormButton"
                    type="submit">
            </button>

            <div *ngIf="project?.isOwner || project.privilege === 'EDIT'"
                 class="invite-professional-table">
                <div class="invite-professional-table__column">
                    <div class="invite-professional-table__column__header">
                        Invite
                    </div>
                    <div class="invite-professional-table__column__value">
                        <input autofocus
                               class="af-input email"
                               email
                               formControlName="email"
                               name="email"
                               placeholder="Add email"
                               required
                               type="email">

                        <div *ngIf="form.controls.email.dirty && form.controls.email.invalid"
                             class="position-absolute">
                            <small *ngIf="form.controls.email.errors.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>

                            <small *ngIf="form.controls.email.errors.maxlength"
                                   class="form-text text-danger">
                                This field cannot be longer than {{ form.controls.email.errors.minlength.maxlength }}
                                characters.
                            </small>

                            <small *ngIf="form.controls.email.errors.minlength"
                                   class="form-text text-danger">
                                This field is required to be at
                                least {{ form.controls.email.errors.minlength.requiredLength }}
                                characters.
                            </small>

                            <small *ngIf="form.controls.email.errors.email"
                                   class="form-text text-danger">
                                Your email is invalid.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="invite-professional-table__column">
                    <div class="invite-professional-table__column__header">
                        Permission
                    </div>
                    <div class="invite-professional-table__column__value flex justify-content-between gap-10">
                        <div class="permission-select-container">
                            <select class="form-control"
                                    formControlName="permission">
                                <option
                                    *ngFor="let permission of permissions;"
                                    [ngValue]="permission">
                                    {{ permission.title }}
                                </option>
                            </select>
                        </div>

                        <div class="d-md-none">
                            <button (click)="submitFormButton.click()"
                                    [disabled]="form.invalid || inProcessSentInvitation"
                                    class="btn btn-secondary btn-circle send-invite"
                                    ngbTooltip="Send Invite">
                                <i class="material-icons md-24">
                                    person_add
                                </i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="invite-professional-table__column d-none d-md-flex">
                    <div class="invite-professional-table__column__header">
                        Phone
                    </div>
                    <div class="invite-professional-table__column__value">
                        <input class="af-input phone"
                               formControlName="phone"
                               name="phone"
                               placeholder="Phone"
                               type="text">
                    </div>
                </div>
                <div class="invite-professional-table__column d-none d-md-flex">
                    <div class="invite-professional-table__column__header">
                    </div>
                    <div class="invite-professional-table__column__value">
                        <div class="flex-1 md-text-right">
                            <button (click)="onSendInviteClick()"
                                    [disabled]="form.invalid || inProcessSentInvitation"
                                    class="d-none d-md-inline btn btn-secondary btn-border-radius send-invite m-l-20"
                                    ngbTooltip="Send Invite"
                                    type="button">
                                Send Invite
                            </button>
                        </div>
                    </div>
                </div>
                <div class="invite-professional-table__column d-none d-md-flex">
                    <div class="invite-professional-table__column__header">
                    </div>
                    <div class="invite-professional-table__column__value">
                    </div>
                </div>
                <div class="invite-professional-table__column w-controls d-none d-md-flex">
                    <div class="invite-professional-table__column__header">
                    </div>
                    <div class="invite-professional-table__column__value">
                    </div>
                </div>
            </div>
        </form>

        <div class="team-table d-none d-md-flex">
            <div class="team-table__header">
                <div class="team-table__header__column">
                    Email
                </div>
                <div class="team-table__header__column">
                    Permission
                </div>
                <div class="team-table__header__column">
                    Phone
                </div>
                <div class="team-table__header__column">
                    Company
                </div>
                <div class="team-table__header__column">
                    Status
                </div>
                <div class="team-table__header__controls">
                </div>
            </div>

            <div class="team-table__body">
                <div *ngFor="let projectTeamMember of projectTeamMembers"
                     class="team-table__body__row">
                    <div class="team-table__body__row__cell">
                        {{ projectTeamMember.email }}
                    </div>
                    <div class="team-table__body__row__cell">
                        {{ getPrivilege(projectTeamMember) }}
                    </div>
                    <div class="team-table__body__row__cell">
                        {{ projectTeamMember.phone }}
                    </div>
                    <div class="team-table__body__row__cell">
                        {{ projectTeamMember.companyName }}
                    </div>
                    <div class="team-table__body__row__cell">
                        <div [ngSwitch]="projectTeamMember.status"
                             class="table-value">
                            <span *ngSwitchCase="'SENT'" class="label bg-sent">PENDING</span>
                            <span *ngSwitchCase="'ACCEPTED'" class="label bg-accepted">ACCEPTED</span>
                        </div>
                    </div>

                    <div class="team-table__body__row__controls align-right">
                        <button (click)="confirmRemoveProjectTeamMember(projectTeamMember)"
                                [disabled]="isRemoveTeamMemberDisabled(projectTeamMember)"
                                type="button"
                                class="btn btn-circle btn-small btn-secondary"
                                ngbTooltip="Remove project team member"
                                placement="left">
                            <i class="material-icons md-18">close</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="team-table-mobile d-md-none">
            <div *ngFor="let projectTeamMember of projectTeamMembers; let last = last"
                 [ngClass]="{ 'last' : last }"
                 class="team-table-mobile__row">
                <div class="team-table-mobile__row__team">
                    <div class="team-table-mobile__row__team__label">
                        Team
                    </div>

                    <div class="team-table-mobile__row__team__value">
                        {{ projectTeamMember.email }}
                    </div>
                    <div *ngIf="projectTeamMember.phone"
                         class="team-table-mobile__row__team__value">
                        <i class="material-icons md-18">phone</i><span>{{ projectTeamMember.phone }}</span>
                    </div>
                    <div *ngIf="projectTeamMember.companyName"
                         class="team-table-mobile__row__team__value">
                        <i class="material-icons md-18">assured_workload</i> {{ projectTeamMember.companyName }}
                    </div>
                    <div class="team-table-mobile__row__team__value">
                        <div [ngSwitch]="projectTeamMember.status"
                             class="table-value">
                            <span *ngSwitchCase="'SENT'" class="label bg-sent">PENDING</span>
                            <span *ngSwitchCase="'ACCEPTED'" class="label bg-accepted">ACCEPTED</span>
                        </div>
                    </div>
                </div>

                <div class="team-table-mobile__row__permission">
                    <div class="team-table-mobile__row__permission__label">
                        Permission
                    </div>

                    <div class="team-table-mobile__row__permission__value">
                        {{ getPrivilege(projectTeamMember) }}
                    </div>
                </div>

                <div class="team-table-mobile__row__delete-button">
                    <button (click)="confirmRemoveProjectTeamMember(projectTeamMember)"
                            [disabled]="isRemoveTeamMemberDisabled(projectTeamMember)"
                            class="btn btn-circle btn-small btn-secondary"
                            ngbTooltip="Remove project team member"
                            placement="left">
                        <i class="material-icons md-18">close</i>
                    </button>
                </div>
            </div>
        </div>

        <div class="d-none d-md-inline">
            <div class="flex-between-container align-items-center m-t-100 m-b-50">
                <div class="flex-1">
                </div>
                <div class="flex-1 text-right">
                    <button [routerLink]="['../../edit-schedule-areas', project.id]"
                            class="btn btn-primary btn-border-radius waves-effect next"
                            ngbTooltip="Save and Exit"
                            type="button">
                        Next
                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fixed-footer-mobile">
    <div class="flex-between-container align-items-center">
        <div class="flex-1">
        </div>
        <div class="flex-1 text-right">
            <button [routerLink]="['../../edit-schedule-areas', project.id]"
                    class="btn btn-primary btn-border-radius waves-effect next"
                    ngbTooltip="Save and Exit"
                    type="button">
                Next
                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            </button>
        </div>
    </div>
</div>

