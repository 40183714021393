import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProject } from 'app/shared/model/project.model';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import { DashboardFilterMode, DashboardViewMode } from 'app/flows/scheduler/dashboard/dashboard.interfaces';
import {
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    IOverviewProjectChartData
} from 'app/shared/components/projects/project-details-cost-visualization/overview-project-chart.service';
import { IResourceCost } from 'app/shared/model/bp.model';
import {
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IAccount } from 'app/shared/model/account.model';
import { IInvitation } from 'app/shared/model/invitation.model';

@Injectable({ providedIn: 'root' })
export class DashboardStore {
    constructor() {
    }

    account: IAccount;
    isRevertPossible = false;
    isShowUpdateButtonEnabled = false;
    isShowAverageRatesButtonEnabled = false;
    isUpdateAllToLatestVersionButtonEnabled = false;
    readOnly = true;

    nativeScheduleAreas: IScheduleArea[] = [];

    scheduleAreaItems: IMainViewFilterItemItem[];
    stageItems: IMainViewFilterItemItem[];
    cssElementItems?: IMainViewFilterItemItem[];

    areaIds: number[];
    stageIds: number[];
    cssElementIds: number[];

    invitations: IInvitation[] = [];
    quoters: IComparisonQoter[] = [];
    nativeComparison: IComparison = null;

    inProcessLoadingDataForMainFilter = false;
    inProcessLoadingStatData = false;
    inProcessUpdatingValueColumns = false;
    inProcessUpdatingDefaultQuoter = false;

    get inProcess(): boolean {
        return (
            this.inProcessLoadingDataForMainFilter ||
            this.inProcessLoadingStatData ||
            this.inProcessUpdatingValueColumns ||
            this.inProcessUpdatingDefaultQuoter
        );
    }

    get filterStateIds(): number[] {
        switch (this.mode) {
            case 'areas':
                return this.filterState.areaIds;
            case 'stages':
                return this.filterState.stageIds;
        }
    }

    get items(): IMainViewFilterItemItem[] {
        switch (this.mode) {
            case 'areas':
                return this.scheduleAreaItems;
            case 'stages':
                return this.stageItems;
        }
    }

    /* ------------------------------------------  */

    initedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    initedState$: Observable<boolean> = this.initedSubject.asObservable();

    set inited(value: boolean) {
        this.initedSubject.next(value);
    }

    get inited(): boolean {
        return this.initedSubject.value;
    }

    get mode(): DashboardFilterMode {
        return this.filterState?.groupBy === 'area' || this.filterState?.groupBy === 'stage_room' ? 'areas' : 'stages';
    }

    get selectedQuoters(): IComparisonQoter[] {
        switch (this.dashboardMode) {
            case 'comparison-view':
                return this.quoters.filter(q => q.selected);
            default:
                return [this.quoters.find(q => q.id === this.project.defaultQuoter.id)];
        }
    }

    /* ------------------------------------------  */

    dashboardModeSubject = new BehaviorSubject<DashboardViewMode | null>(null);
    dashboardMode$ = this.dashboardModeSubject.asObservable();

    set dashboardMode(value: DashboardViewMode | null) {
        this.dashboardModeSubject.next(value);
    }

    get dashboardMode(): DashboardViewMode | null {
        return this.dashboardModeSubject.value;
    }

    /* ------------------------------------------  */

    projectSubject = new BehaviorSubject<IProject | null>(null);
    project$ = this.projectSubject.asObservable();

    set project(value: IProject | null) {
        this.projectSubject.next(value);
    }

    get project(): IProject | null {
        return this.projectSubject.value;
    }

    /* ------------------------------------------  */

    filterStateSubject = new BehaviorSubject<IMainViewFilterState>(null);
    filterState$ = this.filterStateSubject.asObservable();

    set filterState(value: IMainViewFilterState) {
        this.filterStateSubject.next(value);
    }

    get filterState(): IMainViewFilterState {
        return this.filterStateSubject.value;
    }

    /* ------------------------------------------  */

    valueColumnsSubject = new BehaviorSubject<ICostPlanValueColumn[]>([]);
    valueColumns$ = this.valueColumnsSubject.asObservable();

    set valueColumns(value: ICostPlanValueColumn[]) {
        this.valueColumnsSubject.next(value);
    }

    get valueColumns(): ICostPlanValueColumn[] {
        return this.valueColumnsSubject.value;
    }

    /* ------------------------------------------  */

    overviewProjectChartDataSubject: BehaviorSubject<IOverviewProjectChartData> = new BehaviorSubject<IOverviewProjectChartData>(null);
    overviewProjectChartDataState$: Observable<IOverviewProjectChartData> = this.overviewProjectChartDataSubject.asObservable();

    set overviewProjectChartData(value: IOverviewProjectChartData) {
        this.overviewProjectChartDataSubject.next(value);
    }

    get overviewProjectChartData(): IOverviewProjectChartData {
        return this.overviewProjectChartDataSubject.value;
    }

    /* ------------------------------------------  */

    resourceCostSubject: BehaviorSubject<IResourceCost | null> = new BehaviorSubject<IResourceCost>(null);
    resourceCost$: Observable<IResourceCost | null> = this.resourceCostSubject.asObservable();

    set resourceCost(value: IResourceCost | null) {
        this.resourceCostSubject.next(value);
    }

    get resourceCost(): IResourceCost | null {
        return this.resourceCostSubject.value;
    }

}
