<div class="project-card-container clickable"
     (click)="onSelect()"
     [ngClass]="{ 'admin-card': isAdmin, 'non-admin-card': !isAdmin, 'scheduler-card': firstPath === '/scheduler', 'quoter-card':  firstPath === '/quoter'}">
    <div class="flex-container gap-10">
        <div style="width: 130px; min-width: 130px">
            <div class="project-card-container__map-container"
                 *ngIf="!project.icon">
                <div style="height: 130px; background: #f7f7f7;"
                     *ngIf="mapOptions"
                     leaflet
                     [leafletOptions]="mapOptions">
                </div>
            </div>

            <div class="image-container"
                 *ngIf="project.icon">
                <bp-image-previewer [icon]="project.icon"
                                    [height]="'130px'">
                </bp-image-previewer>
            </div>
        </div>
        <div style="flex: 1; display: flex; flex-direction: column; justify-content: space-between">
            <div class="m-t-10">
                <div class="flex-between-container">
                    <div class="project-card-container__project-name ellipsis-overflow-container"
                         [title]="project.address">
                        {{ project.address }}
                    </div>

                    <ng-container *ngIf="!isAdmin">
                        <div class="project-card-container__status m-r-10">
                            <bp-project-status
                                [status]="project.status">
                            </bp-project-status>
                        </div>
                    </ng-container>

                    <div ngbDropdown class="project-card-container__context-menu"
                         (click)="$event.stopPropagation()">
                        <button type="button"
                                class="project-card-container__context-menu__dot-button"
                                ngbDropdownToggle>
                            <span>...</span>
                        </button>
                        <div ngbDropdownMenu class="project-card-container__context-menu__menu">
                            <button ngbDropdownItem
                                    (click)="copyProject($event)">
                                Copy project
                            </button>

                            <button ngbDropdownItem
                                    (click)="toggleArchiveProject($event)">
                                {{project.archive ? 'Unarchive Project' : 'Archive Project' }}
                            </button>

                            <button ngbDropdownItem
                                    (click)="deleteProjectWithConfirm()"
                                    *ngIf="project.isOwner">
                                Delete Project
                            </button>
                        </div>
                    </div>
                </div>

                <div class="project-card-container__project-second-line-address ellipsis-overflow-container"
                     *ngIf="!isAdmin"
                     [title]="project.addressSecondLine">
                    {{ project.addressSecondLine }}
                </div>
                <div class="project-card-container__client-name ellipsis-overflow-container"
                     *ngIf="false && !isAdmin">
                    {{ project.clientName }}
                </div>
            </div>

            <ng-container *ngIf="!isAdmin">
                <div>
                    <div class="project-card-container__estimations m-t-20 ellipsis-overflow-container">
                        Est. {{ project.minCost || 0 | currency:'GBP' }} - {{ project.maxCost || 0 | currency:'GBP' }}
                    </div>
                    <div class="project-card-container__description m-t-10 ellipsis-overflow-container">
                        {{ project.description }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
