import { Component, Input, OnInit } from '@angular/core';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IScheduleTaskQuoterTotalModificationEvent } from 'app/shared/constants/events.constants';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';
import { IBuildUp } from 'app/shared/model/bp.model';
import { StorageServiceProvider, WebStorageService } from 'app/shared/services/web-storage.service';
import { BuildUpApi } from "app/shared/dataservices/build-up.api";
import { lastValueFrom } from "rxjs";
import { BpAlertService } from "app/shared/services/bp-alert.service";
import { SelectInputData } from "app/shared/components/common/select-input/select-input.component";

@UntilDestroy()
@Component({
    selector: 'bp-schedule-build-up',
    templateUrl: './build-up.component.html',
    styleUrls: [
        '../../../schedule-common.scss',
        '../../root-container/stage.scss',
        'build-up.scss'
    ],
    providers: [
        StorageServiceProvider, {
            provide: 'storageKeyConfig',
            useValue: { prefix: 'scheduler-build-up-inside-areas-grouping', separator: '-' }
        }]
})
export class BuildUpForFilterByStageGroupingComponent implements OnInit {
    @Input() buildUp: IBuildUp;
    @Input() scheduleAreaId: number;
    @Input() scheduleTasks: IScheduleTask[] = [];

    protected buildUpSelectInputData: SelectInputData;

    protected relatedBuildUps?: IBuildUp[];

    protected expanded = false;

    constructor(
        protected scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        private confirmModalService: ConfirmModalService,
        private webStorageService: WebStorageService,
        private buildUpApi: BuildUpApi,
        private alertService: BpAlertService,
    ) {
    }

    protected get total(): number {
        let total = 0;
        this.filteredScheduleTasks().forEach((st) => total += st.taskTotal.total);
        return total;
    }

    private get webStoragePath(): string[] {
        return [String(this.scheduleService.project.id), String(this.scheduleAreaId), String(this.buildUp.id)];
    }

    ngOnInit(): void {
        this.expanded = this.webStorageService.retrieve(this.webStoragePath) as boolean;

        this.registerScheduleTaskQuoterTotalModificationEvent();
        this.fillBuildUpSelectInputData();

        this.scheduleService.expandAll$
            .pipe(
                skip(1),
                untilDestroyed(this)
            ).subscribe((res) => {
            this.expanded = res;
            this.webStorageService.store(this.webStoragePath, res);
        })
    }

    protected async loadRetatedBuildUps(): Promise<void> {
        const res = await lastValueFrom(this.buildUpApi.related(this.buildUp.id));
        this.relatedBuildUps = [this.buildUp, ...res.body];
    }

    protected toggleStageExpand(): void {
        this.expanded ? this.collapse() : this.expand();
    }

    protected onDeleteCssElementClick(): void {
        this.confirmModalService.open({
            header: `Delete ${this.buildUp.name} css element?`,
            textHtml: `<div class='strong m-b-10'>
                            This will delete all its associated tasks and costs.
                        </div>
                        <div class='strong m-b-10'>
                            Note: You can re-add tasks to this css-element by clicking 'On' in the templates section or using the 'add task' function.
                        </div>`
        }).result.then((res) => {
            if (!res) {
                return;
            }
            this.scheduleService.deleteScheduleTasks(this.scheduleTasks.filter((st) => st.buildUpId === this.buildUp.id));
        })
    }

    protected filteredScheduleTasks(): IScheduleTask[] {
        return _.filter(this.scheduleTasks, scheduleTask => {
            return !scheduleTask.markedAsDeleted && (this.scheduleService.showSubStagesMode !== 'finishes' || scheduleTask.finishes);
        });
    }

    protected onBuildUpChange(event: IBuildUp): void {
        this.scheduleService.changeBuildUpForScheduleTasks(event, this.scheduleTasks).then(() => {
            this.scheduleService.reloadAllSavingCurrentScrollPosition();
            this.alertService.success('Build up was successfully updated for the schedule tasks');
        });
    }

    private fillBuildUpSelectInputData(): void {
        this.loadRetatedBuildUps().then(() => {
            this.buildUpSelectInputData = {
                indexProperty: 'id',
                titleProperty: 'name',
                initIndex: this.buildUp.id,
                data: this.relatedBuildUps,
                dropdownPosition: 'bottom',
            };
        })
    }

    private expand(): void {
        this.expanded = true;
        this.webStorageService.store(this.webStoragePath, true);
    }

    private collapse(): void {
        this.expanded = false;
        this.webStorageService.store(this.webStoragePath, false);
    }

    private registerScheduleTaskQuoterTotalModificationEvent(): void {
        this.scheduleEventsService.scheduleTaskTotalModified$
            .pipe(
                untilDestroyed(this)
            ).subscribe((event: IScheduleTaskQuoterTotalModificationEvent) => {
            let total = 0;
            this.scheduleTasks.forEach(scheduleTask => {
                if (!scheduleTask.markedAsDeleted) {
                    total += scheduleTask.taskTotal.total;
                }
            });

            this.buildUp.total = total;
            this.scheduleEventsService.stageQuoterTotalModified();
        })
    }
}
