<div class="row justify-content-center">
        <div class="col-8">
            <div *ngIf="stage">
                <h1>
                    <span>Stage</span> {{stage.id}}
                </h1>
                <hr>
                <dl class="row-md jh-entity-details">
                    <dt>
                        <span>Stage</span>
                    </dt>
                    <dd>
                        <span>{{stage.stage}}</span>
                    </dd>
                    <dt><span>Stage Order</span></dt>
                    <dd>
                        <span>{{stage.stageOrder}}</span>
                    </dd>
                </dl>

                <button (click)="previousState()"
                        class="btn btn-info"
                        type="submit">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon><span> Back</span>
                </button>

                <button [routerLink]="['/stage', stage.id, 'edit']"
                        class="btn btn-primary"
                        type="button">
                    <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>&nbsp;<span> Edit</span>
                </button>
            </div>
        </div>
    </div>

