<div>
    <div class="block-header m-b-10">
        <div class="text-center">
            <h1>
                Create or edit a Task
            </h1>
        </div>
    </div>

    <div class="justify-content-center"
         style="max-width: 1366px; margin: 0 auto">
        <form #editForm="ngForm"
              name="editForm"
              novalidate
              role="form">
            <div class="row">
                <div [hidden]="!task.id"
                     class="col-lg-2 form-group">
                    <label for="id">
                        ID
                    </label>
                    <input [(ngModel)]="task.id"
                           class="form-control"
                           id="id"
                           name="id"
                           readonly
                           type="text"/>
                </div>
                <div class="col-lg-2 form-group">
                    <label class="form-control-label"
                           for="field_taskOrder">
                        Task Order
                    </label>
                    <input [(ngModel)]="task.taskOrder"
                           class="form-control"
                           id="field_taskOrder"
                           name="field_taskOrder"
                           type="number"/>
                </div>
                <div [ngClass]="{'col-lg-8': task.id, 'col-lg-10': !task.id}"
                     class="form-group">
                    <label class="form-control-label"
                           for="field_task">
                        Task
                    </label>
                    <input [(ngModel)]="task.task"
                           class="form-control"
                           id="field_task"
                           name="field_task"
                           required
                           type="text"/>
                    <div [hidden]="!(editForm.controls.task?.dirty && editForm.controls.task?.invalid)">
                        <small [hidden]="!editForm.controls.task?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 form-group">
                    <label class="form-control-label"
                           for="field_componentarea">
                        Component Area
                    </label>
                    <select (change)="onComponentAreaChange()"
                            [(ngModel)]="task.componentarea"
                            class="form-control"
                            id="field_componentarea"
                            name="field_componentarea"
                            required>
                        <option [ngValue]="null"></option>
                        <option
                            *ngFor="let componentAreaOption of componentareas; trackBy: trackComponentAreaById"
                            [ngValue]="componentAreaOption.id === task.componentarea?.id ? task.componentarea : componentAreaOption">
                            {{componentAreaOption.componentArea + ', ' + componentAreaOption?.unit?.unit}}
                        </option>
                    </select>
                    <div
                        [hidden]="!(editForm.controls.componentarea?.dirty && editForm.controls.componentarea?.invalid)">
                        <small [hidden]="!editForm.controls.componentarea?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>
                <div class="col-lg-3 form-group">
                    <label class="form-control-label"
                           for="field_unit">
                        Unit
                    </label>
                    <select [(ngModel)]="task.unit"
                            [disabled]="task?.componentarea?.componentArea !== NA"
                            class="form-control"
                            id="field_unit"
                            name="field_unit">
                        <option [ngValue]="null">
                        </option>
                        <option
                            *ngFor="let unitOption of units; trackBy: trackUnitById"
                            [ngValue]="unitOption.id === task.unit?.id ? task.unit : unitOption">
                            {{unitOption.unit}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 form-group">
                    <label class="form-control-label"
                           for="field_material_categories">
                        Material Categories
                    </label>

                    <bp-search-by-select
                        (onSelectionChange)="onMaterialCategorySelectionChange($event)"
                        *ngIf="materialCategorySelectInputData"
                        [data]="materialCategorySelectInputData"
                        multiply="true"
                        class="select-material-categories-id"
                        id="field_material_categories">
                    </bp-search-by-select>
                </div>
                <div class="col-lg-2 form-group">
                    <label class="form-control-label"
                           for="field_css_element">
                        CSS Element
                    </label>

                    <bp-search-by-select
                        (onSelectionChange)="onCssElementSelectionChange($event)"
                        *ngIf="cssElementSelectInputData"
                        [data]="cssElementSelectInputData"
                        class="select-css-element-id"
                        id="field_css_element">
                    </bp-search-by-select>
                </div>
                <div class="col-lg-2 form-group">
                    <label class="form-control-label"
                           for="field_build_ups">
                        Build Ups
                    </label>

                    <bp-search-by-select
                        (onSelectionChange)="onBuildUpSelectionChange($event)"
                        *ngIf="buildUpSelectInputData"
                        [data]="buildUpSelectInputData"
                        multiply="true"
                        class="select-build-ups-id"
                        id="field_build_ups">
                    </bp-search-by-select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 form-group">
                    <label class="form-control-label"
                           for="field_stage">
                        Stage
                    </label>
                    <select (change)="onStageSelect()"
                            [(ngModel)]="task.stage"
                            class="form-control"
                            id="field_stage"
                            name="field_stage"
                            required>
                        <option [ngValue]="null">
                        </option>
                        <option *ngFor="let stageOption of stages; trackBy: trackStageById"
                                [ngValue]="stageOption.id === task.stage?.id ? task.stage : stageOption">{{stageOption.stage}}
                        </option>
                    </select>
                    <div [hidden]="!(editForm.controls.stage?.dirty && editForm.controls.stage?.invalid)">
                        <small [hidden]="!editForm.controls.stage?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>
                <div class="col-lg-4 form-group">
                    <label class="form-control-label"
                           for="field_element">
                        Element
                    </label>
                    <select [(ngModel)]="task.element"
                            class="form-control"
                            id="field_element"
                            name="element"
                            required>
                        <option [ngValue]="null">
                        </option>
                        <option *ngFor="let elementOption of elements; trackBy: trackElementById"
                                [ngValue]="elementOption.id === task.element?.id ? task.element : elementOption">
                            {{elementOption.element}}
                        </option>
                    </select>
                    <div [hidden]="!(editForm.controls.element?.dirty && editForm.controls.element?.invalid)">
                        <small [hidden]="!editForm.controls.element?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>
                <div class="col-lg-2 form-group">
                    <div class="form-check">
                        <label class="form-check-label"
                               for="archive">
                            Archive
                            <input [(ngModel)]="task.archive"
                                   class="form-check-input"
                                   id="archive"
                                   name="archive"
                                   type="checkbox">
                            <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                        </label>
                    </div>

                    <div class="form-check">
                        <label class="form-check-label"
                               for="finish">
                            Finish
                            <input [checked]="task.type === 'FINISH'"
                                   (change)="onTaskFinishChange($event)"
                                   class="form-check-input"
                                   id="finish"
                                   name="finish"
                                   type="checkbox">
                            <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                        </label>
                    </div>
                </div>
                <div class="col-lg-2 form-group">
                    <label class="form-control-label"
                           for="field_taskRatio">
                        Ratio
                    </label>
                    <input [(ngModel)]="task.ratio"
                           class="form-control"
                           id="field_taskRatio"
                           name="field_taskRatio"
                           type="number"/>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th style="width: 50%">
                                    <span>Labour</span>
                                </th>
                                <th style="width: 40%">
                                    <span>Hours</span>
                                </th>
                                <th style="width: 10%">
                                    <button (click)="addLabourComponent()"
                                            title="Add labour component"
                                            class="btn btn-link">
                                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                    </button>
                                </th>
                            </tr>
                            </thead>
                            <tbody infinite-scroll>
                            <tr *ngFor="let labourComponent of task?.labourComponents;">
                                <td>
                                    <select [(ngModel)]="labourComponent.trade"
                                            [name]="'labor-trade-' + labourComponent.id"
                                            class="form-control">
                                        <option [ngValue]="null"></option>
                                        <option
                                            *ngFor="let tradeOption of trades; trackBy: trackTradeById"
                                            [ngValue]="tradeOption.id === labourComponent.trade?.id ? labourComponent.trade : tradeOption">
                                            {{tradeOption.trade}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input [(ngModel)]="labourComponent.quantity"
                                           [name]="'labour-quantity' + labourComponent.id"
                                           (change)="onComponentChange()"
                                           class="form-control"
                                           type="text"/>
                                </td>
                                <td>
                                    <button (click)="removeLabourComponent(labourComponent)"
                                            title="Remove labour component"
                                            class="btn btn-link">
                                        <fa-icon [icon]="['fas', 'ban']"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row m-t-10">
                <div class="col-lg-12">
                    <div class="table-responsive">
                        <table class="table table-striped materials">
                            <thead>
                            <tr>
                                <th style="width: 50%">
                                    <span>Material</span>
                                </th>
                                <th style="width: 20%">
                                    <span>Unit</span>
                                </th>
                                <th style="width: 20%">
                                    <span>Qty</span>
                                </th>
                                <th style="width: 10%">
                                    <button (click)="addMaterialComponent()"
                                            title="Add material component"
                                            class="btn btn-link">
                                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                    </button>
                                </th>
                            </tr>
                            </thead>
                            <tbody infinite-scroll>
                            <tr *ngFor="let materialComponent of task?.materialComponents;">
                                <td>
                                    <bp-search-by-select
                                        (onSelectionChange)="onMaterialComponentSelectionChange(materialComponent, $event)"
                                        *ngIf="materialComponent['_searchBySelectInputData']"
                                        [data]="materialComponent['_searchBySelectInputData']"
                                        [appendTo]="'table.materials'"
                                        class="select-material-component-id">
                                    </bp-search-by-select>
                                </td>
                                <td>
                                    <span>{{ materialComponent.material?.unit?.unit }}</span>
                                </td>
                                <td>
                                    <input [(ngModel)]="materialComponent.quantity"
                                           [name]="'material-quantity' + materialComponent.id"
                                           (change)="onComponentChange()"
                                           class="form-control"
                                           type="text"/>
                                </td>
                                <td>
                                    <button (click)="removeMaterialComponent(materialComponent)"
                                            title="Add material component"
                                            class="btn btn-link">
                                        <fa-icon [icon]="['fas', 'ban']"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="text-right m-t-20">
                <button (click)="previousState()"
                        class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                        ngbTooltip="cancel"
                        type="button">
                    Cancel
                </button>

                <button (click)="save()"
                        [disabled]="editForm.form.invalid || isSaving"
                        class="btn btn-primary btn-border-radius waves-effect save"
                        ngbTooltip="Save"
                        type="button">
                    Save
                </button>
            </div>

            <div class="row m-t-20">
                <div class="col-sm-12">
                    <bp-task-total [task]="task"
                                   [updateObservable]="updateSubject">
                    </bp-task-total>
                </div>
            </div>
        </form>
    </div>
</div>
