import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { ProjectCommentsService } from 'app/shared/dataservices/project-comments.service';
import { IProject } from 'app/shared/model/project.model';
import { IProjectComment } from 'app/shared/model/bp.model';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { last } from 'rxjs/operators';
import { AccountService } from 'app/core/auth/account.service';
import Swal from "sweetalert2";

@Component({
    selector: 'bp-project-comments-modal',
    templateUrl: './project-comments-modal.component.html',
    styleUrls: ['project-comments-modal.scss']
})
export class ProjectCommentsModalComponent {
    protected project: IProject;
    protected scheduleTaskId?: number;

    protected newCommentControl = new FormControl(null, [Validators.required]);

    private commentsSubject = new BehaviorSubject<IProjectComment[] | undefined>(undefined);
    protected comments$ = this.commentsSubject.asObservable();

    get accountId(): number {
        return this.accountService.getAccountId();
    }

    constructor(private activeModal: NgbActiveModal,
                private accountService: AccountService,
                private projectCommentsService: ProjectCommentsService) {
    }

    protected close(): void {
        this.activeModal.dismiss('close');
    }

    protected addComment(): void {
        lastValueFrom(this.projectCommentsService.create(this.project.id, this.newCommentControl.value, this.scheduleTaskId)).then(() => {
            this.newCommentControl.setValue(null);
            this.refresh();
        });
    }

    protected refresh(): void {
        lastValueFrom(this.projectCommentsService.query(this.project.id, this.scheduleTaskId)).then((res: HttpResponse<IProjectComment[]>) => {
            this.commentsSubject.next(res.body);
        })
    }

    protected trackById(index: number, comment: IProjectComment): number {
        return comment.id;
    }

    protected confirmRemove(comment: IProjectComment): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this comment!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Remove',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                lastValueFrom(this.projectCommentsService.delete(this.project.id, comment.id)).then((res: HttpResponse<IProjectComment[]>) => {
                    this.refresh();
                })
            }
        });
    }

    protected readonly last = last;
}
