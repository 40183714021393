<div class="row justify-content-center">
        <div class="col-8">
            <div *ngIf="area">
                <h1>
                    <span>Area</span> {{ area.id }}
                </h1>
                <hr>
                <dl class="row-md jh-entity-details">
                    <dt>
                        <span>Area</span>
                    </dt>
                    <dd>
                        <span>{{ area.area }}</span>
                    </dd>
                </dl>

                <button (click)="previousState()"
                        class="btn btn-info"
                        type="submit">
                    <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    <span> Back</span>
                </button>

                <button [routerLink]="['/area', area.id, 'edit']"
                        class="btn btn-primary"
                        type="button">
                    <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>&nbsp;<span> Edit</span>
                </button>
            </div>
        </div>
    </div>

