import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { ProjectAttachmentsService } from 'app/shared/dataservices/project-attachments.service';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IProjectAttachment } from 'app/shared/model/project-attachment.model';
import { UploadedFileCheckerService } from 'app/shared/services/uploaded-file-checker.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

class ImageSnippet {
    pending = false;

    constructor(public src: string, public file: File) {
    }
}

@Component({
    selector: 'bp-project-attachments',
    templateUrl: './project-attachments.component.html',
    styleUrls: ['project-attachments.scss']
})
export class ProjectAttachmentsComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    @Input() project: IProject;
    @Input() disabled = false;

    @Output() onUploaded = new EventEmitter();

    protected attachments: IProjectAttachment[];
    protected inProcessLoadingAttachments = false;

    constructor(
        private projectAttachmentsService: ProjectAttachmentsService,
        private uploadedFileCheckerService: UploadedFileCheckerService,
        private alertService: BpAlertService
    ) {
    }

    ngOnInit(): void {
        this.loadAttachments();
    }

    protected inProcess(): boolean {
        return this.inProcessLoadingAttachments;
    }

    protected processFile(imageInput: any): void {
        const file: File = imageInput.files[0];

        if (!this.uploadedFileCheckerService.check(file)) {
            return;
        }

        this.blockUI.start('Uploading.. Please wait.')
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            const selectedFile = new ImageSnippet(event.target.result, file);

            selectedFile.pending = true;
            this.projectAttachmentsService.upload(this.project.id, selectedFile.file)
                .subscribe(
                    (imageUrl: any) => {
                        selectedFile.pending = false;
                        this.loadAttachments();

                        this.onUploaded?.emit(imageUrl);

                        this.blockUI.stop();
                    },
                    () => {
                        selectedFile.pending = false;
                        selectedFile.src = '';
                        this.blockUI.stop();
                    }
                );
        });

        reader.readAsDataURL(file);
    }

    protected downloadAll(): void {
        const _downloadAll = (attachmet) => {
            return attachmet.reduce((p, at) => {
                return p.then(() => this.download(at));
            }, Promise.resolve());
        };

        this.blockUI.start('Downloading is in process. Please wait.')
        this.inProcessLoadingAttachments = true;
        _downloadAll(this.attachments).finally(() => {
            this.inProcessLoadingAttachments = false;
            this.blockUI.stop();
        }).then(() => {
            this.alertService.success('All attachments were successfully downloaded!');
        });
    }

    protected download(attachment: IProjectAttachment): Promise<void> {
        return lastValueFrom(this.projectAttachmentsService.download(this.project.id, attachment.id)).then((response: string) => {
            const splittedFileName = attachment.fileName.split('.');
            const ext = attachment.fileName.split('.')[splittedFileName.length - 1];

            let dataType = 'application/octet-stream';

            switch (ext.toLowerCase()) {
                case 'jpeg':
                case 'jpg':
                    dataType = 'image/jpeg';
                    break;
                case 'png':
                    dataType = 'image/png';
                    break;
                case 'gif':
                    dataType = 'image/gif';
                    break;
            }

            if (!dataType) {
                this.alertService.error('Cannot download file');
                return Promise.resolve();
            }

            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            const blob = new Blob(binaryData, { type: dataType });
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.setAttribute('download', attachment.originalName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            return Promise.resolve();
        });
    }

    protected showConfirmRemoveDocumentDialog(attachment: IProjectAttachment): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this file?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.remove(attachment);
            }
        });
    }

    protected remove(attachment: IProjectAttachment): void {
        this.projectAttachmentsService.delete(this.project.id, attachment.id).subscribe(() => {
            this.alertService.success('Document successfully removed!');
            this.loadAttachments();
        });
    }

    private loadAttachments(): void {
        this.inProcessLoadingAttachments = true;

        this.projectAttachmentsService
            .query(this.project.id)
            .pipe(
                finalize(() => {
                    this.inProcessLoadingAttachments = false;
                })
            )
            .subscribe((result: HttpResponse<IProjectAttachment[]>) => {
                this.attachments = result.body;
            });
    }
}
