<div *ngIf="project">
    <div class="flex justify-content-between">
        <div class="col-sm-2">
            <button [routerLink]="['../../dashboard', project.id]"
                    class="btn btn-default btn-border-radius waves-effect previous"
                    ngbTooltip="Back to Dashboard"
                    type="button">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back to Dashboard
            </button>
        </div>
        <div class="col-sm-10 text-right">
            <button (click)="makeProjectLive()"
                    *ngIf="proxyAdmin && projectHavePendingAddons"
                    [disabled]="inProcess()"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2"
                    ngbTooltip="Make Project Live">
                Make Project Live
            </button>

            <button (click)="copyLinkForTheClientToBuffer()"
                    [disabled]="inProcess()"
                    class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10"
                    ngbTooltip="Copy Link">
                Copy Link
            </button>

            <button (click)="copyLinkForTheClientToBuffer()"
                    [disabled]="inProcess()"
                    class="d-md-none btn btn-secondary btn-circle m-r-10"
                    ngbTooltip="Copy Link">
                <i class="material-icons md-24">
                    share
                </i>
            </button>

            <button [disabled]="inProcess()"
                    [routerLink]="['/scheduler/team', project.id]"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2"
                    ngbTooltip="Manage Team">
                Manage Team
            </button>

            <button (click)="copyProject()"
                    *ngIf="!readOnly"
                    [disabled]="inProcess()"
                    class="btn btn-secondary btn-border-radius waves-effect mr-2"
                    ngbTooltip="Copy Project">
                Copy Project
            </button>
            <button (click)="save(false, false)"
                    *ngIf="!readOnly"
                    [disabled]="form.invalid || inProcess()"
                    class="btn btn-primary btn-border-radius waves-effect"
                    ngbTooltip="Save and Exit"
                    type="button">
                Save and Exit
            </button>

            <div class="flex items-center justify-content-end autosave-line m-t-10 d-none d-md-flex">
                <ng-container *ngIf="!inProcess(); else isSavingContainer">
                    <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
                    Autosaved: {{ (autoSaveDate || project.scheduleLastModifiedDate) | date:'dd/MM/yy HH:mm' }}
                </ng-container>
                <ng-template #isSavingContainer>
                    <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
                    Saving...
                </ng-template>
            </div>

            <div class="m-b-5 m-t-10 d-md-none">
                <div class="flex items-center justify-content-end autosave-line">
                    <ng-container *ngIf="!inProcess(); else isSavingContainer">
                        <fa-icon [icon]="['fas', 'save']" class="mr-2"></fa-icon>
                        Autosaved: {{ (autoSaveDate || project.scheduleLastModifiedDate) | date:'dd/MM/yy HH:mm' }}
                    </ng-container>
                    <ng-template #isSavingContainer>
                        <fa-icon [icon]="['fas', 'spinner']" class="mr-2"></fa-icon>
                        Saving...
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="card m-t-20">
        <form (ngSubmit)="(submitted = true) && form.valid && save(false, false)"
              [formGroup]="form"
              name="form"
              novalidate>
            <button #submitFormButton
                    id="submitFormButton"
                    type="submit">
            </button>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <div class="form-line">
                            <input
                                [ngClass]="{ 'error': (submitted || form.controls.clientName.dirty || form.controls.clientName.touched) && form.controls.clientName.invalid }"
                                [readOnly]="readOnlyStrict"
                                autocomplete="off"
                                autofocus
                                class="af-input"
                                formControlName="clientName"
                                name="clientName"
                                placeholder="Client name"
                                type="text">
                        </div>
                        <div
                            *ngIf="(submitted || form.controls.clientName.dirty) && form.controls.clientName.invalid">
                            <label *ngIf="form.controls.clientName.errors.required"
                                   class="error">
                                This field is required.
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="form-line">
                            <input
                                [ngClass]="{ 'error': (submitted || form.controls.address.dirty || form.controls.address.touched) && form.controls.address.invalid }"
                                [readOnly]="readOnlyStrict"
                                autocomplete="off"
                                class="af-input"
                                formControlName="address"
                                name="address"
                                placeholder="First Line Address*"
                                type="text">
                            <div
                                *ngIf="(submitted || form.controls.address.dirty) && form.controls.address.invalid">
                                <label *ngIf="form.controls.address.errors.required"
                                       class="error">
                                    This field is required.
                                </label>
                                <label *ngIf="form.controls.address.errors.whitespace"
                                       class="error">
                                    Please enter valid data.
                                </label>
                                <label *ngIf="form.controls.address.errors.alreadyExists"
                                       class="error">
                                    Project with such address already exists.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isAdmin"
                         class="form-group">
                        <div class="form-line">
                            <input
                                [ngClass]="{ 'error': (submitted || form.controls.addressSecondLine.dirty || form.controls.addressSecondLine.touched) && form.controls.addressSecondLine.invalid }"
                                [readOnly]="readOnlyStrict"
                                autocomplete="off"
                                class="af-input"
                                formControlName="addressSecondLine"
                                name="addressSecondLine"
                                placeholder="Second Line Address"
                                type="text">
                            <div
                                *ngIf="(submitted || form.controls.addressSecondLine.dirty) && form.controls.addressSecondLine.invalid">
                                <label *ngIf="form.controls.addressSecondLine.errors.whitespace"
                                       class="error">
                                    Please enter valid data.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-line">
                                <input
                                    [ngClass]="{ 'error': (submitted || form.controls.phone.dirty || form.controls.phone.touched) && form.controls.phone.invalid }"
                                    [readOnly]="readOnlyStrict"
                                    autocomplete="off"
                                    class="af-input"
                                    formControlName="phone"
                                    name="phone"
                                    placeholder="Phone"
                                    type="text">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || form.controls.email.dirty || form.controls.email.touched) && form.controls.email.invalid }"
                                        [readOnly]="readOnlyStrict"
                                        autocomplete="off"
                                        class="af-input"
                                        formControlName="email"
                                        name="email"
                                        placeholder="Email"
                                        type="text">
                                    <div
                                        *ngIf="(submitted || form.controls.email.dirty) && form.controls.email.invalid">
                                        <label *ngIf="form.controls.email.errors.email"
                                               class="error">
                                            Please use correct email.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="form-line">
                            <bp-select-input
                                (onSelectionChange)="onRegionSelectionChange($event)"
                                *ngIf="regionsInputData"
                                [additionalClasses]="'fs-14'"
                                [data]="regionsInputData"
                                [placeholder]="'Region'"
                                [removeUnderlines]="true"
                                class="region-select-id">
                            </bp-select-input>
                        </div>
                    </div>

                    <div *ngIf="isAdmin"
                         class="form-group">
                        <div class="form-line">
                            <input
                                [readOnly]="readOnly"
                                autocomplete="off"
                                class="af-input"
                                formControlName="templateName"
                                name="templateName"
                                placeholder="Property template name"
                                type="text">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || form.controls.city.dirty || form.controls.city.touched) && form.controls.city.invalid }"
                                        [readOnly]="readOnlyStrict"
                                        autocomplete="off"
                                        class="af-input"
                                        formControlName="city"
                                        name="city"
                                        placeholder="City"
                                        type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <div class="form-line">
                                    <input
                                        [ngClass]="{ 'error': (submitted || form.controls.postcode.dirty || form.controls.postcode.touched) && form.controls.postcode.invalid }"
                                        [readOnly]="readOnlyStrict"
                                        autocomplete="off"
                                        class="af-input"
                                        formControlName="postcode"
                                        name="postcode"
                                        placeholder="Postcode *"
                                        type="text">
                                    <div
                                        *ngIf="(submitted || form.controls.postcode.dirty) && form.controls.postcode.invalid">
                                        <label *ngIf="form.controls.postcode.errors.required"
                                               class="error">
                                            This field is required.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a (click)="isVisibleAdditionalBlock = !isVisibleAdditionalBlock"
                       class="flex-between-container align-items-center cursor-pointer additional-information-toggle m-t-20"
                       ngbTooltip="{{ isVisibleAdditionalBlock ? 'Hide Additional Information' : 'Show Additional Information'}}">
                        <div class="gray-text font-weight-normal">
                            Additional information
                        </div>
                        <div>
                            <i *ngIf="isVisibleAdditionalBlock"
                               class="material-icons md-18">
                                arrow_upward
                            </i>
                            <i *ngIf="!isVisibleAdditionalBlock"
                               class="material-icons md-18">
                                arrow_downward
                            </i>
                        </div>
                    </a>

                    <ng-container *ngIf="isVisibleAdditionalBlock">
                        <div class="row m-t-20">
                            <div class="col-sm-4">
                                <div class="date-container">
                                    <label class="gray-text form-control-label"
                                           for="field_ProjectCreatedDate">
                                        Project Created Date
                                    </label>
                                    <div>
                                        <bp-date-picker
                                            [date]="project.createdDate"
                                            [disabled]="true"
                                            id="field_ProjectCreatedDate">
                                        </bp-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="date-container">
                                    <label class="gray-text form-control-label"
                                           for="field_TenderDeadlineDate">
                                        Tender Deadline Date
                                    </label>
                                    <div>
                                        <bp-date-picker
                                            (onDateChanged)="onTenderDeadlineDateSelect($event)"
                                            [date]="project.tenderDeadline"
                                            [disabled]="readOnly"
                                            id="field_TenderDeadlineDate">
                                        </bp-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="false" class="col-sm-4">
                                <div class="date-container">
                                    <label class="gray-text form-control-label"
                                           for="field_TenderDecisionDate">
                                        Tender Decision Date
                                    </label>
                                    <div>
                                        <bp-date-picker
                                            (onDateChanged)="onTenderDecisionDateSelect($event)"
                                            [date]="project.tenderDecision"
                                            [disabled]="readOnly"
                                            id="field_TenderDecisionDate">
                                        </bp-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="date-container">
                                    <label class="gray-text form-control-label"
                                           for="field_startDate">
                                        Project Start Date
                                    </label>
                                    <div>
                                        <bp-date-picker
                                            (onDateChanged)="onStartDateSelect($event)"
                                            [date]="project.startDate"
                                            [disabled]="readOnly"
                                            id="field_startDate">
                                        </bp-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || form.controls.tenderNotes.dirty || form.controls.tenderNotes.touched) && form.controls.tenderNotes.invalid }"
                                    [readOnly]="readOnly"
                                    autocomplete="off"
                                    class="af-input tender-notes"
                                    formControlName="tenderNotes"
                                    name="tenderNotes"
                                    placeholder="Summary of Works">
                                </textarea>
                                <div
                                    *ngIf="(submitted || form.controls.tenderNotes.dirty) && form.controls.tenderNotes.invalid">
                                    <label *ngIf="form.controls.tenderNotes.errors.maxlength"
                                           class="error">
                                        Maximum length
                                        is {{ form.controls.tenderNotes.errors.maxlength.requiredLength }}
                                        symbols
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-line">
                                <textarea
                                    [ngClass]="{ 'error': (submitted || form.controls.attachmentsUrl.dirty || form.controls.attachmentsUrl.touched) && form.controls.attachmentsUrl.invalid }"
                                    [readOnly]="readOnly"
                                    autocomplete="off"
                                    class="af-input attachmentsUrl"
                                    formControlName="attachmentsUrl"
                                    name="attachmentsUrl"
                                    placeholder="Attachments URL (Dropbox, Google Drive or similar)">
                                </textarea>
                                <div
                                    *ngIf="(submitted || form.controls.attachmentsUrl.dirty) && form.controls.attachmentsUrl.invalid">
                                    <label *ngIf="form.controls.attachmentsUrl.errors.maxlength"
                                           class="error">
                                        Maximum length
                                        is {{ form.controls.attachmentsUrl.errors.maxlength.requiredLength }}
                                        symbols
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="flex flex-column gap-20">
                                <div>
                                    <div class="gray-text">
                                        Shared attachments (Floorplans, sketches, planning and developments drawings
                                        or
                                        similar)
                                    </div>
                                    <small>
                                        Supports: Jpeg, png, bmp, pdf, svg, csv. 5mb limit per file upload.
                                    </small>
                                </div>
                                <bp-project-attachments [disabled]="readOnly || inProcess()"
                                                        [project]="project"
                                                        id="project-attachment">
                                </bp-project-attachments>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isAdmin">
                        <div class="form-group">
                            <div class="form-line">
                                <label class="form-control-label"
                                       for="field_tags_for_existed_project">
                                    Tags
                                </label>
                                <ng-select [(ngModel)]="project.tags"
                                           [hideSelected]="true"
                                           [items]="tags"
                                           [ngModelOptions]="{standalone: true}"
                                           [virtualScroll]="true"
                                           bindLabel="name"
                                           id="field_tags_for_existed_project"
                                           multiple="true"
                                           placeholder="-- start typing to find tag --">
                                </ng-select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-line">
                                        <textarea
                                            [ngClass]="{ 'error': (submitted || form.controls.notes.dirty || form.controls.notes.touched) && form.controls.notes.invalid }"
                                            [ngStyle]="{'height': isAdmin ? '100px' : '160px'}"
                                            autocomplete="off"
                                            class="af-input notes"
                                            formControlName="notes"
                                            name="notes"
                                            placeholder="Enter tender notes">
                                        </textarea>
                                <div
                                    *ngIf="(submitted || form.controls.notes.dirty) && form.controls.notes.invalid">
                                    <label *ngIf="form.controls.notes.errors.maxlength"
                                           class="error">
                                        Maximum length is {{ form.controls.notes.errors.maxlength.requiredLength }}
                                        symbols
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="m-t-40">
                        <small>
                            *Required fields
                        </small>
                    </div>
                </div>

                <div class="col-sm-6 text-center">
                    <bp-image-uploader
                        (onUploaded)="onImageUploaded($event)"
                        *ngIf="isAdmin"
                        [disabled]="inProcess()"
                        [initialImageUrl]="project.icon">
                    </bp-image-uploader>

                    <bp-project-image
                        (onUploaded)="onImageUploaded($event)"
                        *ngIf="!isAdmin"
                        [disabled]="readOnly || inProcess()"
                        [postcode]="form.controls.postcode.value"
                        [project]="project">
                    </bp-project-image>
                </div>
            </div>

            <div class="flex-between-container m-t-20">
                <div class="text-left">
                    <button (click)="confirmDeleteProject()"
                            *ngIf="!readOnlyStrict"
                            class="btn bp-delete-button"
                            type="button">
                        <span><i class="material-icons md-18">delete_forever</i>DELETE PROJECT</span>
                    </button>
                </div>

                <div class="text-right">
                    <div *ngIf="!readOnlyStrict"
                         class="form-group">
                        <div class="form-line">
                            <div class="form-check pl-2">
                                <label class="form-check-label">
                                    Archive project
                                    <input class="form-check-input archive-project"
                                           formControlName="archive"
                                           type="checkbox">
                                    <span class="form-check-sign"><span class="check"></span></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <button (click)="confirmRefreshSchedule()"
                            *ngIf="!readOnlyStrict"
                            [disabled]="inProcess()"
                            class="btn btn-secondary btn-border-radius btn-small refresh-schedule mr-2"
                            type="button">
                        Refresh schedule
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
