<div class="card">
        <div class="header">
            <div class="row">
                <div class="col-lg-5 left">
                    <h1 class="task-label">
                        Tasks
                    </h1>
                    <bp-archive-filter [defaultValue]="archiveFilterValue"
                                       (onValueChanged)="onArchiveFilterValueChanged($event)">
                    </bp-archive-filter>
                </div>
                <div class="col-lg-7 right">
                    <input [formControl]="searchControl"
                           class="af-input"
                           id="task-prefix"
                           name="task-prefix"
                           placeholder="Search task name"
                           type="text"/>
                    <button [routerLink]="['/task/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-task pull-right">
                        Create new Task
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="tasks"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="element.stage.stageOrder"
                        style="width: 15%">
                        <span>STAGE</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="element.elementOrder"
                        style="width: 15%">
                        <span>ELEMENT</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="task.taskOrder"
                        style="width: 15%">
                        <span>TASK</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                        <span>UNIT</span>
                    </th>
                    <th class="pb-0 pt-0"
                        style="width: 25%">
                        <table class="table table-borderless mb-0">
                            <thead>
                            <tr class="table-light">
                                <th class="pl-0 border-0">
                                    LABOUR
                                </th>
                                <th class="border-0"
                                    style="width: 100px">
                                    HOURS
                                </th>
                                <th class="border-0"
                                    style="width: 100px">
                                    COST
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </th>
                    <th class="pb-0 pt-0"
                        style="width: 25%">
                        <table class="table table-borderless mb-0">
                            <thead>
                            <tr class="table-light">
                                <th class="pl-0 border-0 text-right">
                                    PRIME MATERIAL
                                </th>
                                <th class="border-0 text-right">
                                    PRIME COST
                                </th>
                                <th class="border-0 text-right">
                                    TOTAL
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </th>
                    <th style="width: 50px">
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let task of tasks; trackBy: trackId"
                    [ngClass]="{'editable': task._editable}"
                    class="table-light">
                    <td style="width: 12%">
                        {{task.stage}}
                    </td>
                    <td style="width: 12%">
                        {{task.element}}
                    </td>
                    <td style="width: 12%">
                        <div *ngIf="task._editable">
                            <bp-inline-edit
                                type="text"
                                [(ngModel)]="task.task"
                                [label]="'Enter task name'"
                                [required]="true"
                                (onBlur)="onBlurInlineEdit($event, task)">
                            </bp-inline-edit>
                        </div>
                        <div *ngIf="!task._editable">
                            {{ task.task }}
                        </div>
                    </td>
                    <td>
                        {{task.id}}
                    </td>
                    <td>
                        <div *ngIf="task.unit">
                            {{task.unit}}
                        </div>
                    </td>
                    <td style="width: 18%">
                        <table class="table mb-0">
                            <tbody>
                            <tr *ngFor="let labour of task.labours"
                                class="table-light">
                                <td class="pl-0 border-0">
                                    <div *ngIf="task._editable">
                                        <bp-inline-select-edit
                                            [ngModel]="labour.tradeId"
                                            [placeholder]="'Select trade'"
                                            [required]="true"
                                            [idField]="'tradeId'"
                                            [idOption]="'id'"
                                            [labelField]="'trade'"
                                            [values]="trades"
                                            (onBlur)="onBlurLabourEdit($event, task)">
                                        </bp-inline-select-edit>
                                    </div>
                                    <div *ngIf="!task._editable">
                                        {{labour.trade}}
                                    </div>
                                </td>
                                <td class="border-0"
                                    style="width: 100px">
                                    <div *ngIf="task._editable">
                                        <bp-inline-edit
                                            type="number"
                                            [(ngModel)]="labour.quantity"
                                            [label]="'Enter labour QTY'"
                                            [required]="true"
                                            (onBlur)="onBlurInlineEdit($event, task)">
                                        </bp-inline-edit>
                                    </div>
                                    <div *ngIf="!task._editable">
                                        {{ labour.quantity }}
                                    </div>
                                </td>
                                <td class="border-0"
                                    style="width: 100px">
                                    {{ labour.quantity * labour.rate || 0 | currency:'GBP' }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table *ngIf="task.primeMaterials.length > 0">
                            <tr *ngFor="let primeMaterial of task.primeMaterials">
                                <td class="text-right border-0"
                                    style="width: 50%">
                                    <bp-inline-edit
                                        type="text"
                                        [(ngModel)]="primeMaterial.primeMaterialName"
                                        [label]="'Enter prime material name'"
                                        [required]="true"
                                        (onBlur)="onBlurInlineEdit($event, task)">
                                    </bp-inline-edit>
                                </td>
                                <td class="text-right border-0"
                                    style="width: 25%">
                                    <bp-inline-edit
                                        type="number"
                                        [(ngModel)]="primeMaterial.primeMaterialCost"
                                        [label]="'Enter prime material cost'"
                                        [required]="true"
                                        [currency]="'GBP'"
                                        (onBlur)="onBlurInlineEdit($event, task)">
                                    </bp-inline-edit>
                                </td>
                                <td class="text-right border-0"
                                    style="width: 25%">
                                    {{ (+primeMaterial.primeMaterialCost + +total(task)) || 0 | currency:'GBP' }}
                                </td>
                            </tr>
                        </table>
                        <table *ngIf="task.primeMaterials.length === 0">
                            <tr>
                                <td colspan="3"
                                    class="text-right border-0">
                                    {{ total(task) || 0 | currency:'GBP' }}
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="text-right"
                        style="width: 50px">
                        <div class="bp-btn-group"
                             role="group">
                            <button [routerLink]="['/task', task.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                (click)="onDeleteTask(task)"
                                class="btn btn-danger waves-effect"
                                type="button">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>


