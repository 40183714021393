<div class="template-item card-container text-center"
     [ngClass]="{'selected': template.count > 0 }">
    <div [style.background-image]="'url(' + (template.icon || iconPath) + ')'"
         class="area-icon">
    </div>

    <div class="template-name ellipsis-overflow-container-3"
         title="{{template.schedule}}">
        {{ template.schedule }}
    </div>

    <div class="template-counting noselect">
        <i (click)="onRemoveTemplateCountClick($event)"
           class="material-icons count-button">
            remove
        </i>
        <span class="m-l-10 m-r-10">{{ template.count }}</span>
        <i (click)="onAddTemplateCountClick($event)"
           class="material-icons count-button">
            add
        </i>
    </div>
</div>
