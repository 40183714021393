import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProject, IProjectCache } from 'app/shared/model/project.model';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISpecification } from 'app/shared/model/specification.model';
import { ITag } from 'app/shared/model/tag.model';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { SpecificationService } from 'app/shared/dataservices/specification.service';
import { TagService } from 'app/shared/dataservices/tag.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { CoordinatesSearcherService } from 'app/shared/services/coordinates-searcher.service';
import { IGeoPoint } from 'app/shared/model/geo-point.model';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { DatePickerOnDateSelectEvent } from 'app/shared/components/common/date-picker/date-picker.component';
import { noWhiteSpaceValidator } from 'app/shared/validators/no-white-space.validator';
import { projectAlreadyExistsValidator } from 'app/shared/validators/project-already-exists.validator';
import { IRegion } from 'app/shared/model/region.model';
import { IsSchedulerProjectReadOnlyService } from 'app/shared/services/is-scheduler-project-read-only.service';
import { AccountService } from 'app/core/auth/account.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { lastValueFrom, Subscription } from 'rxjs';
import { ProjectActionsService } from 'app/shared/services/project-actions.service';
import { ProjectCacheService } from 'app/shared/dataservices/project-cache.service';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { AuthServerProvider } from 'app/core/auth/auth-jwt.service';
import { EstimatingAddonService } from 'app/shared/dataservices/estimating-addon.service';
import { LoaderService } from 'app/shared/services/loader-service.service';
import { CopyToClipboardService } from 'app/shared/services/copy-to-clipboard';
import { SuccessModalService } from 'app/shared/components/common/success-modal/success-modal.service';
import Swal from "sweetalert2";

/**
 * Update Project Details Component
 */
@Component({
    selector: 'bp-project-details',
    styleUrls: ['project-details.scss'],
    templateUrl: './project-details.component.html'
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    @Output() onProjectUpdatedEmitter = new EventEmitter();
    @Output() onProjectRemovedEmitter = new EventEmitter();

    @Output() onProjectsUpdatedEmitter = new EventEmitter();
    project: IProject;

    protected formSub = Subscription.EMPTY;
    protected routeDataSub = Subscription.EMPTY;
    protected isAdmin: boolean;

    protected regionsInputData: SelectInputData;

    protected inProcessLoadSpecifications = false;
    protected inProcessLoadTags = false;
    protected inProcessSaveProject = false;
    protected inProcessCopyProject = false;
    protected inProcessLoadRegions = false;

    protected form: FormGroup;

    protected specifications: ISpecification[];
    protected tags: ITag[];

    protected submitted = false;
    protected readOnly = true;
    protected readOnlyStrict = true;
    protected isVisibleAdditionalBlock = true;

    protected useCache = false;
    protected autoSaveDate?: any = null;

    protected proxyAdmin = false;
    protected projectHavePendingAddons = false;

    constructor(
        private fb: FormBuilder,
        private authServerProvider: AuthServerProvider,
        private estimatingAddonService: EstimatingAddonService,
        private projectApi: ProjectApi,
        private projectCacheService: ProjectCacheService,
        private accountService: AccountService,
        private specificationService: SpecificationService,
        private tagService: TagService,
        private alertService: BpAlertService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private regionService: RegionApi,
        private coordinatesSearcherService: CoordinatesSearcherService,
        private isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
        private projectActionsService: ProjectActionsService,
        private invitationService: InvitationService,
        private loaderService: LoaderService,
        private _copyToClipboardService: CopyToClipboardService,
        private _successModalService: SuccessModalService,
    ) {
        this.routeDataSub = this.activatedRoute.data.subscribe(data => {
            this.isAdmin = this.accountService.isAdmin();

            this.loadProject(data.project.id).then(() => {
                this.readOnly = this.isSchedulerProjectReadOnlyService.isReadOnly(this.project);
                this.readOnlyStrict = !this.project.isOwner;
                this.updateProjectHavePendingAddons();

                this.initForm();
                this.loadSpecifications();
                this.loadTags();
                this.loadRegions();
            })
        });
    }

    ngOnInit(): void {
        this.proxyAdmin = this.authServerProvider.getProxyAdmin();
    }

    ngOnDestroy(): void {
        this.formSub.unsubscribe();
        this.routeDataSub.unsubscribe();
    }

    public save(calledOnExitFromScreen = false, saveToCache: boolean = true): Promise<boolean> {
        return new Promise(resolve => {
            if (saveToCache) {
                this.inProcessSaveProject = true;
            } else {
                this.blockUI.start('Saving...');
            }

            this.project.address = this.form.controls.address.value;
            this.project.addressSecondLine = this.form.controls.addressSecondLine.value;
            this.project.city = this.form.controls.city.value;
            this.project.postcode = this.form.controls.postcode.value;
            this.project.clientName = this.form.controls.clientName.value;
            this.project.phone = this.form.controls.phone.value;
            this.project.email = this.form.controls.email.value;
            this.project.attachmentsUrl = this.form.controls.attachmentsUrl.value;
            this.project.tenderNotes = this.form.controls.tenderNotes.value;
            this.project.notes = this.form.controls.notes.value;
            this.project.templateName = this.form.controls.templateName.value;
            this.project.archive = this.form.controls.archive.value;
            this.project.timestamp = +new Date();

            const retrieveCoordinatesPromise = this.coordinatesSearcherService.search(this.project.postcode).then((geoPoint: IGeoPoint) => {
                this.project.latitude = geoPoint.lat;
                this.project.longitude = geoPoint.long;
            });

            retrieveCoordinatesPromise.finally(() => {
                if (saveToCache) {
                    lastValueFrom(this.projectCacheService.update(this.getJSONForSaveRequest()).pipe(
                        finalize(() => {
                            this.inProcessSaveProject = false;
                            resolve(true);
                        })
                    ));
                } else {
                    const success = () => {
                        this.blockUI.stop();
                        this.alertService.success('Your project has been updated');
                        this.router.navigate(['../../dashboard', this.project.id], { relativeTo: this.activatedRoute });
                        resolve(true);
                    }

                    this.projectApi.find(this.project.id).subscribe((currentProjectInfo) => {
                        const regionWasChanged = currentProjectInfo.body.region?.id !== this.project.region?.id;

                        lastValueFrom(this.projectCacheService.remove(this.project.id)).then(() => {
                            lastValueFrom(this.projectApi
                                .update(this.getJSONForSaveRequest()))
                                .then(() => {
                                        if (regionWasChanged) {
                                            // remove and invite quoters as region was changes
                                            lastValueFrom(this.invitationService
                                                .addDefaultQuoters(this.project.id))
                                                .then((defaultQuoterIds: number[]) => {
                                                    if (defaultQuoterIds.includes(this.project.defaultQuoter.id)) {
                                                        lastValueFrom(this.projectApi
                                                            .updateDefaultQuoter(this.project.id, this.project.defaultQuoter.id)).then(() => {
                                                            success();
                                                        })
                                                    } else {
                                                        success();
                                                    }
                                                })
                                        } else {
                                            success();
                                        }
                                    },
                                );
                        })
                    })
                }
            });
        })
    }

    protected copyLinkForTheClientToBuffer(): void {
        this.projectApi.getTempLink(this.project.id).subscribe((tempLink) => {
            this._copyToClipboardService.copyToClipboard(tempLink);

            this._successModalService.open({
                header: `Link successfully copied to clipboard`,
                textHtml: `<div class='strong m-b-10'>
                            <a href='${tempLink}' target='_blank' class="link underlined">${tempLink}</a>
                          </div>`,

            });
        })
    }

    protected copyProject(): void {
        this.projectActionsService.copyProject(this.project).then((newProject: IProject | null) => {
            if (newProject) {
                this.blockUI.update('Your project has been copied. Redirecting to copied project');
                this.router.navigate(['../../project-details', newProject.id], { relativeTo: this.activatedRoute }).finally(() => {
                    setTimeout(() => {
                        this.blockUI.stop();
                    }, 500)
                })
            }
        })
    }

    protected onStartDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.project.startDate = event.newDate;
        this.save();
    }

    protected onTenderDeadlineDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.project.tenderDeadline = event.newDate;
        this.save();
    }

    protected onTenderDecisionDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.project.tenderDecision = event.newDate;
        this.save();
    }

    protected onImageUploaded(imgUrl: string): void {
        this.project.icon = imgUrl;
        this.save();
    }

    protected onRegionSelectionChange(event): void {
        if (event != null) {
            this.project.region = event;
            this.save();
        }
    }

    protected inProcess(): boolean {
        return (
            this.inProcessLoadSpecifications ||
            this.inProcessLoadTags ||
            this.inProcessSaveProject ||
            this.inProcessCopyProject
        );
    }

    protected makeProjectLive(): void {
        if (!this.project.estimatingAddOn?.length) {
            return;
        }

        const promises = this.project.estimatingAddOn
            .filter(addon => addon.status === 'PENDING')
            .map(addon => lastValueFrom(this.estimatingAddonService.statusDone(this.project.id, addon.id)));

        if (promises.length) {
            this.blockUI.start('Making project live..')
            Promise.all(promises).finally(() => {
                this.blockUI.stop();
            }).then(() => {
                this.project.estimatingAddOn.forEach((addon) => addon.status = 'DONE');
                this.updateProjectHavePendingAddons();
                this.alertService.success('Your project is live now.');
            });
        }
    }

    protected confirmDeleteProject(): void {
        debugger
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this project!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.projectActionsService.deleteProject(this.project).then((res: boolean) => {
                    if (res) {
                        this.alertService.success('Your project has been deleted');
                        this.router.navigate(['../../projects'], { relativeTo: this.activatedRoute })
                    }
                })
            }
        });
    }

    protected confirmRefreshSchedule(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This will update prices but you may lose project-specific information such as comments and notes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, refresh!',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.loaderService.start({ backgroundImage: 'create-project.gif' });

                this.projectApi
                    .refreshProjectTask(this.project.id)
                    .pipe(
                        finalize(() => {
                            this.loaderService.stop();
                        })
                    )
                    .subscribe(
                        () => {
                            this.alertService.success('Your project has been updated');
                        }
                    );
            }
        });
    }

    private initForm(): void {
        this.form = this.fb.group({
            address: new FormControl(
                this.project.address,
                [Validators.required, noWhiteSpaceValidator],
                [projectAlreadyExistsValidator(this.projectApi, this.project.address)]
            ),
            addressSecondLine: new FormControl(this.project.addressSecondLine),
            city: new FormControl(this.project.city),
            postcode: new FormControl(this.project.postcode, [Validators.required]),
            clientName: new FormControl(this.project.clientName),
            phone: new FormControl(this.project.phone),
            email: new FormControl(this.project.email, Validators.email),
            // https://www.regextester.com/94502
            attachmentsUrl: new FormControl(this.project.attachmentsUrl, Validators.maxLength(255)),
            tenderNotes: new FormControl(this.project.tenderNotes, Validators.maxLength(1200)),
            notes: new FormControl(this.project.notes, Validators.maxLength(255)),
            templateName: new FormControl(this.project.templateName),
            archive: new FormControl(this.project.archive)
        });

        this.formSub = this.form.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.save(false, true)
        });
    }

    private loadSpecifications(): void {
        this.inProcessLoadSpecifications = true;

        this.specificationService
            .query()
            .pipe(
                finalize(() => {
                    this.inProcessLoadSpecifications = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ISpecification[]>) => {
                    this.specifications = res.body;
                }
            );
    }

    private loadRegions(): void {
        this.inProcessLoadRegions = true;

        this.regionService
            .query()
            .pipe(
                finalize(() => {
                    this.inProcessLoadRegions = false;
                })
            )
            .subscribe(
                (res: HttpResponse<IRegion[]>) => {
                    const regions = res.body;

                    this.regionsInputData = {
                        indexProperty: 'id',
                        titleProperty: 'region',
                        data: regions,
                        dropdownPosition: 'bottom',
                        clearable: false,
                        initIndex: this.project.region.id,
                        initItem: this.project.region.region
                    };
                }
            );
    }

    private loadTags(): void {
        this.inProcessLoadTags = true;

        this.tagService
            .query()
            .pipe(
                finalize(() => {
                    this.inProcessLoadTags = false;
                })
            )
            .subscribe(
                (res: HttpResponse<ITag[]>) => {
                    this.tags = res.body;
                }
            );
    }

    private getJSONForSaveRequest(): IProject {
        const project = Object.assign({}, this.project);
        project.defaultQuoter = project.defaultQuoter == null ? null : { id: project.defaultQuoter.id };
        return project;
    }

    private loadProject(projectId: number): Promise<void> {
        return lastValueFrom(this.projectCacheService.get(projectId)).then((res: HttpResponse<IProjectCache>) => {
            this.useCache = true;

            this.project = res.body;
            this.autoSaveDate = new Date(res.body.timestamp);
        }, () => {
            this.useCache = false;
            return lastValueFrom(this.projectApi.find(projectId)).then((res: HttpResponse<IProject>) => {
                this.project = res.body;
            });
        })
    }

    private updateProjectHavePendingAddons(): void {
        this.projectHavePendingAddons = this.project.estimatingAddOn?.find(addon => addon.status === 'PENDING') != null;
    }
}
