<div (click)="onSelect()"
     [ngClass]="{ 'admin-card': isAdmin, 'non-admin-card': !isAdmin, 'scheduler-card': firstPath === '/scheduler', 'quoter-card':  firstPath === '/quoter'}"
     class="project-card-container clickable">
    <div class="flex flex-row justify-content-between align-items-start m-b-10 relative">
        <div style="width: calc(100% - 20px) !important;">
            <div [title]="project.address"
                 class="project-card-container__project-name ellipsis-overflow-container-2">
                <span>{{ project.address }}</span>
            </div>
            <div *ngIf="!isAdmin"
                 [title]="project.addressSecondLine"
                 class="project-card-container__project-second-line-address ellipsis-overflow-container">
                <span>{{ project.addressSecondLine }}</span>
            </div>
        </div>

        <div (click)="$event.stopPropagation()" class="project-card-container__context-menu"
             ngbDropdown>
            <button class="project-card-container__context-menu__dot-button"
                    ngbDropdownToggle
                    type="button">
                <span>...</span>
            </button>
            <div class="project-card-container__context-menu__menu" ngbDropdownMenu>
                <button (click)="copyProject($event)"
                        ngbDropdownItem>
                    Copy
                </button>

                <button (click)="toggleArchiveProject($event)"
                        ngbDropdownItem>
                    {{ project.archive ? 'Unarchive' : 'Archive' }}
                </button>

                <button (click)="deleteProjectWithConfirm()"
                        *ngIf="project.isOwner"
                        ngbDropdownItem>
                    Delete
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!isAdmin">
        <div *ngIf="!project.icon"
             class="project-card-container__map-container">
            <div *ngIf="mapOptions"
                 [leafletOptions]="mapOptions"
                 leaflet
                 style="height: 120px; background: #f7f7f7;">
            </div>
        </div>

        <div *ngIf="project.icon"
             class="image-container">
            <bp-image-previewer [height]="'120px'"
                                [icon]="project.icon">
            </bp-image-previewer>
        </div>

        <div>
            <div *ngIf="project.minCost != null && project.maxCost != null; else builderCost"
                 class="project-card-container__estimations m-t-10 ellipsis-overflow-container">
                Est. {{ project.minCost || 0 | currency:'GBP' }} - {{ project.maxCost || 0 | currency:'GBP' }}
            </div>
            <ng-template #builderCost>
                <div class="project-card-container__estimations m-t-10 ellipsis-overflow-container">
                    {{ project.builderCost || 0 | currency:'GBP' }}
                </div>
            </ng-template>
            <div class="project-card-container__description m-t-10 ellipsis-overflow-container">
                {{ project.description }}
            </div>
        </div>

        <div class="project-card-container__status">
            <bp-project-status
                [status]="project.status">
            </bp-project-status>
        </div>
    </ng-container>

    <ng-container *ngIf="isAdmin">
        <div class="image-container">
            <bp-image-previewer *ngIf="project.icon"
                                [icon]="project.icon"
                                [width]="'140px'">
            </bp-image-previewer>
        </div>
    </ng-container>

</div>
