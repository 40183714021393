import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IStage } from 'app/shared/model/stage.model';
import { IElement } from 'app/shared/model/element.model';
import { SelectInputData } from 'app/shared/components/common/select-input/select-input.component';
import { SearchBySelectInputData } from 'app/shared/components/common/search-by-select/search-by-select.component';
import { Subject, Subscription } from 'rxjs';
import { IMaterial } from 'app/shared/model/material.model';
import {
    IScheduleTaskModificationEvent,
    IScheduleTaskQuoterTotalModificationEvent
} from 'app/shared/constants/events.constants';
import { ITask } from 'app/shared/model/task.model';
import { AddMaterialModalService } from 'app/shared/components/schedule/add-material-modal/add-material-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetNaComponentAreaService } from 'app/flows/scheduler/services/get-na-component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import { DefaultPricesUpdaterService } from 'app/flows/scheduler/schedule/services/default-prices-updater.service';
import {
    DefaultUnitValueUpdaterService
} from 'app/flows/scheduler/schedule/services/default-unit-value-updater.service';
import { NewTaskRequestApi } from 'app/shared/dataservices/new-task-request.api';
import * as _ from 'lodash';
import { LoadMaterialsService } from 'app/flows/scheduler/services/load-materials.service';
import {
    CheckIfScheduleRawIsGrayedService
} from 'app/flows/scheduler/schedule/services/check-if-schedule-raw-is-grayed.service';
import { ScheduleService } from 'app/flows/scheduler/schedule/schedule.service';
import {
    ProjectCommentsModalService
} from 'app/shared/components/common/comments-modal/project-comments-modal.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { UpdatedScheduleTaskProperty } from 'app/shared/model/bp.model';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import { ScheduleTaskApi } from 'app/shared/dataservices/schedule-task.api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IUpdateTaskRequestDTO } from 'app/shared/model/new-task-request.model';
import { HttpResponse } from '@angular/common/http';
import {
    TaskRequestModalService
} from 'app/flows/scheduler/schedule/components/task-request-modal/task-request-modal.service';
import { TaskApi } from 'app/shared/dataservices/task.api';

@UntilDestroy()
@Component({
    selector: 'bp-schedule-task-for-filter-by-css-element-grouping',
    templateUrl: './schedule-task.component.html',
    styleUrls: [
        '../../root-container/schedule-task.scss',
        '../../../schedule-common.scss'
    ]
})
export class ScheduleTaskForFilterByCssElementGroupingComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() stage: IStage;
    @Input() element: IElement;
    @Input() scheduleTask: IScheduleTask;

    protected isGrayed = false;

    protected taskSearchBySelectInputData: SearchBySelectInputData;
    protected scheduleAreaSelectInputData: SelectInputData;
    protected materialSelectInputData: SelectInputData;

    protected modifyScheduleTaskSub: Subscription;
    protected scheduleTaskUpdatedOnBESubject: Subscription;

    protected unitValueWasChanged = false;

    constructor(
        public scheduleService: ScheduleService,
        private scheduleEventsService: ScheduleEventsService,
        private scheduleTaskApi: ScheduleTaskApi,
        private defaultPricesUpdaterService: DefaultPricesUpdaterService,
        private defaultUnitValueUpdaterService: DefaultUnitValueUpdaterService,
        private taskApi: TaskApi,
        private addMaterialModalService: AddMaterialModalService,
        private getNaComponentAreaService: GetNaComponentAreaService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private newTaskRequestApi: NewTaskRequestApi,
        private commentsModalService: ProjectCommentsModalService,
        private loadMaterialsService: LoadMaterialsService,
        private taskRequestModalService: TaskRequestModalService,
        private checkIfScheduleRawIsGrayedService: CheckIfScheduleRawIsGrayedService
    ) {
    }

    protected get primeMaterialName(): number {
        const primeMaterial = _.find(this.scheduleTask.actualMaterialComponents, { isPrime: true });
        return primeMaterial ? primeMaterial.material : '';
    }

    protected get materialCategories(): IMaterialCategory[] {
        return this.scheduleTask?.materialCategories;
    }

    ngOnInit(): void {
        this.fillMaterialSelectInputData();
        this.fillTaskSelectInputData();
        this.fillScheduleAreaSelectInputData();
    }

    ngAfterViewInit(): void {
        this.isGrayed = this.checkIfScheduleRawIsGrayedService.isGrayedCssElement(this.scheduleTask.cssElementId, this.scheduleTask);

        this.modifyScheduleTaskSub = this.scheduleEventsService.requestToModifyScheduleTask$.subscribe((event: IScheduleTaskModificationEvent) => {
                if (!event.scheduleTask.markedAsDeleted) {
                    return;
                }

                setTimeout(() => {
                    this.isGrayed = this.checkIfScheduleRawIsGrayedService.isGrayedCssElement(
                        this.scheduleTask.cssElementId,
                        this.scheduleTask
                    );
                });
            }
        );

        this.scheduleTaskUpdatedOnBESubject = this.scheduleEventsService.scheduleTaskUpdatedOnBE$
            .subscribe((event: IScheduleTask) => {
                if (event.id !== this.scheduleTask?.id) {
                    return;
                }

                this.defaultUnitValueUpdaterService.setDefaultUnitValue(this.scheduleTask).then(() => {
                    this.loadMaterialsService.load(this.scheduleService.project, this.scheduleTask).then(() => {
                        this.fillMaterialSelectInputData();
                    });
                });

                this.onScheduleTaskModification();
            })
    }

    ngOnDestroy(): void {
        this.modifyScheduleTaskSub?.unsubscribe();
        this.scheduleTaskUpdatedOnBESubject?.unsubscribe();
    }

    protected onEditScheduleClick(event: MouseEvent): void {
        event.preventDefault();

        this.router.navigate(['/scheduler', 'schedule-task-details', this.scheduleService.project.id, this.scheduleTask.id], {
            relativeTo: this.activatedRoute
        });
    }

    protected onEditNewTaskRequestScheduleClick(event: MouseEvent): void {
        event.preventDefault();

        this.newTaskRequestApi.get(this.scheduleTask.newTaskRequestId).subscribe((res1) => {
            this.taskRequestModalService.open(res1.body).result.then((updatedData: IUpdateTaskRequestDTO) => {
                this.newTaskRequestApi.update(this.scheduleTask.newTaskRequestId, updatedData).subscribe((res2: HttpResponse<IScheduleTask>) => {
                    this.scheduleTask.specification = res2.body.specification;
                    this.scheduleTask.unitValue = res2.body.unitValue;
                    this.scheduleTask.taskTotal = res2.body.taskTotal;

                    this.scheduleEventsService.scheduleTaskTotalModified({ stageId: this.stage.id });
                });
            })
        })
    }
    protected onEditRatesClick(event: MouseEvent): void {
        event.preventDefault();

        this.scheduleTaskApi.quoterView(this.scheduleTask.id).toPromise().then(actualScheduleTaskIdRes => {
            this.router.navigate(
                ['/quoter', 'quote-task-details', this.scheduleService.project.id, actualScheduleTaskIdRes.body],
                {
                    queryParams: { returnPage: 'schedule' },
                    relativeTo: this.activatedRoute
                });
        })
    }

    protected onCommentsClick(event: MouseEvent): void {
        event.preventDefault();
        this.commentsModalService.open(this.scheduleService.project, this.scheduleTask?.id);
    }

    protected onDeleteTaskClick(): void {
        this.scheduleTask.markedAsDeleted = true;
        this.broadcastTaskModificationEvent('Unknown');

        if (this.scheduleTask.taskId != null) {
            this.scheduleEventsService.scheduleTaskTotalModified({
                stageId: this.scheduleTask.stageId
            })
        }
    }

    protected onDeleteTaskRequestClick(): void {
        this.newTaskRequestApi.delete(this.scheduleTask.newTaskRequestId).subscribe(response => {
            this.scheduleTask.markedAsDeleted = true;
            this.broadcastTaskModificationEvent('Unknown');
        });
    }

    protected onTaskSelectionChange(event: ITask): void {
        if (!event || event.id === this.scheduleTask.taskId) {
            return;
        }

        this.scheduleTask.oldTaskId = this.scheduleTask.taskId;
        this.scheduleTask.oldTaskName = this.scheduleTask.task;

        this.scheduleTask.taskId = event.id;
        this.scheduleTask.task = event.task;
        this.scheduleTask.displayName = event.task;
        this.scheduleTask.componentAreaId = event.componentAreaId;
        this.scheduleTask.unitId = event.unitId;
        this.scheduleTask.unit = event.unit ? event.unit.toString() : null;
        this.scheduleTask.unitPlural = event.unitPlural;
        this.scheduleTask.materialCategories = event.materialCategories;
        this.scheduleTask.primeMaterialId = null;

        this.defaultUnitValueUpdaterService.setDefaultUnitValue(this.scheduleTask).then(() => {
            this.loadMaterialsService.load(this.scheduleService.project, this.scheduleTask).then(() => {
                this.fillMaterialSelectInputData();
                this.handleTaskModification('task');
            });
        });
    }

    protected onMaterialSelectionChange(event: IMaterial): void {
        if (!event || event.id === this.scheduleTask.primeMaterialId) {
            return;
        }

        this.scheduleTask.primeMaterialId = event.id;
        this.scheduleTask.materialUrlRef = event.referenceUrl;

        this.handleTaskModification('material');
    }

    protected async onOpenMaterialSelect(): Promise<IMaterial[]> {
        await this.loadMaterialsService.load(this.scheduleService.project, this.scheduleTask);
        return this.scheduleTask._materials.filter((material) => {
            return (this.scheduleService.showSubStagesMode !== "finishes" || material.type === "FINISH");
        });
    }

    protected onScheduleAreaSelectionChange(event: IScheduleArea): void {
        this.scheduleTask.scheduleAreaId = event.id;

        if (this.scheduleTask.id != null && this.scheduleTask.componentArea !== 'N/A') {
            this.defaultUnitValueUpdaterService.setDefaultUnitValue(this.scheduleTask, true).then(() => {
                this.handleTaskModification('scheduleArea');
            });
        } else {
            this.handleTaskModification('scheduleArea');
        }
    }

    protected onUnitValueBlur(): void {
        if (!this.scheduleTask.unitValue) {
            this.scheduleTask.unitValue = 0;
        }

        if (this.unitValueWasChanged) {
            this.unitValueWasChanged = false;
            if (this.scheduleTask.id != null) {
                this.getNaComponentAreaService.get().subscribe((naComponentArea: IComponentArea) => {
                    this.scheduleTask.componentArea = naComponentArea.componentArea;
                    this.scheduleTask.componentAreaId = naComponentArea.id;
                    this.handleTaskModification('unitValue');
                });
            } else {
                this.handleTaskModification('unitValue');
            }
        }
    }

    protected onPrimeMaterialCostAvailableChanged(): void {
        if (this.scheduleTask.primeMaterialCostAvailable) {
            this.scheduleTask.primeMaterialCost = 0;
        }
        this.handleTaskModification('clientSupplied');
    }

    private fillTaskSelectInputData(): void {
        this.taskSearchBySelectInputData = {
            searchBy: 'task',
            indexProperty: 'id',
            titleProperty: 'task',
            displayProperty: 'task',
            displayValue: this.scheduleTask.task,
            itemService: this.taskApi,
            itemServiceMethod: 'queryForGroupAdding',
            dropdownPosition: 'bottom',
            additionalQueryPathParams: { stageId: this.stage.id, elementId: this.element.id },
            eventEmitter: new Subject<void>(),
            placeholder: 'Add task',
            doNotSort: true
        };

        if (this.scheduleTask.taskId) {
            this.taskSearchBySelectInputData.initValue = this.scheduleTask.task;
            this.taskSearchBySelectInputData.initIndex = this.scheduleTask.taskId;
        }
    }

    private fillScheduleAreaSelectInputData(): void {
        this.scheduleAreaSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'area',
            initIndex: this.scheduleTask.scheduleAreaId,
            data: this.scheduleService.nativeScheduleAreas,
            dropdownPosition: 'bottom'
        };
    }

    private fillMaterialSelectInputData(): void {
        if (this.scheduleTask.primeMaterialId == null) {
            return;
        }
        const data = this.scheduleTask._materials || [{ id: this.scheduleTask.primeMaterialId, material: this.scheduleTask.primeMaterialName }];
        this.materialSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'material',
            initIndex: this.scheduleTask.primeMaterialId,
            data: data,
            dropdownPosition: 'bottom',
            onEnterClickHandler: this.onMaterialSelectInputEnterClick.bind(this),
            onOpenHandler: this.onOpenMaterialSelect.bind(this)
        };
    }

    private onMaterialSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length > 0) {
            const modalRef = this.addMaterialModalService.open(
                this.scheduleService.project,
                this.scheduleTask,
                currentTextValue,
                this.scheduleTask.materialCategories?.length ? this.scheduleTask.materialCategories[0].id : null,
                this.scheduleTask.unit
            );
            modalRef.result.then(
                (createdMaterial: IMaterial) => {
                    this.scheduleTask._materials.push(createdMaterial);
                    this.scheduleTask.primeMaterialId = createdMaterial.id;
                    this.fillDefaultPrices();
                    this.broadcastTaskModificationEvent('material');
                    this.fillMaterialSelectInputData();
                    this.materialSelectInputData.initItem = createdMaterial.material;
                    this.materialSelectInputData.initIndex = createdMaterial.id;
                },
                reason => {
                    // This is intentional
                }
            );
        }
    }

    private onScheduleTaskModification(): void {
        if (this.scheduleTask.markedAsDeleted) {
            return;
        }

        this.fillScheduleAreaSelectInputData();

        this.taskSearchBySelectInputData.initValue = this.scheduleTask.task;
        this.taskSearchBySelectInputData.initIndex = this.scheduleTask.taskId;
        this.taskSearchBySelectInputData.displayValue = this.scheduleTask.task;

        this.taskSearchBySelectInputData.eventEmitter.next();
    }

    private handleTaskModification(updatedProperty: UpdatedScheduleTaskProperty): void {
        this.onScheduleTaskModification();
        this.fillDefaultPrices();
        this.broadcastTaskModificationEvent(updatedProperty);
    }

    private broadcastTaskModificationEvent(updatedProperty: UpdatedScheduleTaskProperty): void {
        this.scheduleEventsService.requestToModifyScheduleTask({
            stage: this.stage,
            element: this.element,
            scheduleTask: this.scheduleTask,
            updatedProperty
        })
    }

    private fillDefaultPrices(): void {
        this.defaultPricesUpdaterService
            .fillDefaultPrices(this.scheduleService.project, this.scheduleTask)
            .then((res: IScheduleTaskQuoterTotalModificationEvent) => {
                if (res != null) {
                    this.scheduleEventsService.scheduleTaskTotalModified(res)
                }
            });
    }
}
