<div class="modal-header">
    <div class="text-center"
         style="flex: 1">
        <h2>
            Request QS
        </h2>
    </div>
    <button (click)="clear()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="text-center">
        <div class="strong m-b-10">
            As part of our BuildPartner product we offer our qualified and insured in-house QS team to assist you on
            your projects.
        </div>
        <div class="m-b-30">
            <a href="javascript:void(0);"
               (click)="goToStartServiceFlow()"
               class="link underlined"
               rel="noopener">
                Learn about our service and pricing options here.
            </a>
        </div>
    </div>
</div>
