<div class="flex justify-content-end gap-20">
    <button (click)="onUpdateAllToLatestVersionClick()"
            *ngIf="!readOnly && isUpdateAllToLatestVersionButtonEnabled"
            ngbTooltip="Update All to Latest Version"
            class="btn btn-secondary btn-border-radius update-all-to-latest-version">
        Update All to Latest Version
    </button>
    <button (click)="onShowAverageRatesButtonClick()"
            *ngIf="!readOnly && isShowAverageRatesButtonEnabled"
            ngbTooltip="Show Average Rates"
            class="d-none d-md-inline btn btn-secondary btn-border-radius">
        Show Average Rates
    </button>
    <button (click)="addDefaultQuoter()"
            [disabled]="readOnly"
            *ngIf="showDefaultQuoterButton"
            class="btn btn-secondary btn-border-radius btn-small default-quoter"
            type="button">
        Create Cost Plan
    </button>
    <button (click)="onRequestProfessionalClick()"
            [disabled]="readOnly"
            class="btn btn-secondary btn-border-radius request-professional"
            type="button">
        Request Professional
    </button>
    <button (click)="onCompareQuotesClick()"
            *ngIf="showCompareQuotesButton"
            class="btn btn-secondary btn-border-radius compare-quotes"
            type="button">
        Compare Quotes
    </button>
</div>

<div class="row m-t-20">
    <div class="col-sm-12">
        <table class="af-table table-hover">
            <tr class="tr-head">
                <th style="width: 20%">
                    Company
                </th>
                <th style="width: 20%">
                    Email
                </th>
                <th style="width: 15%">
                    Phone
                </th>
                <th>
                    Status
                </th>
                <th>
                    Version
                </th>
                <th>
                    Price (<span class="pound-symbol">&pound;</span>)
                </th>
                <th style="width: 60px">
                    Default
                </th>
                <th style="width: 150px">
                </th>
            </tr>

            <tr *ngFor="let invitation of invitations"
                [ngClass]="{'my-account-tr': isMe(invitation)}">
                <td>
                    <span *ngIf="!invitation.website">
                        {{ invitation.company  }}
                    </span>

                    <a [href]="invitation.website | absoluteUrl"
                       *ngIf="invitation.website"
                       [target]="'_blank'"
                       title="Go to company website"
                       class="link">
                        {{ invitation.company || invitation.website }}
                    </a>
                </td>
                <td>
                    <span *ngIf="!invitation.regionBenchmarkQuoter">{{ invitation.email }}</span>
                </td>
                <td>
                    <span *ngIf="!invitation.isDefault || isMe(invitation)">{{ invitation.phone }}</span>
                </td>
                <td>
                    <div [ngSwitch]="invitation.status"
                         class="table-value">
                        <span *ngSwitchCase="'SENT'" class="label bg-sent">PENDING</span>
                        <span *ngSwitchCase="'QUOTED'" class="label bg-quoted">QUOTED</span>
                        <span *ngSwitchCase="'IN_PROGRESS'" class="label bq-in-progress">IN PROGRESS</span>
                        <span *ngSwitchCase="'VARIATIONS_MADE'"
                              class="label bg-variations-made">VARIATIONS MADE</span>
                        <span *ngSwitchCase="'ACCEPTED'" class="label bg-accepted">ACCEPTED</span>
                        <span *ngSwitchCase="'DECLINED'" class="label bg-declined">DECLINED</span>
                    </div>
                </td>
                <td>
                    <small class="label p-hor-5"
                           title="Quoter version"
                           *ngIf="invitation.quoterId != null"
                           [ngClass]="{'label-info': isInvitationVersionTheSameAsProjectVersion(invitation), 'label-warning': !isInvitationVersionTheSameAsProjectVersion(invitation)}">
                        v. {{(invitation.majorVersion + '.' + invitation.minorVersion) || '-' }}
                    </small>
                    <bp-spinner *ngIf="invitation.processing"
                                class="version-spinner">
                    </bp-spinner>
                </td>
                <td>
                    <span
                        *ngIf="invitation.total != null && (invitation.isDefault || invitation.status === 'QUOTED' || invitation.status === 'VARIATIONS_MADE' || invitation.status === 'ACCEPTED'); else notShowPrice">{{ invitation.total | number : '1.2-2' }}</span>

                    <ng-template #notShowPrice>
                        <span>----</span>
                    </ng-template>
                </td>
                <td>
                    <div class="form-check pl-2 default-quoter-value">
                        <label class="form-check-label">
                            <input (change)="onDefaultQuoterChanged(invitation)"
                                   (click)="isInvitationDefault(invitation) ? $event.preventDefault(): ''"
                                   [disabled]="isDefaultQuoterCheckBoxDisabled(invitation)"
                                   [checked]="isInvitationDefault(invitation)"
                                   class="form-check-input"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                        </label>
                    </div>
                </td>
                <td class="align-right">
                    <button (click)="onUpdateToLatestVersionClick(invitation)"
                            [disabled]="invitation.quoterId == null || invitation.majorVersion + '.' + invitation.minorVersion == project.version"
                            class="btn btn-circle btn-small btn-secondary update-to-latest-version m-r-10"
                            ngbTooltip="Update to Latest Version">
                        <i class="material-icons md-18">refresh</i>
                    </button>

                    <button (click)="onGoToIndividualQuoteClick(invitation)"
                            [disabled]="readOnly || !canViewIndividualQuote(invitation)"
                            class="btn btn-circle btn-small btn-secondary go-to-individual-quoter m-r-10"
                            ngbTooltip="Go to individual quoter">
                        <i class="material-icons md-18">center_focus_weak</i>
                    </button>

                    <button (click)="onAcceptQuoteButtonClick(invitation)"
                            *ngIf="canAcceptQuote(invitation)"
                            [disabled]="readOnly"
                            class="btn btn-circle btn-small btn-secondary accept-quote m-r-10"
                            ngbTooltip="Accept quote">
                        Accept
                    </button>

                    <button (click)="confirmRemoveInvitation(invitation)"
                            [disabled]="readOnly || inProcessRemoveInvitation"
                            class="btn btn-circle btn-small btn-secondary"
                            type="button"
                            ngbTooltip="Remove invitation">
                        <i class="material-icons md-18">close</i>
                    </button>

                    <button (click)="confirmReSubmitQuoter(invitation)"
                            *ngIf="canReSubmitQuoter()"
                            [disabled]="readOnly || inProcessReSubmitQuoter"
                            type="button"
                            class="btn btn-circle btn-small btn-secondary"
                            ngbTooltip="Re-submit invitation ">
                        Re-submit
                    </button>
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <form #f="ngForm"
              (ngSubmit)="f.valid && onSendInviteClick()"
              *ngIf="newInvitation"
              [autocomplete]="false"
              class="new-invitation-form"
              name="form"
              novalidate>
            <button #submitFormButton
                    id="submitFormButton"
                    type="submit">
            </button>

            <table class="af-table">
                <tr>
                    <td style="width: 20%">
                        <div class="form-line"
                             style="margin-top: 9px;"
                             *ngIf="selectCompanyMode">
                            <bp-select-input
                                *ngIf="alreadyInvitedCompaniesInputData"
                                (onSelectionChange)="onMaterialSelectionChange($event)"
                                [data]="alreadyInvitedCompaniesInputData"
                                [placeholder]="'Company'"
                                [removeUnderlines]="true"
                                [noBottomBorder]="true"
                                class="company-select-id">
                            </bp-select-input>
                        </div>

                        <div class="form-line"
                             *ngIf="!selectCompanyMode">
                            <input #company="ngModel"
                                   [(ngModel)]="newInvitation.company"
                                   [ngClass]="{ 'error': (f.submitted || company.dirty || company.touched) && company.invalid }"
                                   autofocus
                                   class="af-input"
                                   name="company"
                                   placeholder="Company"
                                   required
                                   type="text">
                            <div *ngIf="(f.submitted || company.dirty) && company.invalid"
                                 class="inv-error">
                                <label *ngIf="company.errors.required"
                                       class="error">
                                    This field is required
                                </label>
                                <label *ngIf="company.errors.company"
                                       class="error">
                                    Incorrect company
                                </label>
                            </div>
                        </div>
                    </td>
                    <td style="width: 20%">
                        <div class="form-line">
                            <input #email="ngModel"
                                   [(ngModel)]="newInvitation.email"
                                   [ngClass]="{ 'error': (f.submitted || email.dirty || email.touched) && email.invalid }"
                                   autofocus
                                   class="af-input"
                                   email
                                   name="email"
                                   placeholder="Email"
                                   required
                                   type="email">
                            <div *ngIf="(f.submitted || email.dirty) && email.invalid"
                                 class="inv-error">
                                <label *ngIf="email.errors.required"
                                       class="error">
                                    This field is required
                                </label>
                                <label *ngIf="email.errors.email"
                                       class="error">
                                    Incorrect email.
                                </label>
                            </div>
                        </div>
                    </td>
                    <td style="width: 15%">
                        <div class="form-line">
                            <input #phone="ngModel"
                                   [(ngModel)]="newInvitation.phone"
                                   [ngClass]="{ 'error': (f.submitted || phone.dirty || phone.touched) && phone.invalid }"
                                   autofocus
                                   class="af-input"
                                   name="phone"
                                   placeholder="Phone"
                                   required
                                   type="text">
                            <div *ngIf="(f.submitted || phone.dirty) && phone.invalid"
                                 class="inv-error">
                                <label *ngIf="phone.errors.required"
                                       class="error">
                                    This field is required
                                </label>
                                <label *ngIf="phone.errors.phone"
                                       class="error">
                                    Incorrect phone.
                                </label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <button (click)="submitFormButton.click()"
                                    [disabled]="readOnly || inProcessSentInvitation"
                                    class="btn btn-border-radius btn-primary btn-small send-invite"
                                    type="button">
                                Send Invite
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </form>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <button (click)="onInviteBuilderClick()"
                *ngIf="!newInvitation"
                [disabled]="readOnly || !canInviteBuilder()"
                class="btn-secondary btn btn-border-radius m-t-15 invite-builder"
                type="button">
            Invite Builder
        </button>
    </div>
</div>

<div class="row m-t-100">
    <div class="col-sm-6">
        <button [routerLink]="['/scheduler/schedule', project.id]"
                class="btn btn-default btn-border-radius waves-effect previous m-r-20"
                ngbTooltip="Back to Schedule"
                type="button">
            <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Back to Schedule
        </button>
    </div>
    <div class="col-sm-6">
        <div class="text-right">
            <button (click)="onCompareQuotesClick()"
                    class="btn btn-primary btn-border-radius waves-effect next"
                    ngbTooltip="Next"
                    type="button">
                Next <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            </button>
        </div>
    </div>
</div>
