<div class="card">
        <div class="header">
            <div class="row">
                <div class="col-lg-5 left">
                    <h1 class="material-label">
                        Materials
                    </h1>
                    <bp-archive-filter (onValueChanged)="onArchiveFilterValueChanged($event)"
                                       [defaultValue]="archiveFilterValue">
                    </bp-archive-filter>
                </div>
                <div class="col-lg-7 right">
                    <input [formControl]="searchControl"
                           class="af-input"
                           id="material-prefix"
                           name="material-prefix"
                           placeholder="Search material"
                           type="text"/>
                    <button [routerLink]="['/material/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-material pull-right">
                        Create new Material
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="materials"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="material">
                        <span>MATERIAL</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="isDefault">
                        <span>DEFAULT</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="materialOrder">
                        <span>PRIME<br/>ORDER</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="materialCategory.name">
                        <span>MATERIAL<br/>CATEGORY</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="unit.unit">
                        <span>UNIT</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                        <span>SPECIFICATION</span>
                    </th>
                    <th>
                        <span>DEFAULT<br/>PRICE</span>
                    </th>
                    <th>
                        <span>REFERENCE<br/>URL</span>
                    </th>
                    <th bpSortBy="lastPriceModifiedDate">
                        <span>LAST<br/>UPDATED PRICE</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                        <span>FINISH</span>
                    </th>
                    <th>
                        <span>PRIME</span>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let material of materials; trackBy: trackId">
                    <td style="min-width: 100px">
                        <a [routerLink]="['/material', material.id, 'edit']">
                            {{material.id}}
                        </a>
                    </td>
                    <td style="min-width: 20%">
                        <bp-inline-edit
                            type="text"
                            [(ngModel)]="material.material"
                            [label]="'Enter task name'"
                            [required]="true"
                            (onBlur)="onBlurInlineEdit($event, material)">
                        </bp-inline-edit>
                    </td>
                    <td style="min-width: 100px">
                        <bp-inline-checkbox-edit
                            [ngModel]="material.isDefault"
                            (onBlur)="onBlurMaterialIsDefaultEdit($event, material)">
                        </bp-inline-checkbox-edit>
                    </td>
                    <td>
                        {{ material.materialOrder }}
                    </td>
                    <td style="min-width: 20%">
                        <bp-inline-select-edit
                            [ngModel]="material.materialCategory?.id"
                            [placeholder]="'Select category'"
                            [required]="true"
                            [idField]="'id'"
                            [idOption]="'id'"
                            [labelField]="'name'"
                            [values]="materialCategories"
                            (onBlur)="onBlurMaterialCategoryEdit($event, material)">
                        </bp-inline-select-edit>
                    </td>
                    <td style="min-width: 100px">
                        <div *ngIf="material.unit">
                            <bp-inline-select-edit
                                [ngModel]="material.unit.id"
                                [placeholder]="'Select unit'"
                                [required]="true"
                                [idField]="'id'"
                                [idOption]="'id'"
                                [labelField]="'unit'"
                                [values]="units"
                                (onBlur)="onBlurUnitEdit($event, material)">
                            </bp-inline-select-edit>
                        </div>
                    </td>
                    <td style="min-width: 100px">
                        <bp-inline-select-edit
                            [ngModel]="material.specification?.id"
                            [placeholder]="'Select specification'"
                            [required]="false"
                            [idField]="'id'"
                            [idOption]="'id'"
                            [labelField]="'name'"
                            [values]="specifications"
                            (onBlur)="onBlurSpecificationEdit($event, material)">
                        </bp-inline-select-edit>
                    </td>
                    <td style="min-width: 100px">
                        <bp-inline-edit
                            type="number"
                            [(ngModel)]="material.defaultPrice"
                            [label]="'Enter default price'"
                            [required]="true"
                            [currency]="'GBP'"
                            (onBlur)="onBlurInlineEdit($event, material)">
                        </bp-inline-edit>
                    </td>
                    <td style="min-width: 200px; max-width: 200px; overflow-x: auto; ">
                        <bp-inline-edit
                            type="text"
                            [(ngModel)]="material.referenceUrl"
                            [label]="'Enter reference url'"
                            [required]="false"
                            (onBlur)="onBlurInlineEdit($event, material)">
                        </bp-inline-edit>
                    </td>
                    <td style="min-width: 200px; max-width: 200px; overflow-x: auto; ">
                        <span
                            *ngIf="material.lastPriceModifiedDate">{{ material.lastPriceModifiedDate | date:'dd/MM/yy hh:mm' }}</span>
                        <span *ngIf="!material.lastPriceModifiedDate">Unknown</span>
                    </td>
                    <td style="min-width: 100px">
                        <bp-inline-checkbox-edit
                            [label]="'Finish'"
                            [ngModel]="material.type === 'FINISH'"
                            (onBlur)="onBlurMaterialIsFinishEdit($event, material)">
                        </bp-inline-checkbox-edit>
                    </td>
                    <td style="min-width: 100px">
                        <bp-inline-checkbox-edit
                            [label]="'Prime'"
                            [ngModel]="material.isPrime"
                            (onBlur)="onBlurMaterialIsPrimeEdit($event, material)">
                        </bp-inline-checkbox-edit>
                    </td>
                    <td class="text-right"
                        style="min-width: 120px">
                        <div class="bp-btn-group"
                             role="group">
                            <button [routerLink]="['/material', material.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                [routerLink]="['/', { outlets: { popup: 'material/'+ material.id + '/delete'} }]"
                                class="btn btn-danger waves-effect"
                                queryParamsHandling="merge"
                                replaceUrl="true"
                                type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>

                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

