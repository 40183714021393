<div *ngIf="exportSettings">
    <div class="flex justify-content-between w-full m-b-20">
        <div class="setting-container">
            <h1>
                Export Settings
            </h1>
        </div>

        <button class="btn btn-border-radius btn-primary"
                [disabled]="inProcess"
                (click)="save()"
                type="button">
            Save
        </button>
    </div>

    <div class="flex flex-row w-full justify-content-between gap-40">
        <div class="controls-container">
            <div class="flex flex-column w-full gap-20 m-t-20">
                <div class="flex flex-column gap-20">
                    <div class="flex flex-row justify-content-between items-center">
                        <div class="form-check clear w-full">
                            <label class="form-check-label">
                                Include Cover Letter in PDF Export
                                <input [(ngModel)]="exportSettings.includeCoverLetter"
                                       [disabled]="inProcess"
                                       class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-sign"><span class="check"></span></span>
                            </label>
                        </div>
                    </div>
                    <div class="m-l-50 flex flex-column gap-20"
                         *ngIf="false">
                        <div class="form-check clear w-full">
                            <label class="form-check-label">
                                Include Company Logo in top left
                                <input [(ngModel)]="exportSettings.includeCompanyLogo"
                                       [disabled]="inProcess"
                                       class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-sign"><span class="check"></span></span>
                            </label>
                        </div>

                        <div class="form-check clear w-full">
                            <label class="form-check-label">
                                Include my business details in top right
                                <input [(ngModel)]="exportSettings.includeMyBusinessDetails"
                                       [disabled]="inProcess"
                                       class="form-check-input"
                                       type="checkbox">
                                <span class="form-check-sign"><span class="check"></span></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-check clear w-full"
                     *ngIf="false">
                    <label class="form-check-label">
                        Include Title Page
                        <input [(ngModel)]="exportSettings.includeTitlePage"
                               [disabled]="inProcess"
                               class="form-check-input"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>

                <div class="flex flex-row justify-content-between items-center">
                    <div class="form-check clear w-full">
                        <label class="form-check-label">
                            Include Terms and Conditions in PDF Export
                            <input [(ngModel)]="exportSettings.includeTermsAndConditions"
                                   [disabled]="inProcess"
                                   class="form-check-input"
                                   type="checkbox">
                            <span class="form-check-sign"><span class="check"></span></span>
                        </label>
                    </div>
                </div>

                <div class="m-t-40 flex flex-column gap-10">
                    <button class="btn btn-border-radius btn-default"
                            (click)="customizeUserExport('EXCEL')"
                            type="button">
                        Customize Excel Export
                    </button>

                    <button class="btn btn-border-radius btn-default"
                            (click)="customizeUserExport('WORD')"
                            type="button">
                        Customize Word Export
                    </button>

                    <button class="btn btn-border-radius btn-default"
                            (click)="customizeUserExport('PDF')"
                            type="button">
                        Customize PDF Export
                    </button>
                </div>

                <div class="m-t-100">
                    <div class="m-b-20">
                        Please use the following variables if you need:
                    </div>
                    <ul>
                        <li>
                            {{ '{ clientName }' }}
                        </li>
                        <li>
                            {{ '{ projectAddress }' }}
                        </li>
                        <li>
                            {{ '{ projectPrice }' }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="flex-1">
            <div class="btn-group" role="group" aria-label="Export Setting tab">
                <button type="button" class="btn btn-secondary" (click)="tabIndex = 0"
                        [ngClass]="{ 'active': tabIndex === 0 }">
                    Cover Letter
                </button>
                <button type="button" class="btn btn-secondary" (click)="tabIndex = 1"
                        [ngClass]="{ 'active': tabIndex === 1 }">
                    Terms & Conditions
                </button>
            </div>

            <ng-container [ngSwitch]="tabIndex">
                <ng-container *ngSwitchCase="0">
                    <angular-editor [(ngModel)]="exportSettings.coverLetter"
                                    [config]="getEditorConfig()">
                    </angular-editor>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <angular-editor [(ngModel)]="exportSettings.termsAndConditions"
                                    [config]="getEditorConfig()">
                    </angular-editor>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
