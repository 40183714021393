<form #editForm="ngForm"
          name="editForm"
          novalidate
          role="form">
        <h1 id="bp-build-up-heading">
            Create or edit a Build Up
        </h1>

        <div class="flex flex-row gap-40 justify-content-between m-t-20">
            <div class="flex-1">
                <div [hidden]="!item.id"
                     class="form-group">
                    <label for="id">
                        ID
                    </label>
                    <input [(ngModel)]="item.id"
                           class="form-control"
                           id="id"
                           name="id"
                           readonly
                           type="text"/>
                </div>

                <div class="form-group">
                    <label class="form-control-label"
                           for="field_name">
                        Name
                    </label>
                    <input [(ngModel)]="item.name"
                           class="form-control"
                           id="field_name"
                           name="name"
                           required
                           type="text"/>
                    <div [hidden]="!(editForm.controls.name?.dirty && editForm.controls.name?.invalid)">
                        <small [hidden]="!editForm.controls.name?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label"
                               for="global">
                            Global
                            <input [(ngModel)]="item.global"
                                   class="form-check-input"
                                   id="global"
                                   name="global"
                                   type="checkbox">
                            <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                        </label>
                    </div>
                    <div [hidden]="!(editForm.controls.global?.dirty && editForm.controls.global?.invalid)">
                        <small [hidden]="!editForm.controls.global?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-control-label"
                           for="build-up-category">
                        Category
                    </label>
                    <select [(ngModel)]="item.categoryId"
                            class="form-control"
                            id="build-up-category"
                            required
                            name="category">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let category of buildUpCategories; trackBy: trackById"
                                [ngValue]="category.id">
                            {{ category.name }}
                        </option>
                    </select>
                    <div [hidden]="!(editForm.controls.category?.dirty && editForm.controls.category?.invalid)">
                        <small [hidden]="!editForm.controls.category?.errors?.required"
                               class="form-text text-danger">
                            This field is required.
                        </small>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-control-label"
                           for="field_build-up-order">
                        Build Up Order
                    </label>
                    <input [(ngModel)]="item.order"
                           class="form-control"
                           id="field_build-up-order"
                           name="build-up-order"
                           type="number"/>
                </div>

                <div class="form-group">
                    <div class="form-check">
                        <label class="form-check-label"
                               for="archive">
                            Archive
                            <input [(ngModel)]="item.archive"
                                   class="form-check-input"
                                   id="archive"
                                   name="archive"
                                   type="checkbox">
                            <span class="form-check-sign">
                                    <span class="check"></span>
                                </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="flex-1 w-full">
                <h2>
                    Attached tasks
                </h2>

                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th style="width: 20%">
                                <span>TASK ID</span>
                            </th>
                            <th style="width: 60%">
                                <span>TASK</span>
                            </th>
                            <th style="width: 20%">
                                <button (click)="addTask()"
                                        title="Add task"
                                        class="btn btn-link">
                                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                </button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="addingTaskInProgress">
                            <td colspan="3" style="min-width: 300px">
                                <ng-select #myNgSelect
                                           appendTo="body"
                                           [items]="tasks"
                                           bindValue="id"
                                           bindLabel="task"
                                           clearable="false"
                                           [loading]="searchInProgress"
                                           [dropdownPosition]="'bottom'"
                                           (change)="onAddTaskSelectChanged($event)"
                                           (input)="onAddTaskSelectInput($event)">
                                </ng-select>
                            </td>
                        </tr>
                        <tr *ngFor="let task of item.tasks;">
                            <td>
                                {{ task.id }}
                            </td>
                            <td>
                                {{ task.task }}
                            </td>
                            <td>
                                <button (click)="detachTask(task)"
                                        title="Detach task"
                                        class="btn btn-link">
                                    <fa-icon [icon]="['fas', 'remove']"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="text-right">
            <button (click)="previousState()"
                    class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                    ngbTooltip="cancel"
                    type="button">
                Cancel
            </button>

            <button (click)="save()"
                    [disabled]="editForm.form.invalid || isSaving"
                    class="btn btn-primary btn-border-radius waves-effect save"
                    ngbTooltip="Save"
                    type="button">
                Save
            </button>
        </div>
    </form>
