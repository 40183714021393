import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption } from 'app/shared/util/request-util';
import { SERVER_API_URL } from 'app/app.constants';
import { IBuildUp, IBuildUpRequest } from 'app/shared/model/bp.model';
import { ITask } from "app/shared/model/task.model";

@Injectable({ providedIn: 'root' })
export class BuildUpApi {
    public resourceUrl = SERVER_API_URL + 'api/build-up';

    constructor(private http: HttpClient) {
    }

    create(buildUpRequest: IBuildUpRequest): Observable<HttpResponse<IBuildUp>> {
        return this.http.post<IBuildUp>(this.resourceUrl, buildUpRequest, { observe: 'response' });
    }

    update(buildUpRequest: IBuildUp): Observable<HttpResponse<IBuildUp>> {
        return this.http.put<IBuildUp>(this.resourceUrl, buildUpRequest, { observe: 'response' });
    }

    find(id: number): Observable<HttpResponse<IBuildUp>> {
        return this.http.get<IBuildUp>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<IBuildUp[]>> {
        const options = createRequestOption(req);
        return this.http.get<IBuildUp[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    attachTask(buildUpId: number, taskId: number): Observable<HttpResponse<void>> {
        return this.http.post<void>(`${this.resourceUrl}/${buildUpId}/attach-task/${taskId}`, null, { observe: 'response' });
    }

    detachTask(buildUpId: number, taskId: number): Observable<HttpResponse<void>> {
        return this.http.post<void>(`${this.resourceUrl}/${buildUpId}/detach-task/${taskId}`, null, { observe: 'response' });
    }

    related(buildUpId: number): Observable<HttpResponse<IBuildUp[]>> {
        return this.http.get<IBuildUp[]>(`${this.resourceUrl}/${buildUpId}/related`, { observe: 'response' });
    }

    tasks(buildUpId: number): Observable<HttpResponse<ITask[]>> {
        return this.http.get<ITask[]>(`${this.resourceUrl}/${buildUpId}/tasks`, { observe: 'response' });
    }
}
