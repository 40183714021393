<div class="valuation">
    <div class="flex flex-row justify-content-between align-items-start">
        <div class="flex flex-column gap-10">
            <div class="flex gap-15">
                <div class="valuation__search-container">
                    <input (keydown.enter)="search()"
                           [formControl]="searchControl"
                           placeholder="Search"
                           type="text"/>

                    <button (click)="clearSearch()"
                            *ngIf="searchControl.value?.length"
                            aria-hidden="true"
                            class="valuation__search-container__clear-search"
                            type="button">&times;
                    </button>

                    <div class="valuation__search-container__vr">
                    </div>

                    <fa-icon (click)="search()" [icon]="['fas', 'search']"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="store.createForm">
        <div class="m-t-10">
            <bp-valuation-stages>
            </bp-valuation-stages>
        </div>

        <div class="w-full flex flex-row justify-content-end">
            <div class="flex flex-column gap-15 submit-form">
                <div class="flex justify-content-between items-center">
                    <div>Deposit</div>
                    <input formControlName="deposit"
                           class="form-control v2"
                           placeholder="Deposit"
                           id="deposit"
                           name="deposit"
                           required
                           type="text">
                </div>
                <div class="flex justify-content-between items-center">
                    <div>Retention</div>
                    <input formControlName="retention"
                           class="form-control v2"
                           placeholder="Retention"
                           id="retention"
                           name="retention"
                           required
                           type="text">
                </div>
                <div class="flex justify-content-between items-center">
                    <div>Less deposit</div>
                    <div>{{ service.getLessDeposit() | currency:'GBP' }}</div>
                </div>

                <div class="flex justify-content-between items-center">
                    <div>Less retention</div>
                    <div> {{ service.getLessRetention() | currency:'GBP' }}</div>
                </div>

                <div class="flex justify-content-between items-center">
                    <div>Invoice Total</div>
                    <div>{{ service.getInvoiceTotal() | currency:'GBP' }}</div>
                </div>

                <button (click)="onSubmitForValuationButtonClick()"
                        class="btn btn-primary btn-border-radius submit-for-valuation"
                        data-toggle="tooltip"
                        title="Submit for Valuation"
                        type="button">
                    Submit for Valuation
                </button>
            </div>
        </div>
    </form>
</div>
