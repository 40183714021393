<img alt=""
     class="login-logo"
     src="../../../../content/images/bp_logo.png"/>

<div class="wrap-login">
    <div *ngIf="errorEmailNotExists"
         class="alert alert-danger">
        <strong>Email address isn't registered!</strong> Please check and try again.
    </div>

    <ng-container *ngIf="success === 'OK'">
        <form
            class="form"
            name="form"
            role="form"
            autocomplete="off">
            <span class="form-title">Email Sent</span>

            <button [routerLink]="['/']"
                    class="btn btn-link go-to-login"
                    type="button">
                <i class="material-icons md-24">close</i>
            </button>

            <div class="m-t-10 text-center gray">
                Check your email for details on how to reset your password.
            </div>

            <div class="text-center m-t-40">
                <button class="btn-primary btn-border-radius btn"
                        type="button"
                        [routerLink]="['/']">
                    Back to Login
                </button>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="!success">
        <form #resetRequestForm="ngForm"
              (ngSubmit)="requestReset()"
              class="form"
              name="form"
              role="form"
              autocomplete="off">
            <span class="form-title">Reset your password</span>

            <button [routerLink]="['/']"
                    class="btn btn-link go-to-login"
                    type="button">
                <i class="material-icons md-24">close</i>
            </button>

            <div class="form-group">
                <label for="email">
                    Enter the email address you used to register.
                </label>
                <input #emailInput="ngModel"
                       [(ngModel)]="resetAccount.email"
                       class="form-control"
                       email
                       id="email"
                       maxlength=254
                       minlength=5
                       name="email"
                       required
                       type="email">
            </div>

            <div class="text-center m-t-40">
                <button [disabled]="resetRequestForm.form.invalid"
                        class="btn-primary btn-border-radius btn reset"
                        type="submit">
                    Reset
                </button>
            </div>
        </form>
    </ng-container>
</div>
