<div class="text-center"
     *ngIf="!filterState">
    Please wait...
</div>

<div *ngIf="filterState">
    <div class="block-header m-b-10">
        <div class="flex-between-container gap-10">
            <div class="flex-1">
                <a [routerLink]="['../../../quotes', project.id]"
                   class="back-link">
                    <i class="material-icons">arrow_back</i> Back to quote
                </a>
            </div>

            <div class="flex-1 text-center">
                <h1>
                    Quote from <b>{{ quoter?.company || quoter?.email }}</b>
                </h1>
            </div>

            <div class="flex-1 flex flex-row justify-content-end gap-15">
                <button [disabled]="inProcess()"
                        [popper]="bpExportSelector"
                        class="btn btn-secondary btn-border-radius export"
                        ngbTooltip="Export"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <i class="material-icons md-24">
                            file_download
                        </i>

                        <i class="material-icons cursor-pointer">
                            expand_more
                        </i>
                    </div>
                </button>

                <button (click)="toggleExpand()"
                        [disabled]="inProcess()"
                        *ngIf="filterState.groupBy === 'area' || filterState.groupBy === 'stage'"
                        class="btn btn-secondary btn-border-radius expand-collapse"
                        ngbTooltip="{{ expandedAll ? 'Collapse' : 'Expand'}}"
                        [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
                    <div class="flex align-items-center gap-1">
                        <ng-container *ngIf="expandedAll">
                            <i class="material-icons md-24">
                                fullscreen_exit
                            </i>
                        </ng-container>

                        <ng-container *ngIf="!expandedAll">
                            <i class="material-icons md-24">
                                fullscreen
                            </i>
                        </ng-container>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="inited">
        <bp-individual-quote-stages
            [comparison]="comparison"
            [project]="project"
            [quoter]="quoter"
            [nativeScheduleAreas]="filteredScheduleAreas"
            [filterState]="filterState"
            [scheduleAreaItems]="scheduleAreaItems"
            [stageItems]="stageItems"
            (onMainViewFilterChanged)="onMainViewFilterChangedFunc($event)">
        </bp-individual-quote-stages>
    </div>

    <div class="row text-center"
         *ngIf="!inited || inProcess()">
        <strong>
            Please wait..
        </strong>
    </div>
</div>

<popper-content #bpExportSelector>
    <bp-export-selector
        (onChanged)="onExportTypeSelected($event)">
    </bp-export-selector>
</popper-content>
