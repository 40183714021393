<div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h2 id="bp-region-heading">
                    Create or edit a Region
                </h2>
                <div>
                    <div [hidden]="!region.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="region.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly
                               type="text"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_region">
                            Region
                        </label>
                        <input [(ngModel)]="region.region"
                               class="form-control"
                               id="field_region"
                               name="region"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.region?.dirty && editForm.controls.region?.invalid)">
                            <small [hidden]="!editForm.controls.region?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>

