<div *ngIf="buildUp"
     [ngClass]="{'expanded': expanded}"
     class="build-up-container w-full"
     id="build-up-container_{{ scheduleAreaId }}_{{ buildUp.id }}">
    <div>
        <div class="m-l-20">
            <div class="w-full flex flex-row flex-wrap gap-10 justify-content-between align-items-center"
                 style="height: 36px;">
                <div class="flex-1">
                    <div class="root-header m-l--20 flex flex-row items-center gap-15">
                        <bp-select-input
                            *ngIf="buildUpSelectInputData && buildUpSelectInputData.data?.length"
                            [disabled]="scheduleService.disabled"
                            (onSelectionChange)="onBuildUpChange($event)"
                            [data]="buildUpSelectInputData"
                            [removeUnderlines]="false"
                            [additionalClasses]="'fw-500 fs-14'"
                            class="select-build-up-id"
                            style="width: 100%">
                        </bp-select-input>
                        <i class="material-icons bp-cursor-pointer"
                           (click)="toggleStageExpand()">{{ expanded ? "expand_more" : "chevron_right" }}</i>
                    </div>
                </div>

                <div class="flex-1 explanations-data-line">
                    <span *ngIf="expanded" class="p-l-15">Specification</span>
                </div>

                <div class="flex-0_5 explanations-data-line">
                    <span *ngIf="expanded">Area</span>
                </div>

                <div class="qti explanations-data-line">
                    <span *ngIf="expanded">Qty</span>
                </div>

                <div
                    class="totals font-12 flex flex-row justify-content-between items-center gap-10 explanations-data-line">
                    <ng-container *ngIf="expanded">
                        <div class="flex-1 text-center overflow-ellipsis">
                            Labour<br>cost/unit
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Materials<br>cost/unit
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Unit<br>total cost
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Line<br>Total
                        </div>
                        <div class="flex-1 text-center overflow-ellipsis">
                            Client<br>Supplied
                        </div>
                    </ng-container>
                </div>
                <div class="controls">
                    <div [title]="total | currency:'GBP'"
                         class="total">
                        {{ total | currency:'GBP' }}
                    </div>
                    <button (click)="onDeleteCssElementClick()"
                            [disabled]="scheduleService.disabled"
                            class="delete-stage btn btn-circle btn-small btn-secondary"
                            ngbTooltip="Delete build-up">
                        <i class="material-icons md-18">delete_forever</i>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="expanded" class="m-t-10">
            <bp-schedule-task-for-filter-by-build-up-grouping
                *ngFor="let scheduleTask of filteredScheduleTasks()"
                [element]="scheduleService.getElement(scheduleTask)"
                [scheduleTask]="scheduleTask"
                [stage]="scheduleService.getStage(scheduleTask)">
            </bp-schedule-task-for-filter-by-build-up-grouping>
        </div>
    </div>
</div>

