import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { Role } from "app/shared/model/common.model";

export type ReportType = 'EXCEL' | 'WORD' | 'PDF';

export interface IUserReportStructureSheetCell {
    id: number;
    type: string;
    label?: string;

    _checked?: boolean;
}

export interface IUserReportStructureSheet extends IUserReportStructureSheetCell {
    columns: IUserReportStructureSheetCell[];
}

export interface IUserReportStructure extends IUserReportStructureSheetCell {
    sheets: IUserReportStructureSheet[];
}

export interface IUserReport {
    id?: number;
    report?: IUserReportStructureSheetCell,
    sheet?: IUserReportStructureSheetCell,
    column?: IUserReportStructureSheetCell
}

export interface IUserReportSetting {
    newSettings?: IUserReport[];
    removedSettings?: number[];
}

@Injectable({ providedIn: 'root' })
export class UserReportApi {
    public resourceUrl = SERVER_API_URL + 'api/report';

    constructor(private http: HttpClient) {
    }

    getReportStructure(type: ReportType, authority?: Role): Observable<HttpResponse<IUserReportStructure>> {
        let params = new HttpParams().set('type', type);
        if (authority) {
            params = params.append('authority', authority);
        }
        return this.http.get<IUserReportStructure>(`${this.resourceUrl}`, {
            params,
            observe: 'response'
        });
    }

    getReportSettings(type: ReportType, authority?: Role): Observable<HttpResponse<IUserReport[]>> {
        let params = new HttpParams().set('type', type);
        if (authority) {
            params = params.append('authority', authority);
        }

        return this.http.get<IUserReport[]>(`${this.resourceUrl}/user`, {
            params,
            observe: 'response'
        });
    }

    saveReportSettings(type: ReportType, data: IUserReportSetting, authority?: Role): Observable<HttpResponse<IUserReportSetting>> {
        let params = new HttpParams().set('type', type);
        if (authority) {
            params = params.append('authority', authority);
        }

        return this.http.post<IUserReportSetting>(`${this.resourceUrl}/user`, data, {
            params,
            observe: 'response'
        });
    }
}
