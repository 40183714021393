import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProject } from '../../../model/project.model';
import { ProjectApi } from '../../../dataservices/project.api';
import { HttpResponse } from '@angular/common/http';
import { latLng, MapOptions, tileLayer } from 'leaflet';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ProjectActionsService } from 'app/shared/services/project-actions.service';
import { TopMenuStateService } from 'app/shared/services/top-menu-state.service';
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

const defaultX = 51.50853;
const defaultY = -0.12574;

@Component({
    selector: 'bp-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['project-card.scss']
})
export class ProjectCardComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    @Input() project: IProject;
    @Input() isAdmin: boolean;

    @Output() onUpdate = new EventEmitter();

    protected mapOptions: MapOptions;

    constructor(
        private projectActionsService: ProjectActionsService,
        private alertService: BpAlertService,
        private projectApi: ProjectApi,
        private topMenuStateService: TopMenuStateService
    ) {
    }

    get firstPath(): string {
        if (this.project.currentUserRelation?.length === 1 && this.project?.currentUserRelation.includes('ROLE_QUOTER')) {
            return '/quoter';
        } else {
            return '/scheduler';
        }
    }

    ngOnInit(): void {
        if (!this.isAdmin) {
            this.setMapOptions(this.project.latitude || defaultX, this.project.longitude || defaultY);
        }
    }

    protected copyProject(event: any): void {
        event.stopPropagation();

        this.projectActionsService.copyProject(this.project).then((newProject: IProject | null) => {
            if (newProject) {
                this.onUpdate.emit();
                this.alertService.success('Your project has been copied');
            }
        })
    }

    protected toggleArchiveProject(event: any): void {
        event.stopPropagation();

        this.blockUI.start('Please wait..');

        lastValueFrom(this.projectApi.find(this.project.id)).then((project) => {
            project.body.archive = !project.body.archive;

            this.projectApi
                .update(project.body)
                .pipe(
                    finalize(() => {
                        this.blockUI.stop();
                    })
                )
                .subscribe(
                    (res: HttpResponse<any>) => {
                        this.onUpdate.emit();
                        this.alertService.success(`Project was successfully ${project.body.archive ? 'archived' : 'unarchived'}`);
                    },
                );
        })
    }

    protected onSelect(): void {
        this.topMenuStateService.navigate(this.project);
    }

    protected deleteProjectWithConfirm(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this project!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete project!',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteProject()
            }
        });
    }

    private deleteProject(): void {
        this.projectActionsService.deleteProject(this.project).then((res: boolean) => {
            if (res) {
                this.onUpdate.emit();
                this.alertService.success('Your project has been deleted');
            }
        })
    }

    private setMapOptions(x: number, y: number): void {
        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18,
                    opacity: 0.8,
                    detectRetina: true,
                    attribution: '&copy; <a href="https://buildpartner.com">BuildPartner</a>'
                })
            ],
            attributionControl: false,
            zoom: 15,
            zoomControl: false,
            dragging: false,
            center: latLng(x, y)
        };
    }
}
