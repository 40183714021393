<div class="main-fixed-container">
        <div class="flex flex-column gap-40">
            <ng-container *ngIf="scopesStore.headersWithScopes$ | async as headersWithScopes">
                <bp-scopes-container
                    *ngFor="let scopeHeader of headersWithScopes"
                    [scopesHeader]="scopeHeader">
                </bp-scopes-container>

                <bp-scopes-container>
                </bp-scopes-container>
            </ng-container>
        </div>
    </div>

