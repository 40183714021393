import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { HttpResponse } from '@angular/common/http';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import * as _ from 'lodash';
import { IStage } from 'app/shared/model/stage.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { ItemsLoaderResult, ItemsLoaderService } from 'app/shared/services/items-loader.service';
import { IsSchedulerProjectReadOnlyService } from 'app/shared/services/is-scheduler-project-read-only.service';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { ICssElement, IResourceCost } from 'app/shared/model/bp.model';
import { StatQuoterService } from 'app/shared/dataservices/stat-quoter.service';
import { ScheduleAreasService } from 'app/shared/services/schedule-areas.service';
import { ScheduleAreasStore } from 'app/shared/services/schedule-areas.store';
import {
    getActualAreaIds,
    getActualStageIds,
    IMainViewFilterItemItem
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { from, lastValueFrom, Observable, Subject, timer } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { IAccount } from 'app/shared/model/account.model';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { IInvitation } from 'app/shared/model/invitation.model';
import { concatMap, filter, map, take, takeUntil } from 'rxjs/operators';
import { BpAlertService } from 'app/shared/services/bp-alert.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {

    private _stopInvitationPolling$: Subject<boolean> = new Subject<boolean>();
    private _inProgressStatusCount = 0;

    constructor(
        private accountService: AccountService,
        private itemsLoaderService: ItemsLoaderService,
        private dashboardStore: DashboardStore,
        private isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private scheduleAreasService: ScheduleAreasService,
        private statQuoterService: StatQuoterService,
        private scheduleAreasStore: ScheduleAreasStore,
        private invitationService: InvitationService,
        private alertService: BpAlertService,
        private projectApi: ProjectApi) {
    }

    setDefaults(): Promise<void> {
        return this.accountService.identity().then((account: IAccount) => {
            this.dashboardStore.account = account;
            this.dashboardStore.readOnly = this.isSchedulerProjectReadOnlyService.isReadOnly(this.dashboardStore.project);
            this.dashboardStore.valueColumns = [];
            this.dashboardStore.filterState = this.mainFilterInitialStateStorageService.retrieve(this.dashboardStore.project.id, 'project_overview_scheduler');
            this.retrieveInfoIfRevertIsPossible();

            return lastValueFrom(this.itemsLoaderService.loadItems(
                this.dashboardStore.project.id,
                !!this.dashboardStore.account.beta,
                !!this.dashboardStore.account.beta)).then((res: ItemsLoaderResult) => {
                this.dashboardStore.scheduleAreaItems = res.scheduleAreaItems;
                this.dashboardStore.stageItems = res.stageItems;
                this.dashboardStore.cssElementItems = res.cssElementItems;
                this.dashboardStore.filterState.cssElementIds = _.map(this.dashboardStore.cssElementItems, 'id');
            });
        });

    }

    init(): Promise<void> {
        return this.reloadProject().then(() => {
            this.dashboardStore.dashboardMode = null;

            this.dashboardStore.scheduleAreaItems = [];
            this.dashboardStore.stageItems = [];
            this.dashboardStore.cssElementItems = [];
            this.dashboardStore.stageIds = null;
            this.dashboardStore.areaIds = null;
            this.dashboardStore.cssElementIds = null;

            return this.reloadAll();
        });
    }

    destroy(): void {
        this._stopInvitationPolling$.next(true);
    }

    reloadProject(): Promise<IProject> {
        return lastValueFrom(this.projectApi.find(this.dashboardStore.project.id)).then((res: HttpResponse<IProject>) => {
            this.dashboardStore.project = res.body;
            return this.dashboardStore.project;
        });
    }

    addDefaultQuoter(): Observable<number[]> {
        return this.invitationService.addDefaultQuoters(this.dashboardStore.project.id);
    }

    async updateAllToLatestVersion(): Promise<void> {
        this.dashboardStore.invitations = await lastValueFrom(this.invitationService.query(this.dashboardStore.project.id).pipe(map(res => res.body)));
        const promises = this.getInvitationOfNotLatestVersion().map((invitation) => {
            return lastValueFrom(this.projectApi.updateQuoterToCurrentProjectVersion(this.dashboardStore.project.id, invitation.quoterId));
        });
        if (promises.length) {
            return Promise.all(promises).then(() => {
                return;
            });
        }
    }

    retrieveInfoIfRevertIsPossible(): void {
        this.projectApi.isRevertPossible(this.dashboardStore.project.id).subscribe((res: HttpResponse<boolean>) => {
            this.dashboardStore.isRevertPossible = res.body;
        });
    }

    update(): void {
        this.loadComparisons().then(() => {
            this.updateValueColumns();
        });

        this.updateResourceCost();
    }

    updateResourceCost(): void {
        const actualAreaIds = getActualAreaIds(this.dashboardStore.filterState, this.dashboardStore.scheduleAreaItems);
        const actualStageIds = getActualStageIds(this.dashboardStore.filterState, this.dashboardStore.stageItems);

        if (!actualAreaIds?.length || !actualStageIds?.length) {
            this.dashboardStore.resourceCost = {
                buildingMaterial: 0,
                finishingMaterial: 0,
                labour: 0,
                profit: 0
            }
            return;
        }

        lastValueFrom(this.statQuoterService.resourceCost(
            this.dashboardStore.project.id,
            this.dashboardStore.project.defaultQuoter.id,
            actualAreaIds,
            actualStageIds))
            .then((res: HttpResponse<IResourceCost>) => {
                this.dashboardStore.resourceCost = res.body;
            })
    }

    updateDefaultQuoter(quoter: IComparisonQoter): void {
        this.dashboardStore.inProcessUpdatingDefaultQuoter = true;
        this.dashboardStore.valueColumns = null;

        lastValueFrom(this.projectApi
            .updateDefaultQuoter(this.dashboardStore.project.id, quoter.id))
            .finally(() => {
                this.dashboardStore.inProcessUpdatingDefaultQuoter = false;
            })
            .then(() => {
                this.projectApi.find(this.dashboardStore.project.id)
                    .subscribe((res: HttpResponse<IProject>) => {
                        this.dashboardStore.project.defaultQuoter = res.body.defaultQuoter;
                        this.update();
                    });
            });
    }

    isQuoterDefault(quoter: IComparisonQoter): boolean {
        return quoter.id === this.dashboardStore.project.defaultQuoter?.id;
    }

    toggleItem(item: IMainViewFilterItemItem): void {
        switch (this.dashboardStore.mode) {
            case 'areas':
                if (this.dashboardStore.filterState.areaIds != null) {
                    const items = this.dashboardStore.filterState.areaIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.dashboardStore.filterState.areaIds.length === this.dashboardStore.scheduleAreaItems.length) {
                        this.dashboardStore.filterState.areaIds = null;
                    }
                } else {
                    this.dashboardStore.filterState.areaIds = this.dashboardStore.scheduleAreaItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
            case 'stages':
                if (this.dashboardStore.filterState.stageIds != null) {
                    const items = this.dashboardStore.filterState.stageIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.dashboardStore.filterState.stageIds.length === this.dashboardStore.stageItems.length) {
                        this.dashboardStore.filterState.stageIds = null;
                    }
                } else {
                    this.dashboardStore.filterState.stageIds = this.dashboardStore.stageItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
        }

        this.dashboardStore.filterState = this.dashboardStore.filterState;
        //   this.filterStateSubject.next(this.filterState);
    }

    updateItemsIds(): void {
        this.dashboardStore.areaIds = this.dashboardStore.filterState.areaIds ? this.dashboardStore.filterState.areaIds : this.dashboardStore.scheduleAreaItems?.map(i => i.id);
        this.dashboardStore.stageIds = this.dashboardStore.filterState.stageIds ? this.dashboardStore.filterState.stageIds : this.dashboardStore.stageItems?.map(i => i.id);
        this.dashboardStore.cssElementIds = this.dashboardStore.filterState.cssElementIds ? this.dashboardStore.filterState.cssElementIds : this.dashboardStore.cssElementItems?.map(i => i.id);
    }

    updateValueColumns(): void {
        if (!this.dashboardStore.nativeComparison) {
            return;
        }

        const valueColumns: ICostPlanValueColumn[] = [];
        for (let index = 0; index < this.dashboardStore.quoters.length; index++) {
            const quoter = this.dashboardStore.quoters[index];
            const quoterIndex = this.dashboardStore.nativeComparison.quoters.length === 1 ? 0 : this.dashboardStore.nativeComparison.quoters.findIndex(q => q.id === quoter.id);

            const valueColumn: ICostPlanValueColumn = {
                quoter: quoter,
                quoterTotal: 0,
                costPerSqm: 0,
                data: []
            };

            switch (this.dashboardStore.mode) {
                case 'stages':
                    for (const stageItem of (this.dashboardStore.stageItems || [])) {
                        let total = 0;

                        const stage = this.dashboardStore.nativeComparison.stageDTOs.find(stage => stage.id === stageItem.id);
                        if (stage && this.dashboardStore.stageIds.indexOf(stage.id) !== -1) {
                            stage.elementDTOs.forEach(element => {
                                element.taskDTOs.forEach(task => {
                                    if (this.dashboardStore.areaIds.indexOf(task.scheduleAreaRootId) !== -1) {
                                        total += task.totals[quoterIndex];
                                    }
                                })
                            })
                        }

                        valueColumn.data.push({ item: stageItem, total });
                    }
                    break;
                case 'areas':
                    for (const areaItem of (this.dashboardStore.scheduleAreaItems || [])) {
                        let total = 0;

                        this.dashboardStore.nativeComparison.stageDTOs.forEach((stage) => {
                            if (this.dashboardStore.stageIds.indexOf(stage.id) !== -1) {
                                stage.elementDTOs.forEach(element => {
                                    element.taskDTOs.forEach(task => {
                                        if (this.dashboardStore.areaIds.indexOf(task.scheduleAreaRootId) !== -1 && task.scheduleAreaRootId === areaItem.id) {
                                            total += task.totals[quoterIndex];
                                        }
                                    })
                                })
                            }
                        });

                        valueColumn.data.push({ item: areaItem, total });
                    }
                    break;
            }

            valueColumn.quoterTotal = _.sumBy(valueColumn.data, 'total');

            this.scheduleAreasService.calcCostPerSqm(this.dashboardStore.areaIds, valueColumn.quoterTotal).then(res => {
                valueColumn.costPerSqm = res;
            })

            valueColumns.push(valueColumn);
        }
        this.dashboardStore.valueColumns = valueColumns;
        this.dashboardStore.inited = true;
    }

    private loadComparisons(): Promise<void> {
        this.dashboardStore.nativeComparison = {
            quoters: [],
            stageDTOs: []
        };

        const quoterIdsParam = this.dashboardStore.quoters.map(q => q.id);

        if (quoterIdsParam.length === 0) {
            return Promise.resolve();
        }

        this.dashboardStore.inProcessLoadingStatData = true;
        return lastValueFrom(this.projectApi.queryComparison(this.dashboardStore.project.id, quoterIdsParam))
            .finally(() => {
                this.dashboardStore.inProcessLoadingStatData = false;
            }).then((res: HttpResponse<IComparison>) => {
                this.dashboardStore.nativeComparison = res.body;
            });
    }

    private loadDataForMainFilter(): Promise<void> {
        this.dashboardStore.inProcessLoadingDataForMainFilter = true;

        return Promise.all([
            lastValueFrom(this.projectApi.queryAvailableScheduleAreas(this.dashboardStore.project.id)),
            lastValueFrom(this.projectApi.queryScheduleStages(this.dashboardStore.project.id)),
            lastValueFrom(this.projectApi.queryCssElements(this.dashboardStore.project.id)),
            this.scheduleAreasStore.refresh(this.dashboardStore.project.id, true) // do not remove
        ]).finally(() => {
            this.dashboardStore.inProcessLoadingDataForMainFilter = false;
        }).then((result: any[]) => {
            this.dashboardStore.nativeScheduleAreas = result[0].body;
            const stages: IStage[] = result[1].body;

            this.dashboardStore.scheduleAreaItems = _.map(this.dashboardStore.nativeScheduleAreas, (a: IScheduleArea) => {
                return {
                    id: a.id,
                    title: a.area
                };
            });

            this.dashboardStore.stageItems = _.map(stages, (a: IStage) => {
                return {
                    id: a.id,
                    title: a.stage
                };
            });

            const cssElements: ICssElement[] = result[2].body;
            this.dashboardStore.cssElementItems = _.map(cssElements, (a: ICssElement) => {
                return {
                    id: a.id,
                    title: a.name,
                };
            });

            this.updateItemsIds();
        });
    }

    private getInvitationOfNotLatestVersion(): IInvitation[] {
        return this.dashboardStore.invitations.filter(invitation => {
            return !(invitation.quoterId == null || invitation.majorVersion + '.' + invitation.minorVersion == this.dashboardStore.project.version)
        });
    }

    private getVersionForQuoter(quoterId: number): string {
        const invitation = this.dashboardStore.invitations.find(invitation => {
            return invitation.quoterId === quoterId;
        });

        return invitation.majorVersion + '.' + invitation.minorVersion;
    }

    private startInvitationsLongPolling(): void {
        timer(0, 3000)
            .pipe(concatMap(() => from(this.invitationService.query(this.dashboardStore.project.id))))
            .pipe(filter((res) => {
                const inPCount = res.body.filter(invitation => invitation.status === 'IN_PROGRESS').length;
                if (inPCount !== this._inProgressStatusCount) {
                    this.dashboardStore.isShowUpdateButtonEnabled = true;
                    this.alertService.warning("Your prices are out of sync, click 'Update Benchmarks' to ensure comparability.", 5000);
                    this._stopInvitationPolling$.next(true);
                    return true;
                }
                return false;
            }))
            .pipe(take(1))
            .pipe(takeUntil(this._stopInvitationPolling$))
            .subscribe();
    }

    private async reloadAll(): Promise<any> {
        const res = await Promise.all([
            lastValueFrom(this.invitationService.query(this.dashboardStore.project.id)),
            lastValueFrom(this.projectApi.queryQuoters(this.dashboardStore.project.id)),
            this.loadDataForMainFilter(),
        ]);
        this.dashboardStore.invitations = res[0].body;
        this._inProgressStatusCount = this.dashboardStore.invitations.filter(invitation => invitation.status === 'IN_PROGRESS').length;
        if (this._inProgressStatusCount > 0) {
            this.startInvitationsLongPolling();
        }
        this.dashboardStore.quoters = _.uniqBy(res[1].body, 'id')
            .filter(q => this.getVersionForQuoter(q.id) === this.dashboardStore.project.version)
            .map(q_1 => {
                q_1.selected = (q_1.default && !q_1.company.toLowerCase().startsWith('m')) || this.dashboardStore.project.defaultQuoter?.email === q_1.email;
                return q_1;
            });
        this.dashboardStore.isShowAverageRatesButtonEnabled = !this.dashboardStore.invitations.find(i => i.regionBenchmarkQuoter === true);
        this.dashboardStore.isUpdateAllToLatestVersionButtonEnabled = this.getInvitationOfNotLatestVersion().length > 0;
        this.update();
    }
}
