<div>
    <div>
        <h1>
            Task Rates
        </h1>
    </div>

    <div class="card m-t-40">
        <div class="flex flex-column gap-15">
                <div class="w-full flex flex-row justify-content-between align-items-center flex-wrap gap-20 m-b-10">
                    <div class="flex-1">
                        <span class="table-title">Task</span>
                    </div>
                    <div class="w-300">
                        <span class="table-title">Labour</span>
                    </div>
                    <div class="w-120">
                        <span class="table-title">Hours per unit</span>
                    </div>
                    <div class="w-120">
                        <span class="table-title">Margin</span>
                    </div>
                    <div class="w-60">
                    </div>
                </div>

                <div *ngIf="inProcess()"
                     class="text-center">
                    <strong>
                        Please wait..
                    </strong>
                </div>

                <div *ngFor="let labour of taskLabours;"
                     class="w-full flex flex-row justify-content-between align-items-center flex-wrap gap-20 labour-row">
                    <div class="flex-1">
                        {{ labour.task }}
                    </div>
                    <div class="w-300">
                        {{ labour.trade }}
                    </div>
                    <div class="w-120">
                        {{ labour.hours  | number : '1.2-2' }}
                    </div>
                    <div class="w-120">
                        {{ labour.margin * 100 }}<span class="sign">%</span>
                    </div>
                    <div class="w-60">
                        <button (click)="confirmDeleteTaskLabour(labour)"
                                *ngIf="labour.id && !inProcessDeleteTaskLabour"
                                class="delete-task btn btn-circle btn-small btn-secondary"
                                type="button"
                                ngbTooltip="Delete task labour">
                            <i class="material-icons md-18">delete_forever</i>
                        </button>
                    </div>
                </div>

                <small *ngIf="taskLabours.length === 0">
                    No items
                </small>
            </div>
    </div>
</div>
