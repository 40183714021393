import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { map } from 'rxjs/operators';

export interface IAddressByPostCode {
    postcode: string;
    addressLine1: string;
    addressLine2: string;
    city: string
    region: string;
}

@Injectable({ providedIn: 'root' })
export class CommonService {
    public resourceUrl = SERVER_API_URL + 'api/common';

    constructor(private http: HttpClient) {
    }

    addressByPostCode(postcode: string): Observable<IAddressByPostCode> {
        return this.http.get<IAddressByPostCode>(`${this.resourceUrl}/address/${postcode}`)
            .pipe(map(res => {
                res.postcode = postcode;
                return res;
            }))
    }
}
