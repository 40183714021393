<app-loader [showBackground]="loaderService.showBackground"
            [backgroundImage]="loaderService.backgroundImage"
            *ngIf="loaderService.show">
</app-loader>

<block-ui>
    <ng-container *ngIf="fullscreen; else mainBlock">
        <router-outlet>
        </router-outlet>
    </ng-container>
    <ng-template #mainBlock>
        <div class="container-fluid">
            <bp-navbar
                *ngIf="showNavbar()">
            </bp-navbar>

            <section class="content"
                     id="main">
                <router-outlet>
                </router-outlet>
                <router-outlet name="popup">
                </router-outlet>
            </section>

            <bp-footer>
            </bp-footer>
        </div>
    </ng-template>
</block-ui>
