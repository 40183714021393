import { Injectable } from '@angular/core';
import { IStage } from 'app/shared/model/stage.model';
import { IProject } from 'app/shared/model/project.model';
import { IScheduleArea } from 'app/shared/model/schedule-area.model';
import {
    getActualAreaIds,
    getActualStageIds,
    IMainViewFilterItemItem,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { ShowSubStagesMode } from 'app/shared/constants/show-substages-modes';
import * as _ from 'lodash';
import { IElement } from 'app/shared/model/element.model';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IAccount } from 'app/shared/model/account.model';
import {
    AddGroupOfScheduleTasksRequest,
    IAddScheduleTaskRequest,
    IScheduleTaskModificationEvent,
    IScheduleTaskQuoterTotalModificationEvent
} from 'app/shared/constants/events.constants';
import { BehaviorSubject, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { finalize, skip } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { IQueryStagesResult, ProjectApi } from 'app/shared/dataservices/project.api';
import {
    ApplyMainFilterToScheduleService,
    IMainFilterApplyingToScheduleResult
} from 'app/flows/scheduler/services/apply-main-filter-to-schedule.service';
import { IArea } from 'app/shared/model/area.model';
import { ITask } from 'app/shared/model/task.model';
import {
    ShowSubStagesModeStorageService
} from 'app/flows/scheduler/schedule/services/show-sub-stages-mode-storage.service';
import { AccountService } from 'app/core/auth/account.service';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { StageService } from 'app/shared/dataservices/stage.service';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { DefaultPricesUpdaterService } from 'app/flows/scheduler/schedule/services/default-prices-updater.service';
import { TaskUpdateHelperService } from 'app/flows/scheduler/schedule/services/task-update-helper.service';
import {
    CalculateStageFilteredByAreaTotalService
} from 'app/flows/scheduler/schedule/services/calculate-stage-filtered-by-area-total.service';
import { AddItemToScheduleService } from 'app/flows/scheduler/schedule/services/add-schedule-task.service';
import { LoadScheduleTotalAsyncService } from 'app/flows/scheduler/schedule/services/load-total-async.service';
import { IsSchedulerProjectReadOnlyService } from 'app/shared/services/is-scheduler-project-read-only.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ItemsLoaderResult, ItemsLoaderService } from 'app/shared/services/items-loader.service';
import { ExportType } from 'app/shared/constants/export-types';
import { ScheduleTaskUpd } from 'app/shared/model/schedule-task-upd.model';
import { IScheduleUpdateData } from 'app/shared/model/schedule-update-data.model';
import { ScheduleUpdateService } from 'app/shared/dataservices/schedule-update.service';
import { ExportReportsService, IMainReportData } from 'app/shared/services/export/export-reports.service';
import { ScheduleEventsService } from 'app/flows/scheduler/schedule/schedule-events.service';
import { IBuildUp, ICssElement } from 'app/shared/model/bp.model';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import { BulkModeStorageService } from 'app/flows/scheduler/schedule/services/bulk-mode-storage.service';
import { HighlightTasksStorageService } from 'app/flows/scheduler/schedule/services/highlight-tasks-storage.service';
import { UserReportModalService } from 'app/account/user-report-modal/user-report-modal.service';
import { BPStore } from 'app/shared/stores/bp.store';
import { ScheduleAreaApi } from 'app/shared/dataservices/schedule-area.api';
import { BuildUpApi } from "app/shared/dataservices/build-up.api";

export interface IExpandStageEvent {
    stageId: number;
    scheduleAreaId: number | null;
    expand: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {
    @BlockUI() blockUI: NgBlockUI;

    nativeScheduleAreas: IScheduleArea[];
    filteredScheduleAreas: IScheduleArea[];
    scheduleAreaItems: IMainViewFilterItemItem[];
    stageItems: IMainViewFilterItemItem[];
    stageItemsInUse: IMainViewFilterItemItem[];
    cssElementItems: IMainViewFilterItemItem[];
    buildUpItems: IMainViewFilterItemItem[];

    allStages: IStage[];

    refreshSub: Subject<void> = new Subject<void>();

    //
    globalStages: IStage[];
    stages: IStage[];
    cssElementMap: Map<number, { cssElement: ICssElement, scheduleTasks: IScheduleTask[] }> = new Map<number, {
        cssElement: ICssElement,
        scheduleTasks: IScheduleTask[]
    }>();
    buildUpMapSubject: BehaviorSubject<Map<number, { buildUp: IBuildUp, scheduleTasks: IScheduleTask[] }>> =
        new BehaviorSubject(new Map<number, { buildUp: IBuildUp, scheduleTasks: IScheduleTask[] }>);
    inited = false;
    readOnly = true;
    isAdmin = false;
    account: IAccount;
    inProcessReloadAll = false;
    inProcessLoadingAllStages = false;
    inProcessLoadingAllForReportStages = false;
    inProcessLoadingGlobalStages = false;
    inProcessGlobalSaving = false;
    inProcessExporting = false;
    inProcessAddingTaskToStageAndLoadingTasks = false;
    inProcessScheduleTaskModification = false;
    total: number;
    totalCanBeShown = false;
    addScheduleTaskSub: Subscription;
    addGroupOfScheduleTasksSub: Subscription;
    modifyScheduleTaskSub: Subscription;
    loadingFinishedSub: Subscription;
    stageQuoterTotalModifiedSub: Subscription;
    scheduleTaskTotalModifiedSub: Subscription;
    bulkUpdateModeSub: Subscription;
    //
    private bulkUpdateModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    bulkUpdateMode$: Observable<boolean> = this.bulkUpdateModeSubject.asObservable();

    private expandAllSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    expandAll$: Observable<boolean> = this.expandAllSubject.asObservable();

    private expandStageSubject: Subject<IExpandStageEvent> = new Subject<IExpandStageEvent>();
    expandStage$: Observable<IExpandStageEvent> = this.expandStageSubject.asObservable();

    constructor(private showSubStagesModeStorageService: ShowSubStagesModeStorageService,
                private accountService: AccountService,
                private alertService: BpAlertService,
                private stageService: StageService,
                private projectApi: ProjectApi,
                private bpStore: BPStore,
                private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
                private defaultPricesUpdaterService: DefaultPricesUpdaterService,
                private taskUpdateHelper: TaskUpdateHelperService,
                private applyMainFilterToScheduleService: ApplyMainFilterToScheduleService,
                private calculateStageFilteredByAreaTotalService: CalculateStageFilteredByAreaTotalService,
                private addItemToScheduleService: AddItemToScheduleService,
                private loadScheduleTotalAsyncService: LoadScheduleTotalAsyncService,
                private isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
                private itemsLoaderService: ItemsLoaderService,
                private taskUpdateHelperService: TaskUpdateHelperService,
                private scheduleAreaApi: ScheduleAreaApi,
                private scheduleCacheService: ScheduleUpdateService,
                private exportReportsService: ExportReportsService,
                private scheduleEventsService: ScheduleEventsService,
                private bulkModeStorageService: BulkModeStorageService,
                private _highlightTasksStorageService: HighlightTasksStorageService,
                private _buildUpApi: BuildUpApi,
                private _userReportModalService: UserReportModalService,
                private confirmModalService: ConfirmModalService) {
    }

    get disabled(): boolean {
        return this.readOnly || this.inProcess();
    }

    get expandAll(): boolean {
        return this.expandAllSubject.value;
    }

    _project: IProject;

    get project(): IProject {
        return this._project;
    }

    set project(value: IProject) {
        this._project = value;
        this.readOnly = this.isSchedulerProjectReadOnlyService.isReadOnly(this.project);
    }

    get bulkUpdateMode(): boolean {
        return this.bulkUpdateModeSubject.value;
    }

    set bulkUpdateMode(value: boolean) {
        this.bulkModeStorageService.storeMode(this.project.id, value);
        this.bulkUpdateModeSubject.next(value);
    }

    _searchKey: string | null = null;

    get searchKey(): string | null {
        return this._searchKey;
    }

    set searchKey(value: string | null) {
        this._searchKey = value;
        this.loadAllStages().then(() => {
            this.reloadAllSavingCurrentScrollPosition();
            if (value?.length) {
                this.toggleExpandAll(true);
            }
        });
    }

    private _filterState: IMainViewFilterState;

    get filterState(): IMainViewFilterState {
        return this._filterState;
    }

    set filterState(value: IMainViewFilterState) {
        this._filterState = value;
        this.totalCanBeShown = false;
        this.mainFilterInitialStateStorageService.store(this.project.id, 'schedule', value);
        this.reloadAllSavingCurrentScrollPosition();
    }

    private _showSubStagesMode: ShowSubStagesMode;

    get showSubStagesMode(): ShowSubStagesMode {
        return this._showSubStagesMode;
    }

    set showSubStagesMode(value: ShowSubStagesMode) {
        this._showSubStagesMode = value;
        this.updateStageItems();
        this.showSubStagesModeStorageService.store(this.project.id, value);
        this.reloadAllSavingCurrentScrollPosition();
    }

    get allStageItems(): IMainViewFilterItemItem[] {
        return this.allStages?.map((stage: IStage) => {
            const res: IMainViewFilterItemItem = { id: stage.id, title: stage.stage };
            return res;
        }) || [];
    }

    get showSubStages(): boolean {
        return this.showSubStagesMode === 'display-all' || this.showSubStagesMode === 'show-in-use';
    }

    init(): void {
        this.accountService.identity().then((account: IAccount) => {
            this.account = account;

            this.isAdmin = this.accountService.isAdmin();
            this._showSubStagesMode = this.showSubStagesModeStorageService.retrieve(this.project.id);

            this.bulkUpdateMode = this.bulkModeStorageService.retrieveMode(this.project.id);

            this.bulkUpdateModeSub?.unsubscribe();
            this.bulkUpdateModeSub = this.bulkUpdateMode$
                .pipe(
                    skip(1)
                ).subscribe((value: boolean) => {
                    if (value && !this.bulkModeStorageService.retrieveDoNotShowAgain(this.project.id)) {
                        this.confirmModalService.open(
                            {
                                header: 'Bulk edit function has been turned on',
                                textHtml: `
                          <div class='flex flex-column gap-20'>
                                <div class='strong text-left'>
                                This will replicate certain changes to a task to other tasks of the same type.
                               </div>
                               <div class='strong text-left'>
                                    This includes the following:
                               </div>
                               <ul class='list-disc text-left m-l-20'>
                                  <li class='list-disc'>Changing the task itself</li>
                                  <li class='list-disc'>Changing or adding a specification</li>
                                  <li class='list-disc'>Marking / unmarking client supply</li>
                               </ul>
                           </div>`,
                                cancelButtonText: 'Cancel',
                                confirmButtonText: 'Confirm',
                                swapButtons: false,
                                showDoNotShowAgain: true,
                                showDoNotShowAgainPlace: 'left'
                            }
                        ).result.then((res) => {
                            this.bulkModeStorageService.storeDoNotShowAgain(this.project.id, res?.doNotShowAgain)
                        });
                    }
                });

            this.loadGlobalStages().then(() => {
                this.reloadAll().then(() => {
                    this.clearHighlights();
                    this.inited = true;

                    this.addScheduleTaskSub?.unsubscribe();
                    this.addScheduleTaskSub = this.scheduleEventsService.addScheduleTask$.subscribe((value: IAddScheduleTaskRequest) => {
                        this.addScheduleTask(value.stage, value.element, value.area, value.task);
                    })

                    this.addGroupOfScheduleTasksSub?.unsubscribe();
                    this.addGroupOfScheduleTasksSub = this.scheduleEventsService.addGroupOfScheduleTasks$.subscribe((value: AddGroupOfScheduleTasksRequest) => {
                        this.saveScheduleTasksToDatabase(value.scheduleTasks, true);
                    })

                    this.modifyScheduleTaskSub?.unsubscribe();
                    this.modifyScheduleTaskSub = this.scheduleEventsService.requestToModifyScheduleTask$.subscribe((value: IScheduleTaskModificationEvent) => {
                        this.onScheduleTaskModification(value);
                    })

                    this.loadingFinishedSub?.unsubscribe();
                    this.loadingFinishedSub = this.scheduleEventsService.loadingFinished$.subscribe((value: boolean) => {
                        this.totalCanBeShown = value;
                    })

                    this.stageQuoterTotalModifiedSub?.unsubscribe();
                    this.stageQuoterTotalModifiedSub = this.scheduleEventsService.stageQuoterTotalModified$.subscribe(() => {
                        this.total = this.calculateQuoterTotal();
                    })

                    this.scheduleTaskTotalModifiedSub?.unsubscribe();
                    this.scheduleTaskTotalModifiedSub = this.scheduleEventsService.scheduleTaskTotalModified$.subscribe((value: IScheduleTaskQuoterTotalModificationEvent) => {
                        this.total = this.calculateQuoterTotal();
                    })

                    this.total = this.calculateQuoterTotal();
                });
            });
        });
    }

    destroy(): void {
        this.inited = false;

        this.addScheduleTaskSub?.unsubscribe();
        this.addGroupOfScheduleTasksSub?.unsubscribe();
        this.modifyScheduleTaskSub?.unsubscribe();
        this.loadingFinishedSub?.unsubscribe();
        this.stageQuoterTotalModifiedSub?.unsubscribe();
        this.scheduleTaskTotalModifiedSub?.unsubscribe();
        this.bulkUpdateModeSub?.unsubscribe();
    }

    isEditRatesEnabled(): boolean {
        return +this.account.id === +this.project.defaultQuoter.userId || this.account.linkedQuoters?.length && this.account.linkedQuoters[0].toLowerCase() === this.project.defaultQuoter.email.toLowerCase();
    }

    toggleExpandAll(forcedValue = null): void {
        this.expandAllSubject.next(forcedValue != null ? forcedValue : !this.expandAllSubject.value);
    }

    expandStage(stageId: number, scheduleAreaId: number | null, expand: boolean = true): void {
        this.expandStageSubject.next({ stageId, scheduleAreaId, expand });
    }

    filteredStages(stages: IStage[], scheduleArea: IScheduleArea = null): IStage[] {
        switch (this.showSubStagesMode) {
            case 'show-in-use':
                return _.filter(stages, (stage: IStage) => {
                    return _.find(stage.elements, (element: IElement) => {
                        const scheduleTasks = _.filter(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                            if (scheduleArea != null) {
                                return scheduleTask.scheduleAreaId === scheduleArea.id;
                            }
                            return true;
                        });

                        return scheduleTasks.length > 0;
                    });
                });
            case 'display-all':
                return stages;
            case 'hide-all':
                return _.filter(stages, (stage: IStage) => {
                    return _.find(stage.elements, (element: IElement) => {
                        const scheduleTasks = _.filter(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                            if (scheduleArea != null) {
                                return scheduleTask.scheduleAreaId === scheduleArea.id;
                            }
                            return true;
                        });

                        return scheduleTasks.length > 0;
                    });
                });
            case 'finishes':
                return _.filter(stages, (stage: IStage) => {
                    return _.find(stage.elements, (element: IElement) => {
                        const scheduleTasks = _.filter(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                            return scheduleTask.finishes;
                        });

                        return scheduleTasks.length > 0;
                    });
                });
        }
    }

    filteredStagesForScheduleAreas(scheduleAreas: IScheduleArea[] = []): IStage[] {
        const result = [];
        scheduleAreas.forEach(scheduleArea => {
            result.push(...this.filteredStages(scheduleArea['stages'], scheduleArea));
        })
        return result;
    }

    calculateQuoterTotal(): number {
        let total = 0;

        switch (this.filterState.groupBy) {
            case 'area':
            case 'area_room':
                _.each(this.nativeScheduleAreas, (scheduleArea: IScheduleArea) => {
                    _.each(scheduleArea['stages'], (stage: IStage) => {
                        if (stage._quoterTotals == null || stage._quoterTotals[scheduleArea.id] == null) {
                            return;
                        }
                        total += stage._quoterTotals[scheduleArea.id];
                    });
                });
                break;
            case 'stage':
            case 'stage_room':
                _.each(this.stages, (stage: IStage) => {
                    if (stage.total == null) {
                        return;
                    }
                    total += stage.total;
                });
                break;
            case 'css-element':
            case 'css-element_room':
                for (let [key, value] of this.cssElementMap) {
                    value.scheduleTasks?.forEach((scheduleTask: IScheduleTask) => {
                        total += !scheduleTask.markedAsDeleted ? scheduleTask.taskTotal.total : 0;
                    });
                }
                break;
            case 'build-up':
            case 'build-up_room':
                for (let [key, value] of this.buildUpMapSubject.value) {
                    value.scheduleTasks?.forEach((scheduleTask: IScheduleTask) => {
                        total += !scheduleTask.markedAsDeleted ? scheduleTask.taskTotal.total : 0;
                    });
                }
                break;
        }

        return total;
    }

    inProcess(): boolean {
        return (
            this.inProcessReloadAll ||
            this.inProcessLoadingAllStages ||
            this.inProcessLoadingAllForReportStages ||
            this.inProcessLoadingGlobalStages ||
            this.inProcessGlobalSaving ||
            this.inProcessAddingTaskToStageAndLoadingTasks ||
            this.inProcessScheduleTaskModification
        );
    }

    inProcessLoading(): boolean {
        return (
            this.inProcessReloadAll ||
            this.inProcessLoadingAllStages ||
            this.inProcessLoadingAllForReportStages ||
            this.inProcessLoadingGlobalStages ||
            this.inProcessGlobalSaving
        );
    }

    exportAs(exportType: ExportType): Promise<boolean> {
        return new Promise(resolve => {
            const mainReportData: IMainReportData = {
                project: this.project,
                filterState: this.filterState,
                scheduleAreaItems: this.scheduleAreaItems,
                stageItems: this.stageItems,
                cssElementItems: this.cssElementItems,
                buildUpItems: this.buildUpItems
            }

            const defaultQuoterId = this.getDefaultQuoterId();
            this.inProcessExporting = true;

            switch (exportType) {
                case 'csv': {
                    this._userReportModalService.open('EXCEL').result.then((res) => {
                        if (res) {
                            this.exportReportsService?.exportAsExcelIndividualQuoter(mainReportData, this.project.defaultQuoter)
                                .finally(() => {
                                    this.inProcessExporting = false
                                });
                            resolve(res);
                        }
                    })
                    break;
                }
                case 'pdf':
                    this._userReportModalService.open('PDF').result.then((res) => {
                        if (res) {
                            this.exportReportsService?.exportAsPDFIndividualQuoter(mainReportData, this.project.defaultQuoter)
                                .finally(() => {
                                    this.inProcessExporting = false
                                });
                        }
                        resolve(res);
                    })
                    break;
                case 'docx':
                    this._userReportModalService.open('WORD').result.then((res) => {
                        if (res) {
                            this.exportReportsService?.exportAsDocxIndividualQuoter(mainReportData, this.project.defaultQuoter)
                                .finally(() => {
                                    this.inProcessExporting = false
                                });
                        }
                        resolve(res);
                    })
                    break;
            }
        })
    }

    duplicateScheduleTask(element: IElement, scheduleTaskForDuplication: IScheduleTask): Promise<void> {
        const task: ScheduleTaskUpd = this.taskUpdateHelperService.getTaskForSaving(scheduleTaskForDuplication);
        delete task.id;

        const scheduleCache: IScheduleUpdateData = {
            projectId: scheduleTaskForDuplication.projectId,
            tasks: [task],
            deleteIds: []
        };

        return new Promise(resolve => {
            lastValueFrom(this.scheduleCacheService.batchUpdate(scheduleCache)).then((res: HttpResponse<IScheduleTask[]>) => {
                const allScheduleTasks = this.getAllScheduleTasks();
                const promises = [];
                res.body.forEach((updST) => {
                    let scheduleTask = allScheduleTasks.find((st) => st.id === updST.id);
                    if (scheduleTask) {
                        scheduleTask = _.merge(scheduleTask, updST);
                        promises.push(this.taskUpdateHelperService.fillTotals(scheduleTask, this.getDefaultQuoterId()));
                    } else {
                        const newScheduleTask = res.body[0];
                        const index = element.scheduleTasks.indexOf(scheduleTaskForDuplication);
                        element.scheduleTasks.splice(index + 1, 0, newScheduleTask);
                        promises.push(this.taskUpdateHelperService.fillTotals(newScheduleTask, this.getDefaultQuoterId()));
                    }
                })

                if (!promises.length) {
                    resolve();
                } else {
                    Promise.all(promises).then(() => resolve());
                }
            });
        })
    }

    changeBuildUpForScheduleTasks(newBuildUp: IBuildUp, scheduleTasks: IScheduleTask[]): Promise<void> {
        this.blockUI.start("Updating build up for the tasks...");

        return lastValueFrom(this._buildUpApi.tasks(newBuildUp.id)).then((newTasks) => {
            const updateData: IScheduleUpdateData = {
                projectId: this.project.id,
                deleteIds: scheduleTasks.map(scheduleTask => scheduleTask.id),
                tasks: newTasks.body.map((task) => {
                    const v = task as any;
                    v.taskId = task.id;
                    delete v.id;
                    v.buildUpId = newBuildUp.id;
                    v.materialCategoryIds = task.materialCategories?.length ? [task.materialCategories[0].id] : [];
                    v.scheduleAreaId = scheduleTasks[0].scheduleAreaId;
                    v.unitValue = 1;
                    return task;
                })
            };

            return new Promise(resolve => {
                lastValueFrom(this.scheduleCacheService.batchUpdate(updateData)).then((res: HttpResponse<IScheduleTask[]>) => {
                    const allScheduleTasks = this.getAllScheduleTasks();
                    const promises = [];
                    res.body.forEach((updST) => {
                        let scheduleTask = allScheduleTasks.find((st) => st.id === updST.id);
                        if (scheduleTask) {
                            scheduleTask = _.merge(scheduleTask, updST);
                            promises.push(this.taskUpdateHelperService.fillTotals(scheduleTask, this.getDefaultQuoterId()));
                        }
                    })

                    if (!promises.length) {
                        resolve();
                    } else {
                        Promise.all(promises).then(() => resolve());
                    }
                }).finally(() => {
                    this.blockUI.stop();
                });
            })
        })
    }

    getAllScheduleTasks(): IScheduleTask[] {
        let scheduleTasks: IScheduleTask[] = [];

        switch (this.filterState.groupBy) {
            case 'area':
            case 'area_room':
                _.each(this.filteredScheduleAreas, (area: IArea) => {
                    _.each(area['stages'], (stage: IStage) => {
                        stage.elements.forEach((element) => {
                            element.scheduleTasks?.forEach(st => {
                                if (st.scheduleAreaId === area.id) {
                                    scheduleTasks.push(st);
                                }
                            })
                        })
                    });
                });
                break;
            case 'stage':
            case 'stage_room':
                _.each(this.stages, (stage: IStage) => {
                    stage.elements.forEach((element) => {
                        scheduleTasks.push(...element.scheduleTasks);
                    })
                });
                break;
            case 'css-element':
            case 'css-element_room':
                for (let [key, value] of this.cssElementMap) {
                    value.scheduleTasks?.forEach((scheduleTask: IScheduleTask) => {
                        scheduleTasks.push(scheduleTask);
                    });
                }
                break;
        }

        return scheduleTasks;
    }

    globalSave(blockMessage: string, blockUI?: NgBlockUI): void {
        blockUI?.start(blockMessage);

        this.taskUpdateHelper
            .globalSaveSchedule(this.project.id, true)
            .pipe(
                finalize(() => {
                    blockUI?.stop();
                })
            )
            .subscribe(() => {
                this.reloadAllSavingCurrentScrollPosition();
            });
    }

    deleteScheduleTasks(tasks: IScheduleTask[]): void {
        const scheduleTasksToDelete = [];
        tasks.forEach((scheduleTask: IScheduleTask) => {
            console.warn('remove task');
            scheduleTask.markedAsDeleted = true;
            scheduleTasksToDelete.push(scheduleTask);
        })
        this.inProcessScheduleTaskModification = true;

        this.clearHighlights();

        this.taskUpdateHelperService.updateScheduleTasks(this.project.id, scheduleTasksToDelete, this.getAllScheduleTasks(), this.getDefaultQuoterId())
            .finally(() => {
                this.inProcessScheduleTaskModification = false;
            })
            .then(() => {
                this.reloadAllSavingCurrentScrollPosition();
            });
    }

    deleteStage(area: IScheduleArea, stage: IStage): void {
        const scheduleTasksToDelete = [];
        stage.elements.forEach((element: IElement) => {
            element.scheduleTasks
                .filter((st: IScheduleTask) => !area || st.scheduleAreaId === area.id)
                .forEach((scheduleTask: IScheduleTask) => {
                    scheduleTask.markedAsDeleted = true;
                    scheduleTasksToDelete.push(scheduleTask);
                });
        });

        this.inProcessScheduleTaskModification = true;

        this.clearHighlights();

        this.taskUpdateHelperService.updateScheduleTasks(this.project.id, scheduleTasksToDelete, this.getAllScheduleTasks(), this.getDefaultQuoterId())
            .finally(() => {
                this.inProcessScheduleTaskModification = false;
            })
            .then(() => {
                if (!area) {
                    this.stages.splice(this.stages.indexOf(stage), 1);
                    this.stageItems.splice(this.stageItems.findIndex(s => s.id === stage.id), 1);
                    this.updateStageItems();
                    this.refreshSub.next();
                    this.scheduleEventsService.stageQuoterTotalModified();
                } else {
                    this.reloadAllSavingCurrentScrollPosition();
                }
            });
    }

    deleteArea(area: IScheduleArea): void {
        this.inProcessScheduleTaskModification = true;

        this.clearHighlights();

        lastValueFrom(this.scheduleAreaApi
            .batchUpdate([], [area.id])).finally(() => {
            this.inProcessScheduleTaskModification = false;
        }).then(() => {
            this.reloadAllSavingCurrentScrollPosition();
        });
    }

    reloadAllSavingCurrentScrollPosition(): void {
        this.blockUI.start("Reloading schedule...");

        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        this.stages = null;
        this.globalStages = null;
        this.allStages = null;

        this.loadGlobalStages().then(() => {
            this.reloadAll().then(() => {
                setTimeout(() => {
                    this.scheduleEventsService.stageQuoterTotalModified();
                    this.blockUI.stop();

                    window.scrollTo(0, currentScrollPosition);
                }, 200);
            });
        });
    }

    getStage(scheduleTask: IScheduleTask): IStage {
        return this.allStages.find(st => st.id === scheduleTask.stageId);
    }

    getElement(scheduleTask: IScheduleTask): IElement {
        return this.allStages.find(st => st.id === scheduleTask.stageId)?.elements?.find(el => el.id === scheduleTask.elementId);
    }

    private loadGlobalStages(): Promise<void> {
        this.inProcessLoadingGlobalStages = true;

        return new Promise(resolve => {
            this.stageService
                .query()
                .pipe(
                    finalize(() => {
                        this.inProcessLoadingGlobalStages = false;
                    })
                )
                .subscribe((res: HttpResponse<IStage[]>) => {
                    this.globalStages = res.body;
                    resolve();
                });
        });
    }

    private loadAllStages(): Promise<void> {
        this.inProcessLoadingAllStages = true;
        return new Promise(resolve => {
            /* https://buildpartner.atlassian.net/browse/BP-2188 - defaultQuoterId is null here */
            this.projectApi
                .queryStagesForTheProject(
                    this.project.id,
                    getActualAreaIds(this.filterState, this.scheduleAreaItems),
                    getActualStageIds(this.filterState, this.stageItems),
                    null,
                    null, // defaultQuoterId,
                    this.searchKey
                )
                .pipe(finalize(() => {
                }))
                .subscribe((res: HttpResponse<IQueryStagesResult>) => {
                    this.allStages = this.addAllStagesAndElementsEvenIfTheyDoesNotContainScheduleTasks(res.body.stages);
                    this.loadScheduleTotalAsyncService
                        .loadObs(this.project, this.allStages, this.getDefaultQuoterId())
                        .then(() => {
                            _.each(this.allStages, (stage: IStage) => {
                                _.each(stage.elements, (element: IElement) => {
                                    element.scheduleTasks = element.scheduleTasks || [];

                                    _.each(element.scheduleTasks, (scheduleTask: IScheduleTask) => {
                                        scheduleTask.taskTotal = scheduleTask.taskTotal || {
                                            materialCost: 0,
                                            laborCost: 0,
                                            qty: 0,
                                            total: 0
                                        };
                                    });
                                });
                            });

                            _.each(this.allStages, stage => {
                                stage.total = _.sumBy(stage.elements, (element: IElement) => {
                                    return _.sumBy(element.scheduleTasks || [], (scheduleTask: IScheduleTask) =>
                                        scheduleTask.markedAsDeleted ? 0 : scheduleTask.taskTotal.total
                                    );
                                });
                            });

                            _.each(this.nativeScheduleAreas, (scheduleArea: IScheduleArea) => {
                                scheduleArea['stages'] = _.filter(this.allStages, (stage: IStage) => {
                                    return true;
                                });

                                _.each(scheduleArea['stages'], (stage: IStage) => {
                                    stage._quoterTotals = stage._quoterTotals || {};
                                    stage._quoterTotals[scheduleArea.id] = this.calculateStageFilteredByAreaTotalService.calculate(
                                        stage,
                                        scheduleArea
                                    );
                                });
                            });

                            this.scheduleEventsService.stageQuoterTotalModified();

                            // highlight schedule tasks
                            const highlightTasksIds = this._highlightTasksStorageService.retrieve(this.project.id);
                            highlightTasksIds.forEach((scheduleTaskId) => {
                                const scheduleTaskToHighlight = this.getAllScheduleTasks().find((st) => st.id === scheduleTaskId);
                                if (scheduleTaskToHighlight) {
                                    scheduleTaskToHighlight.highlight = true;
                                }
                            });

                            this.scheduleEventsService.loadingFinished(true);
                        });

                    this.inProcessLoadingAllStages = false;
                    resolve();
                });
        });
    }

    private addAllStagesAndElementsEvenIfTheyDoesNotContainScheduleTasks(stages: IStage[]): IStage[] {
        let resultStages = _.map(stages, (stage: IStage) => {
            const globalStage = _.find(this.globalStages, { id: stage.id });
            _.each(globalStage.elements, el => {
                if (!_.find(stage.elements, { id: el.id })) {
                    stage.elements.push(_.cloneDeep(el));
                }
            });

            stage.elements = _.sortBy(stage.elements, 'elementOrder');
            _.each(stage.elements, (element: IElement) => {
                element.id = element.id || -1;
                element.element = element.element || 'Requested Task';
            });

            return stage;
        });

        _.each(this.globalStages, globalStage => {
            const stage = _.find(resultStages, { id: globalStage.id });

            if (stage == null) {
                resultStages.push(_.cloneDeep(globalStage));
            }
        });

        resultStages = _.sortBy(resultStages, 'stageOrder');

        return resultStages;
    }

    private applyMainViewFilter(): void {
        const result: IMainFilterApplyingToScheduleResult = this.applyMainFilterToScheduleService.apply(
            this.nativeScheduleAreas,
            this.allStages,
            this.filterState,
            this.scheduleAreaItems,
            this.stageItems,
            this.cssElementItems,
            this.buildUpItems
        );

        this.filteredScheduleAreas = result.scheduleAreas;
        this.stages = result.stages;
        this.cssElementMap = result.cssElementMap;
        this.buildUpMapSubject.next(result.buildUpMap);
    }

    private save(): void {
        this.inProcessGlobalSaving = true;

        this.taskUpdateHelper
            .globalSaveSchedule(this.project.id, false)
            .pipe(
                finalize(() => {
                    this.inProcessGlobalSaving = false;
                })
            )
            .subscribe((res: boolean) => {
                if (res) {
                    this.alertService.success('Save successful');
                    this.reloadAllSavingCurrentScrollPosition();
                } else {
                    this.alertService.error('Error during saving schedule!');
                }
            });
    }

    private reloadAll(): Promise<void> {
        this.allStages = null;
        this.stages = null;
        this.inProcessReloadAll = true;

        return new Promise(resolve => {
            // reload project to get updated project version
            this.updateProject().then(() => {
                this.itemsLoaderService.loadItems(
                    this.project.id,
                    false && !!this.account.beta,
                    !!this.account.beta)
                    .subscribe((res: ItemsLoaderResult) => {
                        this.nativeScheduleAreas = res.scheduleAreas;
                        this.scheduleAreaItems = res.scheduleAreaItems;
                        this.stageItems = res.stageItems;
                        this.stageItemsInUse = res.stageItems;
                        this.cssElementItems = res.cssElementItems;
                        this.buildUpItems = res.buildUpItems;

                        try {
                            this._filterState = this.mainFilterInitialStateStorageService.retrieve(
                                this.project.id,
                                'schedule'
                            );
                        } catch (e) {
                            console.warn('Cannot restore main filter view from local storage');
                        }

                        this.loadAllStages().then(() => {
                            this.updateStageItems();

                            this.applyMainViewFilter();
                            this.restoreExpandState();

                            setTimeout(() => {
                                this.bpStore.dataRetrieved = true;
                            }, 700);

                            this.inProcessReloadAll = false;
                            resolve();
                        });
                    });
            });
        });
    }

    private onScheduleTaskModification(event: IScheduleTaskModificationEvent): void {
        this.clearHighlights();

        const upd = (scheduleTasks: IScheduleTask[]) => {
            this.inProcessScheduleTaskModification = true;

            this.taskUpdateHelper
                .updateScheduleTasks(this.project.id, scheduleTasks, this.getAllScheduleTasks(), this.getDefaultQuoterId())
                .finally(() => {
                    this.inProcessScheduleTaskModification = false;
                })
                .then(() => {
                    this.updateProject();
                });
        }

        if (this.bulkUpdateMode && ['clientSupplied', 'material', 'task'].includes(event.updatedProperty)) {
            const updTaskId = event.updatedProperty === 'task' ? event.scheduleTask.oldTaskId : event.scheduleTask.taskId;
            const updTaskName = event.updatedProperty === 'task' ? event.scheduleTask.oldTaskName : event.scheduleTask.task;

            const similarTasks = this.getAllScheduleTasks().filter(sh => {
                return sh.taskId === updTaskId || sh.id === event.scheduleTask.id;
            });

            if (similarTasks.length > 1 && !this.bulkModeStorageService.retrieveDoNotShowAgainForTheTask(this.project.id, updTaskId)) {
                this.showUpdateAllSimilarTasks(updTaskName, similarTasks.length).then((res: any) => {
                    if (res?.doNotShowAgain) {
                        this.bulkModeStorageService.storeDoNotShowAgainForTheTask(this.project.id, updTaskId, res?.doNotShowAgain);
                    }

                    if (res) {
                        switch (event.updatedProperty) {
                            case 'material': {
                                similarTasks.forEach((task) => {
                                    task.primeMaterialId = event.scheduleTask.primeMaterialId;
                                    task.materialUrlRef = event.scheduleTask.materialUrlRef;
                                });
                                break;
                            }

                            case 'task': {
                                similarTasks.forEach((task) => {
                                    task.taskId = event.scheduleTask.taskId;
                                    task.task = event.scheduleTask.task;
                                    task.displayName = event.scheduleTask.task;
                                    task.componentAreaId = event.scheduleTask.componentAreaId;
                                    task.unitId = event.scheduleTask.unitId;
                                    task.unit = event.scheduleTask.unit;
                                    task.unitPlural = event.scheduleTask.unitPlural;
                                    task.materialCategories = event.scheduleTask.materialCategories;
                                    task.primeMaterialId = event.scheduleTask.primeMaterialId;
                                });
                                break;
                            }

                            case 'clientSupplied': {
                                similarTasks.forEach((task) => {
                                    task.primeMaterialCostAvailable = event.scheduleTask.primeMaterialCostAvailable;
                                    task.primeMaterialCost = event.scheduleTask.primeMaterialCost;
                                });
                                break;
                            }
                        }

                        upd(similarTasks);
                    }
                }, reason => {
                    upd([event.scheduleTask]);
                });
            } else {
                upd([event.scheduleTask]);
            }
        } else {
            upd([event.scheduleTask]);
        }
    }

    private getDefaultQuoterId(): number | null {
        return this.project.defaultQuoter != null ? this.project.defaultQuoter.id : null;
    }

    private addScheduleTask(stage: IStage, element: IElement, area: IArea, task: ITask): void {
        this.addItemToScheduleService
            .addScheduleTask(this.project, this.globalStages, stage, element, area || this.scheduleAreaItems[0], task)
            .then((newScheduleTask: IScheduleTask) => {
                if (!newScheduleTask) {
                    return;
                }

                newScheduleTask.projectId = this.project.id;

                this.defaultPricesUpdaterService.fillDefaultPrices(this.project, newScheduleTask).then(() => {
                    this.addTaskToStageAndLoadedTasks(stage, element, newScheduleTask, true);

                    const fillDefaultPrice: IScheduleTaskQuoterTotalModificationEvent = {
                        stageId: newScheduleTask.stageId
                    };
                    this.scheduleEventsService.scheduleTaskTotalModified(fillDefaultPrice);

                    //stage.expanded = true;

                    if (area) {
                        // area.expanded = true;
                    }

                    setTimeout(() => {
                        const justAddedScheduleTask = this.getAllScheduleTasks().find((scheduleTask) => scheduleTask.highlight);
                        this.scrollToTask(justAddedScheduleTask);
                    }, 2000);
                });
            });
    }

    private scrollToTask(scheduleTask: IScheduleTask): void {
        if (scheduleTask != null) {
            const scheduleTaskId = `schedule-task-row_${scheduleTask?.id}`;
            const scheduleTaskDiv = document.getElementById(scheduleTaskId);
            scheduleTaskDiv.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
            return;
        }
    }

    private restoreExpandState(): void {
        /*switch (this.filterState.groupBy) {
            case 'area':
            case 'area_room':
                _.each(this.filteredScheduleAreas, (area: IArea) => {
                    area.expanded = this.expandAreaGroupStorageService.retrieve(this.project.id, area.id);

                    _.each(area['stages'], (stage: IStage) => {
                        stage.expanded = this.expandStageAreaGroupStorageService.retrieve(this.project.id, stage.id, area.id);
                    });
                });
                break;
            case 'stage':
            case 'stage_room':
                _.each(this.stages, (stage: IStage) => {
                    stage.expanded = this.expandStagesStorageService.retrieve(this.project.id, stage.id);
                });
                break;
        }*/
    }

    private addTaskToStageAndLoadedTasks(stage: IStage, element: IElement, scheduleTask: IScheduleTask, syncWithServer = true) {
        if (element == null) {
            // seems impossible case for now
            const newElementCopy: IElement = {
                id: null,
                element: ''
            };
            newElementCopy.scheduleTasks = newElementCopy.scheduleTasks || [];
            newElementCopy.scheduleTasks.push(scheduleTask);
            stage.elements.push(newElementCopy);
        } else {
            const elementIndex = _.findIndex(stage.elements, { id: element.id });

            if (elementIndex === -1) {
                // seems that for now it is impossible case
                const newElementCopy: IElement = Object.assign({}, element);
                newElementCopy.scheduleTasks = newElementCopy.scheduleTasks || [];
                newElementCopy.scheduleTasks.push(scheduleTask);
                stage.elements.push(newElementCopy);
            } else {
                const st = stage.elements[elementIndex];
                st.scheduleTasks = st.scheduleTasks || [];
                st.scheduleTasks.push(scheduleTask);
            }
        }

        if (!syncWithServer) {
            return;
        }

        if (scheduleTask.newTaskRequestId == null) {
            this.saveScheduleTasksToDatabase([scheduleTask], false);
        } else {
            this.scheduleEventsService.scheduleTaskTotalModified({
                stageId: scheduleTask.stageId
            });
        }
    }

    private saveScheduleTasksToDatabase(scheduleTasks: IScheduleTask[], reloadAll: boolean): void {
        this.inProcessAddingTaskToStageAndLoadingTasks = true;

        this.clearHighlights();
        const highlightTasksValue: number[] = [];

        this.taskUpdateHelper
            .updateScheduleTasks(this.project.id, scheduleTasks, this.getAllScheduleTasks(), this.getDefaultQuoterId())
            .finally(() => {
                this.inProcessAddingTaskToStageAndLoadingTasks = false;
            })
            .then(() => {
                if (reloadAll) {
                    scheduleTasks.forEach(scheduleTask => {
                        highlightTasksValue.push(scheduleTask.id);

                        if (!this.stageItems.find(si => si.id === scheduleTask.stageId)) {
                            this.stageItems.push({ id: scheduleTask.stageId, title: scheduleTask.stage, order: 0 })
                        }
                    })

                    this._highlightTasksStorageService.store(this.project.id, highlightTasksValue);
                    this.updateStageItems();

                    this.reloadAllSavingCurrentScrollPosition();
                } else {
                    this.updateProject();
                }
            });
    }

    private updateProject(): Promise<void> {
        return lastValueFrom(this.projectApi.find(this.project.id)).then((res: HttpResponse<IProject>) => {
            this.project = res.body;
        });
    }

    private updateStageItems(): void {
        switch (this.showSubStagesMode) {
            case 'display-all':
                this.stageItems = this.allStageItems;
                break;
            case 'show-in-use':
            case 'hide-all':
                this.stageItems = this.stageItemsInUse;
                break;
        }
    }

    private showUpdateAllSimilarTasks(taskName: string, countOfSimilarTasks: number): Promise<boolean> {
        const textHtml = `
                          <div class='flex flex-column gap-20'>
                                <div class='strong'>
                                We've detected ${countOfSimilarTasks} tasks named '${taskName}'
                               </div>
                               <div class='strong'>
                                    Would you like to make this change to all of them?
                               </div>
                           </div>`;
        return this.confirmModalService.open(
            {
                header: 'Update all similar tasks',
                textHtml,
                cancelButtonText: 'No, this task only',
                confirmButtonText: 'Update similar tasks',
                swapButtons: false,
                showDoNotShowAgain: true,
                showDoNotShowAgainText: `Don't show again for this task`,
                showDoNotShowAgainPlace: 'bottom'
            }
        ).result;
    }

    private clearHighlights(): void {
        this._highlightTasksStorageService.clear(this.project.id);

        this.getAllScheduleTasks().forEach((scheduleTask) => {
            scheduleTask.highlight = false;
        });
    }
}
