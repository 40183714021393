<div class="card">
        <div class="header flex flex-row justify-content-between">
            <h1 class="component-area-label">
                Component Areas
            </h1>

            <button [routerLink]="['/component-area/new']"
                    class="btn btn-primary btn-border-radius waves-effect create-component-area">
                Create new Component Area
            </button>
        </div>
        <div *ngIf="componentAreas"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="componentArea">
                        <span>NAME</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="unit">
                        <span>UNIT</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let componentArea of componentAreas; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/component-area', componentArea.id, 'edit' ]">
                            {{componentArea.id}}
                        </a>
                    </td>
                    <td>
                        {{componentArea.componentArea}}
                    </td>
                    <td>
                        {{componentArea.unit?.unit }}
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div role="group"
                             class="bp-btn-group">
                            <button [routerLink]="['/component-area', componentArea.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                [routerLink]="['/', { outlets: { popup: 'component-area/'+ componentArea.id + '/delete'} }]"
                                class="btn btn-danger waves-effect"
                                queryParamsHandling="merge"
                                replaceUrl="true"
                                type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>


