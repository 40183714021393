import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IScopesHeader } from 'app/shared/model/bp.model';
import { FormControl } from '@angular/forms';
import { ScopesService } from 'app/flows/scheduler/scopes/services/scopes.service';
import { ScopesStore } from 'app/flows/scheduler/scopes/stores/scopes.store';
import { Subscription } from "rxjs";
import Swal from "sweetalert2";

@Component({
    selector: 'bp-scope-header',
    templateUrl: './scope-header.component.html',
    styleUrls: ['scope-header.scss', './../../scopes.scss']
})
export class ScopeHeaderComponent implements OnInit, OnDestroy {
    @Input() scopesHeader: IScopesHeader | null;

    @ViewChild('scopeHeaderInput')
    protected input: ElementRef;

    protected scopesHeaderControl = new FormControl();

    protected subscriptions = Subscription.EMPTY;

    constructor(
        private service: ScopesService,
        private store: ScopesStore
    ) {
    }

    ngOnInit(): void {
        this.scopesHeaderControl.setValue(this.scopesHeader?.content);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    protected applyChanges(event: any): void {
        event.preventDefault();
        const term = event.target.value;

        if (!term.length) {
            return;
        }

        if (this.scopesHeader == null) {
            this.service.createScopeHeader(term).then((res) => {
                res.scopes = [];
                this.store.headersWithScopes.push(res);
                this.scopesHeaderControl.setValue('');

                setTimeout(() => {
                    this.store.focusUpdatedSubject.next({ scopeHeader: res, scope: null });
                });
            });
        } else {
            this.service.updateScopeHeader(this.scopesHeader.id, term).then((res) => {
                this.scopesHeader = res;
            });
        }
    }

    protected delete(): void {
        this.service.deleteScopeHeader(this.scopesHeader?.id as number).then(() => {
            this.service.refresh();
        });
    }

    protected showDeleteConfirmation(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this Scope Header and all its scopes?',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            },
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                // User clicked "Delete"
                this.delete(); // Call the delete function
            }
        });
    }
}
