<div class="card">
        <div class="header">
            <div class="row">
                <div class="col-lg-5 left">
                    <h1 class="build-up-category-label">
                        Build Up Categories
                    </h1>
                    <bp-archive-filter (onValueChanged)="onArchiveFilterValueChanged($event)"
                                       [defaultValue]="archiveFilterValue">
                    </bp-archive-filter>
                </div>
                <div class="col-lg-7 right">
                    <input [formControl]="searchControl"
                           class="af-input"
                           id="build-up-category-prefix"
                           name="build-up-category-prefix"
                           placeholder="Search build-up category"
                           type="text"/>
                    <button [routerLink]="['/build-up-category/new']"
                            class="btn btn-primary btn-border-radius waves-effect create-build-up-category pull-right">
                        Create new Build Up Category
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="items"
             class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr [(ascending)]="reverse"
                    [(predicate)]="predicate"
                    [callback]="reset.bind(this)"
                    bpSort>
                    <th bpSortBy="id">
                        <span>ID</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="name">
                        <span>NAME</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th bpSortBy="order">
                        <span>ORDER</span>
                        <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of items; trackBy: trackId">
                    <td>
                        <a [routerLink]="['/build-up-category', item.id, 'edit' ]">
                            {{item.id}}
                        </a>
                    </td>
                    <td>
                        {{item.name}}
                    </td>
                    <td>
                        {{item.order}}
                    </td>
                    <td class="text-right"
                        style="min-width: 180px">
                        <div role="group"
                             class="bp-btn-group">
                            <button [routerLink]="['/build-up-category', item.id, 'edit']"
                                    class="btn btn-primary waves-effect"
                                    type="submit">
                                <i class="material-icons">edit</i>
                            </button>
                            <button
                                [routerLink]="['/', { outlets: { popup: 'build-up-category/'+ item.id + '/delete'} }]"
                                class="btn btn-danger waves-effect"
                                queryParamsHandling="merge"
                                replaceUrl="true"
                                type="submit">
                                <i class="material-icons">delete_forever</i>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr [hidden]="!hasMore()"
                    class="bg-white">
                    <td class="text-center"
                        colspan="1000">
                        <button (click)="loadPage(page + 1)"
                                class="btn btn-light">
                            More
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>


