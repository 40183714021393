import { IProjectAttachmentAdditionProperties } from 'app/shared/model/project-attachment.model';
import { Injectable } from '@angular/core';
import { TemplateWizardData } from 'app/flows/scheduler/template-wizard/template-wizard.data';

export interface ITemplateRequestData {
    name?: string;
    description?: string;

    attachments: {
        file: File,
        additionalProperties: IProjectAttachmentAdditionProperties;
    }[];
}

@Injectable({ providedIn: 'root' })
export class TemplateWizardStore {
    data: TemplateWizardData;
    templateRequestDataArray: ITemplateRequestData[];

    constructor(
    ) {
    }

    init(): void {
        this.templateRequestDataArray = [];
    }
}
