<!-- Top Bar -->
<nav class="navbar">
    <div class="navbar-inner">
        <div class="navbar__container container-fluid flex flex-row gap-10 justify-content-between">
            <div class="navbar-header flex-1">
                <a class="collapsed collapse-button d-md-none"
                   type="button"
                   (click)="isNavbarCollapsed = !isNavbarCollapsed">
                    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                </a>
                <a (click)="goHome()"
                   class="navbar-brand">
                    <img alt="BuildPartner"
                         height="45"
                         [src]="logo">

                    <div *ngIf="showProjectNav && topMenuStateService.project"
                         class="project-name"
                         [title]="topMenuStateService.project.address">
                        {{ topMenuStateService.project.address }}
                    </div>
                </a>
            </div>

            <ul class="nav navbar-nav center-nav d-none d-md-block"
                *ngIf="showProjectNav">
                <bp-project-nav
                    [creationMode]="this.creationMode">
                </bp-project-nav>
            </ul>

            <div *ngIf="!isNavbarCollapsed"
                 class="d-md-none"
                 id="mobileNavbar">
                <ng-container *ngIf="showProjectNav">
                    <bp-project-nav
                        [creationMode]="this.creationMode">
                    </bp-project-nav>

                    <hr/>
                </ng-container>

                <ng-container *bpHasAnyAuthority="'ROLE_ADMIN'">
                    <div class="ml-3">
                        ENTITIES
                    </div>
                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="area"
                       routerLinkActive="active">
                        Area
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="element"
                       routerLinkActive="active">
                        Element
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="css-element"
                       routerLinkActive="active">
                        CSS Element
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="component-area"
                       routerLinkActive="active">
                        Component Areas
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="material"
                       routerLinkActive="active">
                        Material
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="material-category"
                       routerLinkActive="active">
                        Material Categories
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="spec"
                       routerLinkActive="active">
                        Spec
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="task"
                       routerLinkActive="active">
                        Task
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="stage"
                       routerLinkActive="active">
                        Stage
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="trade"
                       routerLinkActive="active">
                        Trade
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="unit"
                       routerLinkActive="active">
                        Unit
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="tag"
                       routerLinkActive="active">
                        Tag
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="profession"
                       routerLinkActive="active">
                        Profession
                    </a>

                    <a [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link"
                       routerLink="region"
                       routerLinkActive="active">
                        Region
                    </a>

                    <hr/>

                    <div class="ml-3">
                        ADMINISTRATION
                    </div>

                    <a class="nav-link"
                       routerLink="admin/user-management"
                       routerLinkActive="active">
                        User management
                    </a>

                    <a class="nav-link"
                       routerLink="admin/new-task-request"
                       routerLinkActive="active">
                        New task request
                    </a>

                    <hr/>
                </ng-container>

                <a class="nav-link"
                   routerLink="settings">
                    Profile
                </a>

                <a class="nav-link"
                   routerLink="password">
                    Change Password
                </a>

                <a class="nav-link"
                   *ngIf="shouldShowUpgrade"
                   [href]="BOOK_A_DEMO_URL"
                   role="button"
                   target="_blank"
                   rel="noopener">
                    Book a Demo
                </a>

                <a class="nav-link"
                   *ngIf="shouldShowUpgrade"
                   [href]="BUILD_PARTNER_SUBSCRIBE_URL"
                   role="button"
                   target="_blank"
                   rel="noopener">
                    Upgrade
                </a>

                <hr/>

                <a class="nav-link"
                   href
                   (click)="logout()">
                    Sign out
                </a>
            </div>

            <div [ngSwitch]="authenticated"
                 class="collapse navbar-collapse flex-1"
                 id="navbar-collapse">
                <ul class="nav navbar-nav navbar-right">
                    <li *bpHasAnyAuthority="'ROLE_ADMIN'"
                        ngbDropdown>
                        <a ngbDropdownToggle
                           data-toggle="dropdown"
                           href="javascript:void(0);"
                           role="button">
                            <i class="material-icons">list</i>
                            <span class="label-count bg-orange">9</span>
                        </a>
                        <ul ngbDropdownMenu class="pullDown">
                            <li class="header">ENTITIES</li>
                            <li class="body">
                                <ul class="menu">
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="area"
                                           routerLinkActive="active">
                                            Area
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="element"
                                           routerLinkActive="active">
                                            Element
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="css-element"
                                           routerLinkActive="active">
                                            CSS Elements
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="build-up"
                                           routerLinkActive="active">
                                            Build Ups
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="build-up-category"
                                           routerLinkActive="active">
                                            Build Up Categories
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="component-area"
                                           routerLinkActive="active">
                                            Component Areas
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="material"
                                           routerLinkActive="active">
                                            Material
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="material-category"
                                           routerLinkActive="active">
                                            Material Categories
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="spec"
                                           routerLinkActive="active">
                                            Spec
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="task"
                                           routerLinkActive="active">
                                            Task
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="stage"
                                           routerLinkActive="active">
                                            Stage
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="trade"
                                           routerLinkActive="active">
                                            Trade
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="unit"
                                           routerLinkActive="active">
                                            Unit
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="tag"
                                           routerLinkActive="active">
                                            Tag
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="profession"
                                           routerLinkActive="active">
                                            Profession
                                        </a>
                                    </li>

                                    <li>
                                        <a [routerLinkActiveOptions]="{ exact: true }"
                                           class="dropdown-item"
                                           routerLink="region"
                                           routerLinkActive="active">
                                            Region
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li *bpHasAnyAuthority="'ROLE_ADMIN'"
                        ngbDropdown>
                        <a ngbDropdownToggle
                           href="javascript:void(0);"
                           role="button">
                            <i class="material-icons">list</i>
                            <span class="label-count bg-green">6</span>
                        </a>
                        <ul ngbDropdownMenu
                            class="pullDown">
                            <li class="header">ADMINISTRATION</li>
                            <li class="body">
                                <ul class="menu">
                                    <li>
                                        <a class="nav-link"
                                           routerLink="admin/user-management"
                                           routerLinkActive="active">
                                            User management
                                        </a>
                                    </li>
                                    <li>
                                        <a class="nav-link"
                                           routerLink="admin/new-task-request"
                                           routerLinkActive="active">
                                            New task request
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li *bpHasAnyAuthority="'ROLE_SCHEDULER'" class="buttons">
                        <button (click)="onReviewByQSClick()"
                                class="d-none d-md-inline btn btn-primary btn-border-radius m-r-10"
                                ngbTooltip="Request QS Support"
                                type="button">
                            Request QS
                        </button>

                        <button (click)="onReviewByQSClick()"
                                ngbTooltip="Request QS Support"
                                class="d-md-none btn btn-secondary btn-circle review-by-qs m-r-10">
                            <i class="material-icons md-24">
                                apps
                            </i>
                        </button>
                    </li>

                    <li class="docs-link">
                        <a [href]="DOCS_URL"
                           role="button"
                           target="_blank"
                           rel="noopener">
                            <span>?</span>
                        </a>
                    </li>

                    <li class="separator">
                    </li>

                    <li ngbDropdown
                        class="user_profile">
                        <a ngbDropdownToggle
                           type="button"
                           href="javascript:void(0);"
                           style="margin-top: 13px;"
                           role="button">
                            <div *ngIf="accountService.isIdentityResolved()"
                                 class="flex align-items-center gap-5px">
                                <div class="initials"
                                     [title]="name"
                                     *ngIf="(!accountService.getImageUrl() || accountService.isReplaceBPLogo()) else showAccountIcon">
                                    {{ initials }}
                                </div>

                                <ng-template #showAccountIcon>
                                    <div class="inline-flex align-items-center">
                                        <div
                                            [ngStyle]="{'background-image': 'url(' + accountService.getImageUrl() + ')'}"
                                            class="account-pic-container">
                                        </div>
                                        <span class="login"
                                              [title]="name">{{ initials }}</span>
                                    </div>
                                </ng-template>

                                <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
                            </div>
                        </a>
                        <ul ngbDropdownMenu
                            class="pullDown">
                            <li class="body">
                                <ul class="user_dw_menu">
                                    <ng-container *ngIf="!accountService.isHomeowner()">
                                        <li *ngSwitchCase="true">
                                            <a class="nav-link"
                                               routerLink="settings"
                                               routerLinkActive="active">
                                                Settings
                                            </a>
                                        </li>
                                        <li *ngSwitchCase="true">
                                            <a class="nav-link"
                                               routerLink="export-settings"
                                               routerLinkActive="active">
                                                Export Settings
                                            </a>
                                        </li>
                                        <li *ngSwitchCase="true">
                                            <a class="nav-link"
                                               routerLink="password"
                                               routerLinkActive="active">
                                                Change Password
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_QUOTER'">
                                            <a class="nav-link"
                                               routerLink="quoter/material-rates"
                                               routerLinkActive="active">
                                                Material Rates
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_QUOTER'">
                                            <a class="nav-link"
                                               routerLink="quoter/labour-rates"
                                               routerLinkActive="active">
                                                Labour Rates
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_QUOTER'">
                                            <a class="nav-link"
                                               routerLink="quoter/task-labour-rates"
                                               routerLinkActive="active">
                                                Task Rates
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_ADMIN'">
                                            <a class="nav-link"
                                               routerLink="admin/payment"
                                               routerLinkActive="active">
                                                Payments
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_ADMIN'">
                                            <a class="nav-link"
                                               routerLink="admin/template-request"
                                               routerLinkActive="active">
                                                Template Requests
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_ADMIN'">
                                            <a class="nav-link"
                                               routerLink="admin/new-material-request"
                                               routerLinkActive="active">
                                                New Material Requests
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_ADMIN'">
                                            <a class="nav-link"
                                               routerLink="admin/project-quoter-request"
                                               routerLinkActive="active">
                                                Project Quoter Requests
                                            </a>
                                        </li>
                                        <li *bpHasAnyAuthority="'ROLE_ADMIN'">
                                            <a class="nav-link"
                                               routerLink="admin/heard-about-us"
                                               routerLinkActive="active">
                                                Heard About Us
                                            </a>
                                        </li>
                                        <li *ngSwitchCase="shouldShowUpgrade">
                                            <a class="nav-link"
                                               [href]="BOOK_A_DEMO_URL"
                                               role="button"
                                               target="_blank"
                                               rel="noopener">
                                                Book a Demo
                                            </a>
                                        </li>
                                        <li *ngSwitchCase="shouldShowUpgrade">
                                            <a class="nav-link"
                                               [href]="BUILD_PARTNER_SUBSCRIBE_URL"
                                               role="button"
                                               target="_blank"
                                               rel="noopener">
                                                Upgrade
                                            </a>
                                        </li>
                                    </ng-container>
                                    <li *ngSwitchCase="true">
                                        <a (click)="logout()"
                                           class="dropdown-item"
                                           id="logout">
                                            Sign Out
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
