<img alt=""
     class="login-logo"
     src="../../../../content/images/bp_logo.png"/>

<div class="wrap-login">
    <div *ngIf="keyMissing"
         class="alert alert-danger">
        <strong>
            The password reset key is missing.
        </strong>
    </div>

    <div *ngIf="error"
         class="alert alert-danger">
        <span>
            Your password couldn't be reset. Remember a password request is only valid for 24 hours.
        </span>
    </div>

    <div *ngIf="doNotMatch"
         class="alert alert-danger">
        The password and its confirmation do not match!
    </div>

    <ng-container *ngIf="success">
        <form
            class="form"
            name="form"
            role="form"
            autocomplete="off">
            <span class="form-title">Password Reset</span>

            <button [routerLink]="['/']"
                    class="btn btn-link go-to-login"
                    type="button">
                <i class="material-icons md-24">close</i>
            </button>

            <div class="m-t-10 text-center gray">
                Your password has been reset.<br>Please return to the login page to sign in.
            </div>

            <div class="text-center m-t-40">
                <button class="btn-primary btn-border-radius btn"
                        type="button"
                        [routerLink]="['/']">
                    Back to Login
                </button>
            </div>
        </form>
    </ng-container>

    <div *ngIf="!keyMissing && !success">
        <form #passwordForm="ngForm"
              (ngSubmit)="finishReset()"
              class="form"
              name="form"
              role="form">
            <span class="form-title">Choose a new password</span>

            <button [routerLink]="['/']"
                    class="btn btn-link go-to-login"
                    type="button">
                <i class="material-icons md-24">close</i>
            </button>

            <div class="form-group">
                <label for="password">
                    New password
                </label>
                <input #passwordInput="ngModel"
                       [(ngModel)]="resetAccount.password"
                       class="form-control"
                       id="password"
                       maxlength=50
                       minlength=4
                       name="password"
                       required
                       type="password">
                <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                    <small *ngIf="passwordInput.errors.required"
                           class="form-text text-danger">
                        Your password is required.
                    </small>
                    <small *ngIf="passwordInput.errors.minlength"
                           class="form-text text-danger">
                        Your password is required to be at least 4 characters.
                    </small>
                    <small *ngIf="passwordInput.errors.maxlength"
                           class="form-text text-danger">
                        Your password cannot be longer than 50 characters.
                    </small>
                </div>
             </div>

            <div class="form-group">
                <label for="confirmPassword">
                    New password confirmation
                </label>
                <input #confirmPasswordInput="ngModel"
                       [(ngModel)]="confirmPassword"
                       class="form-control"
                       id="confirmPassword"
                       maxlength=50
                       minlength=4
                       name="confirmPassword"
                       required
                       type="password">
                <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                    <small *ngIf="confirmPasswordInput.errors.required"
                           class="form-text text-danger">
                        Your password confirmation is required.
                    </small>
                    <small *ngIf="confirmPasswordInput.errors.minlength"
                           class="form-text text-danger">
                        Your password confirmation is required to be at least 4 characters.
                    </small>
                    <small *ngIf="confirmPasswordInput.errors.maxlength"
                           class="form-text text-danger">
                        Your password confirmation cannot be longer than 50 characters.
                    </small>
                </div>
            </div>

            <div class="text-center m-t-40">
                <button [disabled]="passwordForm.form.invalid"
                        class="btn btn-primary"
                        type="submit">
                    Reset Password
                </button>
            </div>
        </form>
    </div>
</div>
