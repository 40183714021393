<div class="main-fixed-container">
        <bp-valuations-table>
        </bp-valuations-table>

        <ng-container *ngIf="store.project.status !== 'Quote Accepted' && !store.valuationResponse">
            <div class="no-valuations">
                <div>
                    There are currently no valuations for this project
                </div>

                <button (click)="onCreateValuationClick()"
                        ngbTooltip="Create valuation"
                        class="btn btn-primary btn-border-radius"
                        type="button">
                    Create valuation
                </button>
            </div>
        </ng-container>
    </div>

