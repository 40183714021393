import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IScope, IScopesHeader } from 'app/shared/model/bp.model';
import { ScopesService } from 'app/flows/scheduler/scopes/services/scopes.service';
import { IScopeFocusUpdateEvent, ScopesStore } from 'app/flows/scheduler/scopes/stores/scopes.store';
import { Subscription } from 'rxjs';
import Swal from "sweetalert2";

@Component({
    selector: 'bp-scope',
    templateUrl: './scope.component.html',
    styleUrls: ['scope.scss', './../../scopes.scss']
})
export class ScopeComponent implements OnInit, OnDestroy {
    @Input() scopeHeader: IScopesHeader;
    @Input() scope: IScope;

    protected scopeContentControl = new FormControl();

    @ViewChild('scopeInput')
    protected input: ElementRef;

    protected focusUpdatedSub = Subscription.EMPTY;

    constructor(
        private service: ScopesService,
        private store: ScopesStore
    ) {
    }


    ngOnInit(): void {
        this.scopeContentControl.setValue(this.scope?.content);
        this.focusUpdatedSub = this.store.focusUpdated$.subscribe((event: IScopeFocusUpdateEvent) => {
            if (event.scopeHeader?.id === this.scopeHeader?.id) {
                if (event.scope?.id == this.scope?.id) {
                    this.input.nativeElement.focus();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.focusUpdatedSub.unsubscribe();
    }

    protected applyChanges(event: any): void {
        event.preventDefault();

        const term = event.target.value;
        if (!term.length) {
            return;
        }

        if (this.scope == null) {
            this.service.createScope(this.scopeHeader.id, term, 0).then((res) => {
                this.scopeHeader.scopes.push(res);
                this.scopeContentControl.setValue('');

                setTimeout(() => {
                    this.store.focusUpdatedSubject.next({ scopeHeader: this.scopeHeader, scope: null });
                });
            });
        } else {
            this.service.updateScope(this.scope.id, term, this.scope.amount).then((res) => {
                this.scope = res;
            });
        }
    }

    protected delete(): void {
        this.service.deleteScope(this.scope?.id as number).then(() => {
            this.service.refresh();
        });
    }

    protected showDeleteConfirmation(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this Scope?',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            },
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                // User clicked "Delete"
                this.delete(); // Call the delete function
            }
        });
    }
}
