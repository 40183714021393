import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IMaterialsWrapper, QuoterService } from 'app/shared/dataservices/quoter.service';
import { IBuilderMaterial } from 'app/shared/model/builder-material.model';
import {
    IMaterialRatesDataModel,
    MaterialRatesDataModel
} from 'app/flows/quoter/material-rates/material-rates-data-model.model';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ITEMS_PER_PAGE } from 'app/shared/constants/pagination.constants';
import { AccountService } from 'app/core/auth/account.service';
import { DEFAULT_MATERIAL_RATES_HELP_URL } from 'app/shared/constants/links.constants';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import { SuccessModalService } from 'app/shared/components/common/success-modal/success-modal.service';
import Swal from "sweetalert2";

const DEFAULT_MATERIAL_RATES_HELP_TEXT =
    `<h2 style='text-align: center; margin-top: 10px'>What are default material rates?</h1>` +
    `<div class="strong font-14 m-t-30" style='text-align: justify;'>` +
    `<div class="m-b-10">Default material rates are material rates applied automatically to new quotes. Existing quotes will be unaffected by any changes made on this page.</div>` +
    `<div class="m-b-10">New quotes = The project has just appeared on your account for quotation.</div>` +
    `<div class="m-b-10">To update an existing projects rates, navigate to that project and use the project rates feature.</div>` +
    `<a class="link underlined" href='${DEFAULT_MATERIAL_RATES_HELP_URL}' target='_blank'>Learn more</a>` +
    `</div>`;

/**
 * Default material rates
 */
@Component({
    selector: 'bp-quoter-materials',
    templateUrl: './material-rates.component.html',
    styleUrls: ['material-rates.scss']
})
export class MaterialRatesComponent implements OnInit, OnDestroy {

    searchControl = new FormControl();
    searchSub = Subscription.EMPTY;

    dataModel: IMaterialRatesDataModel;

    isAdmin = false;
    isGlobal = false;

    predicate: string;
    reverse: boolean;
    itemsPerPage: any;
    page: number;
    totalItems: number;
    previousPage: any;

    inProcessLoadingMaterials = false;
    inProcessSavingMaterials = false;
    inProcessRefreshCosts = false;
    inProcessApplyingMarginAll = false;

    defaultMaterialRatesHelpText = DEFAULT_MATERIAL_RATES_HELP_TEXT;

    constructor(
        public accountService: AccountService,
        private quoterService: QuoterService,
        private alertService: BpAlertService,
        private confirmModalService: ConfirmModalService,
        private successModalService: SuccessModalService
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.dataModel = new MaterialRatesDataModel();
        this.predicate = 'id';
    }

    ngOnInit(): void {
        this.isAdmin = this.accountService.isAdmin();
        this.loadPage(1);

        this.searchSub = this.searchControl.valueChanges
            .pipe(debounceTime(700), distinctUntilChanged())
            .subscribe((term: string) => {
                this.reset();
            });
    }

    ngOnDestroy(): void {
        this.searchSub?.unsubscribe();
    }

    loadPage(page: number): void {
        this.page = page;

        if (page !== this.previousPage) {
            this.previousPage = page;

            this.inProcessLoadingMaterials = true;

            const query: any = {
                page: this.page - 1,
                size: this.itemsPerPage,
                sort: this.sort()
            };

            if (this.searchControl.value) {
                query.searchBy = 'material';
                query.searchValue = this.searchControl.value;
            }

            this.quoterService
                .queryAvailableMaterials(query)
                .pipe(
                    finalize(() => {
                        this.inProcessLoadingMaterials = false;
                    })
                )
                .subscribe(
                    (res: HttpResponse<IMaterialsWrapper>) => {
                        const headers = res.headers;
                        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);

                        this.dataModel.updateMargin(res.body.margin);
                        this.dataModel.updateHandlingCharge(res.body.handlingCharge);
                        this.dataModel.fill(res.body.materials);
                    }
                );
        }
    }

    applyMarginToAll(): void {
        this.inProcessApplyingMarginAll = true;

        this.quoterService
            .applyMarginToAllMaterials(this.dataModel.endpointData().margin)
            .pipe(
                finalize(() => {
                    this.inProcessApplyingMarginAll = false;
                })
            )
            .subscribe(
                () => {
                    this.dataModel.applyMarginToAll();
                }
            );

    }

    saveAndApply(): void {
        this.inProcessSavingMaterials = true;

        this.quoterService
            .batchUpdateMaterials(this.dataModel.endpointData(), this.isGlobal)
            .pipe(
                finalize(() => {
                    this.inProcessSavingMaterials = false;
                })
            )
            .subscribe(
                () => {
                    this.alertService.success('Save successful');
                    this.reset();
                }
            );
    }

    refreshCosts(): void {
        this.confirmModalService.open({
                header: 'Update your material prices?',
                textHtml: `<div class="strong m-b-10">
                            You will replace all of your material costs with Buildpartner's latest material prices.
                           </div>`,
            },
        ).result.then((result) => {
            if (!result) {
                return;
            }

            this.inProcessRefreshCosts = true;

            this.quoterService
                .refreshCosts()
                .pipe(
                    finalize(() => {
                        this.inProcessRefreshCosts = false;
                    })
                )
                .subscribe(
                    () => {
                        this.successModalService.open(
                            {
                                header: 'Your material prices have been updated!'
                            }
                        );
                        this.reset();
                    }
                );
        })
    }

    inProcess(): boolean {
        return (
            this.inProcessLoadingMaterials ||
            this.inProcessRefreshCosts ||
            this.inProcessSavingMaterials ||
            this.inProcessApplyingMarginAll
        );
    }

    protected showHelpModal() {
        Swal.fire({
            html: this.defaultMaterialRatesHelpText,
            showCloseButton: true,
            customClass: {
                confirmButton: 'btn btn-primary btn-border-radius waves-effect'
            },
            confirmButtonText: 'Close'
        });
    }

    protected sort(): string[] {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    protected transition(): void {
        this.reset();
    }

    protected trackId(index: number, item: IBuilderMaterial): number {
        return item.id;
    }

    protected reset(): void {
        this.page = null;
        this.previousPage = null;
        this.dataModel.clearMaterials();
        this.loadPage(1);
    }

    protected hasMore(): boolean {
        return this.dataModel.materials.length < this.totalItems;
    }

    protected getTasksValue(material: IBuilderMaterial): string {
        return (material.tasks || ['']).join(', ');
    }

    protected showUpdateConfirmation(): void {
        Swal.fire({
            title: 'Would you like to update ALL quoters database or only default quoters?',
            text: '(This will take time)',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, update all',
            cancelButtonText: 'No, update default only'
        }).then(result => {
            if (result.isConfirmed) {
                // User clicked "Yes, update all"
                this.isGlobal = true;
                this.saveAndApply(); // Trigger the form submission
            } else if (result.isDismissed) {
                // User clicked "No, update default only" or closed the modal
                this.isGlobal = false;
                this.saveAndApply(); // Trigger the form submission
            }
        });
    }

    protected applyMarginToAllConfirmation() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'New margin will be applied to all items (without click Save)!',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            },
            confirmButtonText: 'Apply',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                // User clicked "Apply"
                this.applyMarginToAll();
            }
        });
    }
}
