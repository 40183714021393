<div class="modal-header">
    <h2>
        How would you like to start your project?
    </h2>
    <button (click)="close()"
            aria-hidden="true"
            class="close"
            data-dismiss="modal"
            type="button">&times;
    </button>
</div>
<div class="modal-body">
    <div class="project-types-container">
        <div class="project-types-container__project-type"
             [ngClass]="{'project-types-container__project-type__selected': _selectedType === 'cost-plan-wizard'}"
             (click)="select('cost-plan-wizard')">
            <img alt="Cost Plan Wizard" height="150" src="/content/images/how-to-create-project/cost-plan-wizard.svg">
            <div class="project-types-container__project-type__title">
                Cost Plan Wizard
            </div>
            <div class="project-types-container__project-type__subtitle">
                Do it yourself and find out your next project's cost in minutes!
            </div>
            <div class="project-types-container__project-type__list">
                <div>Features include:</div>
                <ul class="m-l-15">
                    <li>Detailed Cost Plan of your project</li>
                    <li>Comparison costs against other builders</li>
                    <li>Timeline</li>
                    <li>Detailed Schedule of Works</li>
                    <li>And much more.</li>
                </ul>
            </div>
        </div>
        <div class="project-types-container__project-type"
             [ngClass]="{'project-types-container__project-type__selected': _selectedType === 'bp-estimating-service'}"
             (click)="select('bp-estimating-service')">
            <img alt="Google" height="150" src="/content/images/how-to-create-project/bp-estimating-service.svg">
            <div class="project-types-container__project-type__title">
                Estimating Service
            </div>
            <div class="project-types-container__project-type__subtitle">
                Need a bit of extra help? Use BuildPartner’s qualified estimators to set up your project for you.
            </div>
            <div class="project-types-container__project-type__list">
                <div>Choose from a range of services including:</div>
                <ul class="m-l-15">
                    <li>Plan Measurement</li>
                    <li>Feasibility Study</li>
                    <li>Detailed Cost Plan</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button (click)="close()"
            tabindex="0"
            class="btn btn-secondary btn-border-radius waves-effect cancel"
            type="button">
        Cancel
    </button>

    <button (click)="ok()"
            [disabled]="_selectedType == null"
            tabindex="1"
            class="btn btn-border-radius waves-effect ok"
            type="button">
        Select
    </button>
</div>
