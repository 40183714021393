<div class="row justify-content-center">
        <div class="col-8">
            <form #editForm="ngForm"
                  (ngSubmit)="save()"
                  name="editForm"
                  novalidate
                  role="form">
                <h1 id="bp-stage-heading">
                    Create or edit a Stage
                </h1>
                <div>
                    <div [hidden]="!stage.id"
                         class="form-group">
                        <label for="id">
                            ID
                        </label>
                        <input [(ngModel)]="stage.id"
                               class="form-control"
                               id="id"
                               name="id"
                               readonly type="text"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_stage">
                            Stage
                        </label>
                        <input [(ngModel)]="stage.stage"
                               class="form-control"
                               id="field_stage"
                               name="stage"
                               required
                               type="text"/>
                        <div [hidden]="!(editForm.controls.stage?.dirty && editForm.controls.stage?.invalid)">
                            <small [hidden]="!editForm.controls.stage?.errors?.required"
                                   class="form-text text-danger">
                                This field is required.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_stageOrder">
                            Stage Order
                        </label>
                        <input [(ngModel)]="stage.stageOrder"
                               class="form-control"
                               id="field_stageOrder"
                               name="stageOrder"
                               type="number"/>
                    </div>
                </div>

                <div class="text-right">
                    <button (click)="previousState()"
                            class="btn btn-default btn-border-radius waves-effect cancel mr-1"
                            ngbTooltip="cancel"
                            type="button">
                        Cancel
                    </button>

                    <button (click)="save()"
                            [disabled]="editForm.form.invalid || isSaving"
                            class="btn btn-primary btn-border-radius waves-effect save"
                            ngbTooltip="Save"
                            type="button">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>

