<div class="scope">
    <textarea #scopeInput
              bpTextareaAutoresize
              (blur)="applyChanges($event)"
              (keydown.enter)="applyChanges($event)"
              [formControl]="scopeContentControl"
              class="sc-input"
              placeholder="Enter new scope"
              rows="1"
              type="text">
            </textarea>

    <button (click)="showDeleteConfirmation()"
            class="btn btn-circle btn-small btn-secondary"
            ngbTooltip="Delete scope"
            type="button">
        <i class="material-icons">delete_forever</i>
    </button>
</div>
