import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import * as _ from 'lodash';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from "ng2-charts";
import { CommonModule } from "@angular/common";

export interface ILabelValue {
    label: string;
    value: number;
    percentage: number;
}

// Returns a single rgb color interpolation between given rgb color
// based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
function interpolateColor(color1, color2, factor = 0.5) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
}

function interpolateColors(color1, color2, steps) {
    if (steps === 1) {
        return color1;
    }
    const stepFactor = 1 / (steps - 1);
    const interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for (let i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
}

@Component({
    selector: 'bp-cost-plan-chart',
    templateUrl: './cost-plan-chart.component.html',
    styleUrls: ['cost-plan-chart.scss'],
    standalone: true,
    imports: [CommonModule, BaseChartDirective],
    providers: [provideCharts(withDefaultRegisterables())],
})
export class CostPlanChartComponent implements OnInit {
    @Input() data: ILabelValue[];

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    public doughnutChartData: ChartData<'doughnut'> = {
        labels: [],
        datasets: []
    };

    chartPercentage: number[];

    doughnutChartOptions: ChartOptions<'doughnut'>;

    ngOnInit(): void {
        this.doughnutChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 800
            },
            layout: {
                padding: 0
            },
            interaction: {
                mode: 'point'
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    mode: 'index',
                    callbacks: {
                        label: (context: any) => {
                            return ` £${_.round(context.dataset.data[context.dataIndex], 2)} (${this.chartPercentage[context.dataIndex]}%)`
                        }
                    }
                },
            }
        };

        this.redraw();
    }

    redraw(data?: ILabelValue[]): void {
        this.data = data || this.data;

        this.doughnutChartData.labels = this.data?.map(d => d.label);
        this.doughnutChartData.datasets = [
            {
                data: this.data?.map(d => d.value),
                backgroundColor: interpolateColors('rgb(246, 248, 252)', 'rgb(0, 134, 170)', this.data.length)
            }];
        this.chartPercentage = _.map(this.data, 'percentage');

        setTimeout(() => {
            this.chart?.chart.update()
        });
    }
}
