<form #f="ngForm"
      name="form"
      novalidate>
    <div class="block-header m-b-10">
        <div class="row">
            <div class="col-sm-4">
                <ng-container [ngSwitch]="returnPage">
                    <a *ngSwitchCase="'quote'"
                       [routerLink]="['/', 'quoter', 'quote', project.id]"
                       class="back-link">
                        <i class="material-icons">arrow_back</i> Back to quote
                    </a>
                    <a *ngSwitchCase="'schedule'"
                       [routerLink]="['/', 'scheduler', 'schedule', project.id]"
                       class="back-link">
                        <i class="material-icons">arrow_back</i> Back to schedule
                    </a>
                </ng-container>
            </div>

            <div class="col-lg-4 text-center">
                <h1>
                    {{ scheduleTask.task }}
                </h1>
            </div>

            <div class="col-lg-4 text-right">
                <button *ngIf="showSaveWithConfirmToSaveToDatabase()"
                        (click)="showSwal()"
                        [disabled]="inProcess() || scheduleTask.isSchedulerSubcontract"
                        ngbTooltip="Save task"
                        class="btn btn-primary btn-border-radius pull-right"
                        type="button">
                    Save and Exit
                </button>

                <button *ngIf="!showSaveWithConfirmToSaveToDatabase()"
                        [disabled]="inProcess() || scheduleTask.isSchedulerSubcontract"
                        (click)="onSaveTaskClick()"
                        ngbTooltip="Save task"
                        class="btn btn-primary btn-border-radius pull-right"
                        type="button">
                    Save and Exit
                </button>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="header">
            <button id="submitFormButton"
                    style="display: none;"
                    type="button">
            </button>

            <div class="m-t-20">
                <table class="af-table align-right">
                    <tr>
                        <th style="width: 40%">
                        </th>

                        <th style="width: 10%"
                            *ngIf="!scheduleTask.isSubcontract">
                            Hours/unit
                        </th>

                        <th style="width: 10%">
                            {{ scheduleTask.isSubcontract ? 'Subcontracted price' : 'Rate' }}
                        </th>

                        <th style="width: 10%"
                            *ngIf="!scheduleTask.isSubcontract">
                            Rate/unit
                        </th>

                        <th style="width: 10%">
                            OH & P
                        </th>
                        <th style="width: 10%">
                            Total/unit
                        </th>
                    </tr>
                    <tr>
                        <td class="align-left"
                            colspan="1000">
                            <p class="font-weight-bold">
                                Labour
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let actualLabourComponent of scheduleTask.actualLabourComponents">
                        <td class="align-left">
                            {{ actualLabourComponent.trade }}
                        </td>

                        <td class="col-with-input"
                            *ngIf="!scheduleTask.isSubcontract">
                            <input #labourHoursPerUnit="ngModel"
                                   [disabled]="scheduleTask.isProvisional || scheduleTask.isSubcontract || scheduleTask.isSchedulerSubcontract"
                                   (ngModelChange)="actualLabourHoursPerUnitChanged($event, actualLabourComponent)"
                                   [(ngModel)]="actualLabourComponent._hoursPerUnit"
                                   [ngClass]="{ 'error': (f.submitted || labourHoursPerUnit.dirty || labourHoursPerUnit.touched) && labourHoursPerUnit.invalid }"
                                   class="af-input text-right"
                                   name="actualLabourHoursPerInit_{{actualLabourComponent.id}}"
                                   mask="separator.2"
                                   thousandSeparator=","
                                   [suffix]="!scheduleTask.isSubcontract ? 'h' : ''"
                                   type="text">
                        </td>

                        <td class="col-with-input">
                            <span *ngIf="!scheduleTask.isSubcontract">
                                <input #cost="ngModel"
                                       [disabled]="true"
                                       [(ngModel)]="actualLabourComponent.cost"
                                       (ngModelChange)="actualLabourCostChanged($event, actualLabourComponent)"
                                       [ngClass]="{ 'error': (f.submitted || cost.dirty || cost.touched) && cost.invalid }"
                                       class="af-input text-right"
                                       name="actualLabourComponentCost_{{actualLabourComponent.id}}"
                                       mask="separator.2"
                                       allowNegativeNumbers="true"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                            </span>
                            <span *ngIf="scheduleTask.isSubcontract">
                                <input #subcontractCost="ngModel"
                                       [(ngModel)]="actualLabourComponent.subcontractCost"
                                       [disabled]="scheduleTask.isSchedulerSubcontract"
                                       [ngClass]="{ 'error': (f.submitted || subcontractCost.dirty || subcontractCost.touched) && subcontractCost.invalid }"
                                       (ngModelChange)="thereWereChanges = true"
                                       class="af-input text-right"
                                       autocomplete="off"
                                       name="actualLabourSubcontractCost_{{actualLabourComponent.id}}"
                                       mask="separator.2"
                                       allowNegativeNumbers="true"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                            </span>
                        </td>

                        <td class="col-with-input"
                            *ngIf="!scheduleTask.isSubcontract">
                            <input #ratePerUnit="ngModel"
                                   [disabled]="scheduleTask.isProvisional || scheduleTask.isSchedulerSubcontract"
                                   [(ngModel)]="actualLabourComponent._ratePerUnit"
                                   [ngClass]="{ 'error': (f.submitted || ratePerUnit.dirty || ratePerUnit.touched) && ratePerUnit.invalid }"
                                   (ngModelChange)="actualLabourRatePerUnitChanged($event, actualLabourComponent)"
                                   class="af-input text-right"
                                   name="actualLabourComponentRatePerUnit_{{actualLabourComponent.id}}"
                                   mask="separator.2"
                                   prefix="&pound;&nbsp;"
                                   thousandSeparator=","
                                   type="text">
                        </td>

                        <td class="col-with-input">
                            <input #margin="ngModel"
                                   [disabled]="scheduleTask.isProvisional || scheduleTask.isSchedulerSubcontract"
                                   [(ngModel)]="actualLabourComponent._margin"
                                   [ngClass]="{ 'error': (f.submitted || margin.dirty || margin.touched) && margin.invalid }"
                                   (ngModelChange)="thereWereChanges = true"
                                   class="af-input text-right"
                                   name="actualLabourComponentMargin_{{actualLabourComponent.id}}"
                                   suffix="%"
                                   mask="separator.2"
                                   [dropSpecialCharacters]="true"
                                   thousandSeparator=","
                                   type="text">
                        </td>

                        <td class="Total/unit">
                            <span><span>&pound;</span> {{ labourComponentTotalPerUnit(actualLabourComponent) | number : "1.2-2" }}</span>
                        </td>
                    </tr>
                    <tr *ngIf="scheduleTask.actualLabourComponents.length === 0">
                        <td class="align-center"
                            colspan="1000">
                            <p class="font-weight-bold">
                                No labour
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="align-left"
                            colspan="1000">
                            <p class="font-weight-bold">
                                Materials
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let actualMaterialComponent of scheduleTask.actualMaterialComponents">
                        <td class="align-left">
                            {{ actualMaterialComponent.material }} <br/>
                            <span class="materialDetail">
                              <span *ngIf="!actualMaterialComponent.clientSupplied">Client Supplied</span>
                              <span>{{ actualMaterialComponent.specification }}</span>
                            </span>
                        </td>

                        <td *ngIf="!scheduleTask.isSubcontract">
                            <!-- skip hours/unit -->
                        </td>

                        <td class="col-with-input">
                            <span *ngIf="!scheduleTask.isSubcontract">
                                <input #cost="ngModel"
                                       [(ngModel)]="actualMaterialComponent.cost"
                                       [disabled]="scheduleTask.isProvisional || !actualMaterialComponent.clientSupplied || scheduleTask.isSchedulerSubcontract"
                                       (ngModelChange)="actualMaterialCostChanged($event, actualMaterialComponent)"
                                       class="af-input text-right"
                                       autocomplete="off"
                                       name="actualMaterialCost_{{actualMaterialComponent.id}}"
                                       mask="separator.2"
                                       allowNegativeNumbers="true"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                            </span>
                            <span *ngIf="scheduleTask.isSubcontract">
                                <input #subcontractCost="ngModel"
                                       [(ngModel)]="actualMaterialComponent.subcontractCost"
                                       [disabled]="scheduleTask.isProvisional || scheduleTask.isSchedulerSubcontract"
                                       (ngModelChange)="thereWereChanges = true"
                                       class="af-input text-right"
                                       autocomplete="off"
                                       name="actualMaterialsubcontractCost_{{actualMaterialComponent.id}}"
                                       mask="separator.2"
                                       allowNegativeNumbers="true"
                                       thousandSeparator=","
                                       prefix="&pound;&nbsp;"
                                       type="text">
                            </span>
                        </td>

                        <td class="col-with-input"
                            *ngIf="!scheduleTask.isSubcontract">
                            <input #ratePerUnit="ngModel"
                                   [disabled]="true"
                                   [(ngModel)]="actualMaterialComponent._ratePerUnit"
                                   (ngModelChange)="thereWereChanges = true"
                                   [ngClass]="{ 'error': (f.submitted || ratePerUnit.dirty || ratePerUnit.touched) && ratePerUnit.invalid }"
                                   class="af-input text-right"
                                   name="actualLabourComponentRatePerUnit_{{actualMaterialComponent.id}}"
                                   mask="separator.2"
                                   thousandSeparator=","
                                   prefix="&pound;&nbsp;"
                                   type="text">
                        </td>

                        <td class="col-with-input">
                            <input #margin="ngModel"
                                   [disabled]="scheduleTask.isProvisional || scheduleTask.isSchedulerSubcontract"
                                   [(ngModel)]="actualMaterialComponent._margin"
                                   (ngModelChange)="thereWereChanges = true"
                                   [ngClass]="{ 'error': (f.submitted || margin.dirty || margin.touched) && margin.invalid }"
                                   class="af-input text-right"
                                   name="actualMaterialComponentMargin_{{actualMaterialComponent.id}}"
                                   mask="separator.2"
                                   [dropSpecialCharacters]="true"
                                   suffix="%"
                                   thousandSeparator=","
                                   type="text">
                        </td>

                        <td class="total-per-unit">
                            <span><span>&pound;</span> {{ materialComponentTotalPerUnit(actualMaterialComponent) | number : "1.2-2" }}</span>
                        </td>
                    </tr>
                    <tr *ngIf="scheduleTask.actualMaterialComponents.length === 0">
                        <td class="align-center"
                            colspan="1000">
                            <p class="font-weight-bold">
                                No materials
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="align-left"
                            [attr.colspan]="scheduleTask.isSubcontract ? 3 : 5">
                            <p class="font-weight-bold">
                                Sum total
                            </p>
                        </td>
                        <td class="total-per-unit">
                            <span><span>&pound;</span> {{ total() | number : "1.2-2" }}</span>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="m-t-20 row">
                <div class="col-6 display-flex items-center">
                    <p class="font-weight-bold m-t-10 m-r-15">
                        Provisional
                    </p>
                    <small *ngIf="scheduleTask.isSchedulerProvisional"
                           class="no-items">
                        by scheduler
                    </small>
                    <label class="form-check-label">
                        <input [(ngModel)]="scheduleTask.isProvisional"
                               [disabled]="scheduleTask.isSchedulerProvisional"
                               (change)="onIsProvisionalChange()"
                               class="af-input text-right"
                               name="isProvisional_{{scheduleTask.id}}"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                    <div class="provisional m-l-20"
                         *ngIf="scheduleTask.isProvisional">
                        <input #provisionalCost="ngModel"
                               [(ngModel)]="scheduleTask.provisionalCost"
                               [disabled]="scheduleTask.isSchedulerProvisional"
                               (ngModelChange)="thereWereChanges = true"
                               class="af-input text-right"
                               autocomplete="off"
                               name="provisionalCost"
                               mask="separator.2"
                               allowNegativeNumbers="true"
                               thousandSeparator=","
                               prefix="&pound;&nbsp;"
                               type="text">
                    </div>
                </div>

                <div class="col-6 display-flex items-center">
                    <p class="font-weight-bold m-t-10 m-r-15">
                        Subcontract / Supplier
                    </p>
                    <label class="form-check-label">
                        <input [(ngModel)]="scheduleTask.isSubcontract"
                               [disabled]="scheduleTask.isSchedulerProvisional"
                               (change)="onIsSubcontractChange()"
                               class="af-input text-right"
                               name="isSubcontract_{{scheduleTask.id}}"
                               type="checkbox">
                        <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                </div>
            </div>

            <div class="m-t-20 row">
                <div class="col-lg-6">
                    <p class="font-weight-bold">
                        Notes
                    </p>
                    <p>
                        {{ scheduleTask.note || "-" }}
                    </p>
                </div>
                <div class="col-lg-6">
                    <p class="font-weight-bold">
                        Drawing ref
                    </p>
                    <p *ngIf="scheduleTask.drawingRef">
                        <a [href]="scheduleTask.drawingRef"
                           title="Drawing ref">
                            {{ scheduleTask.drawingRef }}
                        </a>
                    </p>
                    <p *ngIf="!scheduleTask.drawingRef">
                        -
                    </p>
                </div>
            </div>
        </div>
    </div>
</form>

