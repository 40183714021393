<button class="btn select"
        [disabled]="disabled"
        (click)="onClick($event)"
        type="button">
    {{ date != null ? (date.toDate() | date) : placeholder }}
</button>
<div class="input-group position-relative">
    <ngb-datepicker class="dropdown-menu show p-0"
                    [class.d-none]="!visible"
                    (dateSelect)="onDateSelect($event)">
    </ngb-datepicker>
    <fa-icon [icon]="['fas', 'remove']"
             (click)="reset()"
             *ngIf="date && !disabled">
    </fa-icon>
</div>
