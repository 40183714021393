export type ShowSubStagesMode = 'show-in-use' | 'hide-all' | 'display-all' | 'finishes';

export interface ShowSubStagesModeInfo {
    id: ShowSubStagesMode;
    extendedLabel: string;
    label: string;
    icon: string;
}

export const SHOW_SUB_STAGES_MODES: ShowSubStagesModeInfo[] = [
    { id: 'show-in-use', label: 'In use', extendedLabel: 'In use', icon: 'format_align_center' },
    { id: 'hide-all', label: 'None', extendedLabel: 'None', icon: 'drag_handle' },
    { id: 'display-all', label: 'Elements', extendedLabel: 'Elements', icon: 'format_align_justify' },
    { id: 'finishes', label: 'Finishes', extendedLabel: 'Finishes', icon: 'mobile_friendly' }
];
